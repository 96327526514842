(function () {
  angular.module('pentaApp').factory("geolocation.factory", factory);
  factory.$inject = ['$http', '$q', 'router.resource', '$rootScope', '$timeout'];
  function factory($http, $q, routerResource, $rootScope, $timeout) {
    /// VARS
    var userPosition = {};
    var estimatedByIp;
    var routersPromise;
    var deltaToOfferNearestRouter = 1000 * 60 * 60 * 4;
    var routers = [];
    var geoTryCount = 0;

    /// INIT
    routersPromise = getRouters()

    /// RETURN
    return {
      /// PUBLIC VARS

      /// PUBLIC METHODS
      getCurrentPosition: getCurrentPosition,
      offerNearestRouter: offerNearestRouter,
      getDistance: getDistance,
      estimatePosition: estimatePosition,
    }

    ////////////////////////////////////////
    //
    function getRouters(){
      return routerResource.query({ _select: ['name', 'geolocalization', 'address'], _controller: 'qweqeqwe' }, function(data){
        routers = data;
      }).$promise;
    }
    
    function estimatePosition() {
      return $q(function (resolve, reject) {
        if (estimatedByIp) resolve(estimatedByIp)
        fetch('https://ipapi.co/json')
          .then(function (response) { return response.json() })
          .then(function (response) {
            estimatedByIp = {
              latitude: response.latitude,
              longitude: response.longitude,
              contryCode: response.country_code
            }
            resolve(estimatedByIp)
          })
          .catch(function (err) {reject(err)})
      })
      // var defered = $q.defer()
      // if(estimatedByIp) return defered.resolve(estimatedByIp)
      // fetch('https://ipapi.co/json/')
      //   .then(function(response){return response.json()})
      //   .then(function(response){
      //     estimatedByIp = {
      //       latitude: response.latitude,
      //       longitude: response.longitude,
      //       countryCode: response.country_code
      //     };
      //     defered.resolve(estimatedByIp)
      //   })
      //   .catch(function(err){
      //     defered.reject(err)
      //   })
      // return defered.promise;
    }

    function getCurrentPosition(done, errCb) {
      if(!done) throw new PentaError('LLamada a getCurrentPosition sin callback')
      if (navigator.geolocation && navigator.geolocation.getCurrentPosition)
        navigator.geolocation.getCurrentPosition(function (position) {
          if(position.coords.accuracy > 500 && geoTryCount < 3){ // si la precisión no es buena, consulto varias veces
            geoTryCount++;
            $timeout(function(){getCurrentPosition(done)}, 2000)
          } 
          else {
            geoTryCount = 0;
            userPosition.latitude = position.coords.latitude;
            userPosition.longitude = position.coords.longitude;
            done(userPosition)
          }
        }, function(){
          if(errCb) errCb()
          //estimatePosition().then(function(estimatedByIp){
            //userPosition.latitude = estimatedByIp.latitude;
            //userPosition.longitude = estimatedByIp.longitude;
            //done(userPosition)
          //})
        });
      else {
        if(errCb) errCb()
        //estimatePosition().then(function(estimatedByIp){
          //userPosition.latitude = estimatedByIp.latitude;
          //userPosition.longitude = estimatedByIp.longitude;
          //done(userPosition)
        //})
      }
    }

    function getNearestRouter(){
      var defered = $q.defer();
      routersPromise.then(function(){
        getCurrentPosition(function(userPosition){
          var nearest;
          routers.forEach(function(router){
            if (router.geolocalization && router.geolocalization.coordinates && router.geolocalization.coordinates.length){
              var routerLat = router.geolocalization.coordinates[0];
              var routerLon = router.geolocalization.coordinates[1];
              router._distanceToUser = getDistance(routerLat, routerLon, userPosition.latitude, userPosition.longitude)
              if(!nearest || (nearest._distanceToUser && router._distanceToUser < nearest._distanceToUser))
                nearest = router;
            }
          })
          defered.resolve(nearest);
        })
      })
      .catch(function(err){
        defered.reject(err)
      })
      return defered.promise;
    }

    function getDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = deg2rad(lat2 - lat1);  // deg2rad below
      var dLon = deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    }
    function deg2rad(deg) {
      return deg * (Math.PI / 180)
    }

    function offerNearestRouter(){
      /// Me fijo si ya se preguntó
      var lastOffer = appStorage.get('nearestRouterOfferedTs');
      if(lastOffer && ((new Date()) - (new Date(lastOffer)) < deltaToOfferNearestRouter)) return;
      /// Chuequeo cual es la sucursal mas cercana
      getNearestRouter().then(function(router){
        if(router._id === $rootScope.defaultRouter) return;
        if($rootScope.currentOrder) return; /// si hay una orden en curso, no ofrezco
        appStorage.set('nearestRouterOfferedTs', new Date())
        ons.notification.confirm({
          title: i18next.t('Detectamos que tu sucursal mas cercana es') + ':',
          message: router.name + (router.address ? (' - ' + router.address) : ''),
          buttonLabels: [i18next.t('Elegir otra'), i18next.t('Si, continuar')]
        }).then(function(val){
          if(val) {
            $rootScope.defaultRouter = router._id;
            if(!$rootScope.$$phase) $rootScope.$apply();
          }
          else{
            $rootScope.openSection('selectRouter/selectRouter.jade', {data: {onClose: function(){$rootScope.resetToLandingPage()}}})
          }
        })
      })
    }


  }


})();
