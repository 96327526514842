'use strict';
/* NO UTILIZAR ESTE CONTROLLER NUNCA, SOLO SE UTILIZA EN _appAngular */
(function () {
  var _controller = 'missingFields.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'router.resource', 'missingFields.resource'];
  function controller($scope, routerResource, missingFieldsResource) {
    /// METHODS
    $scope.save = save;

    /// VARS
    var onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    var times = appNavigator.topPage.data && appNavigator.topPage.data.times || 1;
    $scope.missing = { customFields: {} };

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    /// INIT
    init();


    /////////////////////

    function init() {
      $scope.$parent._currentSection = null;
      $scope.missingFields = profile.missingFields;
      $scope.missingCustomsRequired = $scope.missingFields.customs.filter(function (f) { return !f.optional })
      $scope.missingCustomsRequired.forEach(function (c) { if (c.type === 'SELECT') c.options = c.options.split(','); })
    }

    function save() {
      if ($scope.missing.birthYear && $scope.missing.birthMonth && $scope.missing.birthDay)
        $scope.missing.birthday = $scope.missing.birthYear + '-' + $scope.missing.birthMonth + '-' + $scope.missing.birthDay;
      $scope.missing._controller = _controller;
      $scope.missing.enterprise = profile.enterprise;
      missingFieldsResource.save($scope.missing, function () {
        loadProfile(function () {
          if (appNavigator.pages.length > 1) appNavigator.popPage({ callback: onClose, times: times })
          else onClose();
        })
      })

    }


    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    //// FIN CONTROLLER
  }
})();
