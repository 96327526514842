'use strict';
(function () {
  var _controller = 'additionalInformationLogs.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('additionalInformationLogs', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        currentAdditionalInformation: '<', // Objeto de información adicional.
        mainObject: '<?' // objeto de Reserva / ticket / order . Verificar que tenga el array de "informacionAdicional" para validar datos. Opcional
      }
    })

  controller.$inject = ['$scope', '$element', '$q'];
  function controller($scope, $element, $q) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/additionalInformationLogs/additionalInformationLogs.component.html' : 'components/additionalInformationLogs/additionalInformationLogs.component.jade';

    // METHODS
    $scope.openLogsModal = openLogsModal;
    $scope.hideLogsModal = hideLogsModal;

    // VARS
    var that = this;
    var logDialog = null;
    that.logs = [];

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('$ctrl.currentAdditionalInformation.value', function (newValue, oldValue) {
      if (!newValue || newValue === oldValue) return
       refresh();
    })

    /// On component init
    this.$onInit = function () {
      refresh();
    };

    /////////////////////
    function refresh() {
      if (that.currentAdditionalInformation && (!that.currentAdditionalInformation.logs || !that.currentAdditionalInformation.logs.length)
        && that.mainObject && that.mainObject.additionalInformation && that.mainObject.additionalInformation.length) {
        var item = that.mainObject.additionalInformation.find(function (f) { return f._id === that.currentAdditionalInformation._id });
        if (item && item.logs && item.logs.length) setLogs(item);
      } else {
        if (that.currentAdditionalInformation && that.currentAdditionalInformation.logs && that.currentAdditionalInformation.logs.length)
          setLogs(that.currentAdditionalInformation)
      }
    }

    function setLogs(ai) {
      if (!ai || !ai.logs || !ai.logs.length) return that.logs = [];
      that.logs = ai.logs.map(function (f) {
        return {
          ts: f.ts,
          value: f.value,
          type: ai.type,
          _id: ai._id
        }
      })
    }

    function openLogsModal($event) {
      if (!that.logs || !that.logs.length) return
      ons
        .createElement('additionalInformationlogDialog.html', { parentScope: $scope, append: true })
        .then(function (dialog) {
          logDialog = dialog;
          dialog.show();
        });
    }

    function hideLogsModal($event) {
      if (!logDialog || !logDialog.hide || typeof logDialog.hide !== 'function') return
      logDialog.hide({
        callback: function () {
          if (logDialog._element && logDialog._element.length && (typeof logDialog._element[0].remove === 'function'))
            logDialog._element[0].remove();
          logDialog = null;
        }
      });
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    //// FIN CTRL
  }

})();
