'use strict';
(function () {
  angular.module('pentaApp').factory('noTurnsAvailable.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/noTurnsAvailable/noTurnsAvailable.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data'];
  function controller($scope, modal, data) {
    let contentHeight = $('.page__content').height()
    let multiplier = data?.waitingList ? 50 : 40
    $scope.modalHeight = contentHeight - ((contentHeight * multiplier) / 100) + 'px';
    $scope.data = data;

    $scope.back = function back() { modal.close() }
    $scope.JoinWaitlist = function JoinWaitlist() { modal.close(true) }
    ///////////////////////
  }

})();
