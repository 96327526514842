(function () {
  var _controller = 'qrReader.component.controller'
  angular.module('pentaApp')
    .controller(_controller,  controller)
    .component('qrReader', {
      templateUrl: 'components/qrReader/qrReader.component.jade',
      controller: _controller,
      bindings: { 
        // '=': Two Way Binding, lo que cambia acá, cambia en el controlador padre automáticamente
        // '<': One Way Binding (RECOMENDADO), para recibir variables, funciones, etc
        // '@': String, para recibir strings, puede ser una bandera por ejemplo
        // '&': Dinámico, se ejecuta lo que está adentro, hay que pasarle un objeto con variables 
        //      ej: componente(call-back='algo(var)')  y en el controller callBack({var: 'esto'})
        onRead: '<'
      }
    })

  controller.$inject = ['$scope', '$element', '$timeout'];
  function controller($scope, $element, $timeout){
    //// METHODS
    $scope.initQRReader = initQRReader;
    $scope.changeCamera = changeCamera;
    $scope.toggleTorch = toggleTorch;
    var onRead = this.onRead;

    //// VARS
    $scope.cIndex = 0;
    $scope._activeQRReader = false;
    $scope.cameraIx;
    $scope.cameras;
    $scope.torch;
    $scope.iphone = iOS();
    var scanner;

    //// WATCHERS

    /// One Way Binding Update (cuando se actualiza tu binding con '<')
    this.$onChanges = function(){ }

    //// INIT
    this.$onInit = function () {
    };

    /// DESTROY
    this.$onDestroy = function () {
      stopScanner();
    };

    ///////////////////////////////////
    function initQRReader(){
      $scope._activeQRReader = true;
      $timeout(function(){
        if (!scanner)  scanner = new Instascan.Scanner({ video: $element.find('video')[0]});
        Instascan.Camera.getCameras().then(function(cameras){ 
          $scope.cameras = cameras;
          $scope.cameraIx = cameras.findIndex(function (f) { return /back/.test(f.name) });
          if ($scope.cameraIx === -1) $scope.cameraIx = 0;
          startScanner()
          if(!$scope.$$phase) $scope.$apply();
        }).catch(function(err){console.log(err)}); 
      })
    }

    function stopScanner(){
      if(!scanner) return;
      scanner.stop(); 
      scanner.removeAllListeners('scan');
    }

    function startScanner(){
      scanner.start($scope.cameras[$scope.cameraIx]);
      scanner.addListener('scan', onRead);
    }

    function toggleTorch() {
      $scope.torch = !$scope.torch;
      if($scope.torch){
        var track = $element.find('video')[0].srcObject.getVideoTracks()[0];
        if (track && typeof track.getCapabilities === 'function' && track.getCapabilities().torch) 
          track.applyConstraints({ advanced: [{ torch: $scope.torch }] });
      }
      else{
        stopScanner();
        scanner = undefined;
        $scope._activeQRReader = false;
        $timeout(function(){
          initQRReader();
        })
      }
    }

    function iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    function changeCamera() {
      if (!scanner) return;
      $scope.cameraIx = ($scope.cameraIx + 1) % $scope.cameras.length;
      stopScanner();
      startScanner();
    }
    
  }
})();
