/**
 * Created by nico on 26/02/16.
 */
(function () {
  angular.module('pentaApp').config(configuration);
  configuration.$inject = ['$httpProvider', '$provide'];
  function configuration($httpProvider, $provide) {
    $provide.factory('httpInterceptor', httpInterceptor);
    $httpProvider.interceptors.push('httpInterceptor');
  }

  httpInterceptor.$inject = ['$q', '$rootScope'];
  function httpInterceptor($q, $rootScope) {
    var requests = [], noVeilRequests = [];
    var forceVeil = false;
    return {
      'request': onRequest,
      'response': onBackendResponse,
      'responseError': onBackendError,
      'abortRequests': abortRequests,
      'showLoadingVeil': showLoadingVeil,
      'hideLoadingVeil': hideLoadingVeil
    };
    function checkVeil() { $rootScope._loadingVeil = forceVeil || requests.length > 0; }

    function showLoadingVeil() {
      forceVeil = true;
      checkVeil();
    }
    function hideLoadingVeil() {
      forceVeil = false;
      checkVeil();
    }
    function abortRequests(_controller) {
      if (_controller) {
        requests = requests.filter(function (aborter) {
          if (_controller && aborter._controller !== _controller) return true;
          aborter.resolve();
        });
      }
      checkVeil();
    }
    function onRequest(config) {
      //config = angular.copy(config); // Esta copia no se para que esta.. pero rompe el upload multipart...
      saveAttr(config, '_ignoreVeil');
      saveAttr(config, '_controller');
      saveAttr(config, '_ignoreError');

      if (!config.url.endsWith('jade') && !config._controller) {
        console.error('Request url:', config.url);
        //throw new PentaError('Se debe especificar un _controller al hacer un AJAX');
      }

      var aborter = $q.defer();
      aborter._controller = config._controller
      config.timeout = aborter.promise;
      if (config._ignoreVeil) noVeilRequests.push(aborter); else requests.push(aborter);
      checkVeil();
      return config;
    }

    function saveAttr(config, name) {
      var value;
      if (config.params && config.params[name]) {
        value = config.params[name];
        delete config.params[name];
      } else if (config.data && config.data[name]) {
        value = config.data[name];
        delete config.data[name];
      }
      config[name] = value;
    }

    function popPromise(config) {
      var array = config._ignoreVeil ? noVeilRequests : requests;
      var ix = array.findIndex(function (f) { return config.timeout === f.promise });
      if (ix !== -1) array.splice(ix, 1); else console.warn('Condicion inesperada popPromise');
      checkVeil();
    }
    function onBackendResponse(response) {
      if (!response.config || !response.config._ignoreVeil) popPromise(response.config);
      checkVeil();
      return response;
    }
    function onBackendError(response) {
      if (response.config) popPromise(response.config); else checkVeil();
      if (response.status == -1) {
        // TODO: quizas sea necesario hacer algo cuando se aborte el request. Navegar a la página previa?
      } else if (!response.config._ignoreError) {
        var error = response.data;
        throw error;
      }
      return $q.reject(response);
    }
  }
})();
