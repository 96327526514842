// data = {
//   name: String, (REQUERIDO)
//   description: String, (REQUERIDO)
//   translation: [{
//     code: String, /// en_US, es_AR, es, en,
//     name: String,
//     description: String
//   }],
// }
// Key = String ('name', 'description')
// languague = String ('en_US', 'es_AR', 'es', 'en')
(function () {
  angular.module('pentaApp').filter('language', filter);
  filter.$inject = ['$rootScope'];
  function filter($rootScope) {
    return function (data, key) {
      if (!data) return ''; //- Si no hay data retorna vacio.
      if (!key) return ''; //- Si no hay key retorna vacio.
      if (!$rootScope.appLanguage) {
        if (key) return data[key]; //- Si no hay language y hay key retorna data[key].
        else return ''; //- Si no hay language ni hay key retorna vacio.
      }
      if (!data.translation || !data.translation.length) return data[key]; //- si no hay un lenguaje configurado retorna data[key];
      var translation = data.translation.find(function (f) { return f.code === $rootScope.appLanguage && f.key === key });
      if (!translation) return data[key];
      switch (translation.type) {
        case 'TEXT':
        case 'TEXTAREA':
          if (!translation.text) return data[key];
          return translation.text;
        case 'TABLE':
          if (!translation.table) return data[key];
          return translation.table
        case 'IMAGE':
          if (!translation.image) return data[key];
          return translation.image
        default: return data[key];
      }
    }
  }
})();