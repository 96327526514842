'use strict';

// (function () {
//   angular.module('pentaApp').filter('trustThisUrl', ["$sce", function ($sce) {
//     return function (val) {
//         return $sce.trustAsResourceUrl(val);
//     };
// }]);
// })();

(function () {
  angular
    .module('pentaApp')
    .filter('trustThisUrl', filter);

  filter.$inject = ['$sce'];
  function filter ($sce) {
    return function (val) {
        return $sce.trustAsResourceUrl(val);
    };
  };
})();