'use strict';
(function () {
  var _controller = 'userConf.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$q', '$timeout', 'profile.resource', 'additionalInformation.resource', 'clientUtils.factory'];
  function controller($scope, $q, $timeout, profileResource, additionalInformationResource, clientUtilsFactory) {
    /// METHODS
    $scope.save = save;

    /// VARS
    $scope.profileResource = profileResource;
    $scope.additionalInformations = [];
    var additionalInformationProfile = $scope.$root.profile && $scope.$root.profile.enterprise && $scope.$root.profile.enterprise.additionalInformation || [];
    let timeout;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('user.identificationNumber', (newVal, oldVal) => {
      if (!newVal || newVal === oldVal) return
      if (timeout) $timeout.cancel(timeout)
      timeout = $timeout(function () {
        checkIdDupe(newVal)
      }, 1000);
    })

    /// INIT
    init();

    /////////////////////

    function init() {
      $scope.promise = $q.defer();
      profileResource.get({ _controller: _controller, enterprise: window.enterprise, allFields: true }, function (pf) {
        $scope.user = pf;
        if ($scope.user.birthday) {
          var values = $scope.user.birthday.split('T')[0].split('-')
          $scope.user.bDay = parseInt(values[2]);
          $scope.user.bMonth = values[1];
          $scope.user.bYear = parseInt(values[0]);
        }
        $scope.promise.resolve();
      })
      additionalInformationResource.query({ targets: ['CLIENT-PROFILE'], _controller: _controller })
        .$promise
        .then(function (data) {
          $scope.additionalInformations = data.filter(function (f) {
            var ai = additionalInformationProfile.find(function (a) { return a._id === f._id });
            if (ai && ai.value !== undefined) f.value = ai.value;
            return f.type !== 'FILE'
          })
        })
        .catch(function (err) { if (err) throw new PentaError(err) })
    }

    function save() {
      $scope.user._controller = _controller;
      delete $scope.user.enterprise;
      delete $scope.user.missingFields;

      if (!$scope.user.birthday && $scope.user.bYear && $scope.user.bMonth && $scope.user.bDay)
        $scope.user.birthday = $scope.user.bYear + '-' + $scope.user.bMonth + '-' + $scope.user.bDay;
      profileResource.save($scope.user, function () {
        if (typeof $scope.persistInProfile === 'function') $scope.persistInProfile();
        if ($scope.user.media) $scope.$root.setAvatarUrl();
        window.profile.missingFields = [];
        window.loadProfile({}, function () {
          if (appNavigator.topPage.data && appNavigator.topPage.data.onClose)
            appNavigator.topPage.data.onClose();
          else
            $scope.$root.resetToLandingPage();
        })
      })
    }

    async function checkIdDupe(identificationNumber) {
      try {
        $scope.duplicateMessage = undefined;
        if (!profile.enterprise.pwaConfig.idDupeAlert) return
        if (!identificationNumber) return
        let idDuped = await clientUtilsFactory.checkIdDupe(identificationNumber)
        if (!idDuped || !idDuped.length) return
        $scope.duplicateMessage = clientUtilsFactory.replaceIdDupeMessage(window.profile.enterprise.pwaConfig.idDupeAlertMessage, idDuped[0].email)
        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
      $timeout.cancel(timeout);
    }

    //// FIN CONTROLLER
  }
})();
