'use strict';
(function () {
    angular.module('pentaApp').factory('manualGeolocation.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/manualGeolocation/manualGeolocation.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data'];
  function controller($scope, modal, data) {
    $scope.data = data;
    $scope.options = $scope.options = { clickableIcons: false, disableDefaultUI: true };
    var contentHeight = $('.page__content').height()
    $scope.modalHeight = contentHeight - 60 + 'px'
    var lat = data.latitude;
    var lng = data.longitude;
    // var gmapLatLng = new google.maps.LatLng(lat, lng);
    $scope.map = {
      center: {
        latitude: Number(lat),
        longitude: Number(lng)
      },
      zoom: 8,
      bounds: {},
      control: {}
    }

    $scope.markers = createMarkers(data.routers)

    function createMarkers(routers) {
      if (!routers) return
      var idCounter = 0
      return routers
        .filter(function (router) {
          if (!router.geolocalization || !router.geolocalization.coordinates || !router.geolocalization.coordinates[0] || !router.geolocalization.coordinates[1]) return;
          return true
        })
        .map(function (router) {
          idCounter++
          var thisMarker = {
            id: idCounter,
            latitude: router.geolocalization.coordinates[0],
            longitude: router.geolocalization.coordinates[1]
          }
          return thisMarker
        })
    }

    $scope.accept = function () {
      modal.close($scope.map.center)
    };
    $scope.close = function () { modal.close() };
    ///////////////////////
  }

})();
