'use strict';
(function () {
  var _controller = 'mercadoPagoByApi.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'router.resource', 'mercadoPagoByApi.resource', '$timeout', 'clientCard.resource'];
  function controller($scope, routerResource, mpResource, $timeout, clientCardResource) {
    /// METHODS
    $scope.guessingPaymentMethod = guessingPaymentMethod;
    $scope.send = send;
    $scope.simulate = simulate;
    $scope.useCard = useCard;

    /// VARS
    Mercadopago.clearSession()
    window.Mercadopago.getIdentificationTypes(function(status, resp){
      $scope.docTypes = resp;
      if(!$scope.$$phase){
        $scope.$apply();
      }
    });
    var onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    var extraData = appNavigator.topPage.data && appNavigator.topPage.data.extraData;

    $scope.comment = appNavigator.topPage.data && appNavigator.topPage.data.comment;
    $scope.amount = appNavigator.topPage.data && appNavigator.topPage.data.amount;
    $scope.type = appNavigator.topPage.data && appNavigator.topPage.data.type;
    $scope.onlyPayData = appNavigator.topPage.data && appNavigator.topPage.data.onlyPayData;
    
    /// QUITO FUNCIONALIDAD DE TARJETAS GUARDADAS
    //$scope.clientCards = clientCardResource.query({_controller: _controller}, function(cards){
      //cards.forEach(function(card){
        //card._last = card.cardNumber.substr(card.cardNumber.length - 4);
      //})
    //});

    // WATCHERS

    /// INIT
    init();


    /////////////////////

    function init() {
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    function simulate(){
      onClose();
    }

    function send(){
      var doSubmit = false;
      doPay()


      ///
      var cardNumber;
      var cardholderName;
      var cardExpirationMonth;
      var cardExpirationYear;
      var docType;
      var docNumber;
      function doPay(){
        if(!doSubmit){
          var input = document.createElement('input');
          input.setAttribute('name', 'email');
          input.setAttribute('type', 'hidden');
          if(extraData && extraData.email) input.setAttribute('value', extraData.email);     

          var $form = document.querySelector('#pay');
          cardholderName = $form.cardholderName.value;
          cardNumber = $form.cardNumber.value;
          cardExpirationYear = $form.cardExpirationYear.value;
          cardExpirationMonth = $form.cardExpirationMonth.value;
          docType = $form.docType.value;
          docNumber = $form.docNumber.value;
          $form.appendChild(input);
          window.Mercadopago.createToken($form, sdkResponseHandler); // The function "sdkResponseHandler" is defined below
          return false;
        }
      };

      function sdkResponseHandler(status, response) {
        if (status != 200 && status != 201) {
          return ons.notification.alert({title: 'Error', message: i18next.t('Los datos ingresados no son correctos')})
        }
        else{
          doSubmit=true;
          var data = getFormData($("#pay"));
          data.token = response.id
          data._controller = _controller;
          data.enterprise = window.enterprise;
          data.cardholderName = cardholderName;
          data.cardNumber = cardNumber;
          data.cardExpirationYear = cardExpirationYear;
          data.cardExpirationMonth = cardExpirationMonth;
          data.docType = docType;
          data.docNumber = docNumber;
          data.saveCard = $scope.saveCard;
          if(extraData) Object.assign(data, extraData);
          if($scope.onlyPayData && onClose && typeof onClose === 'function'){
            return appNavigator.popPage({
              callback: function(){onClose(data)}
            })
          }
          mpResource.pay(data, function(resp){
            if(resp.status === 'approved'){
              $scope.done = true;
              $timeout(function(){
                onClose(data)
                appNavigator.popPage({
                  callback: function(){onClose(data)}
                })
              }, 2000)
            }
            else if(resp.status === 'in_process')
              $scope.pending = true;
            else if(resp.status === 'rejected')
              $scope.rejected = true;
            $scope.statusDetail = resp.detail;
          });
        }
      };
    }

    function getFormData($form){
        var unindexed_array = $form.serializeArray();
        var indexed_array = {};

        $.map(unindexed_array, function(n, i){
            indexed_array[n['name']] = n['value'];
        });
        return indexed_array;
    }

    function getBin() {
      var cardnumber = document.getElementById("cardNumber");
      return cardnumber.value.replace(/\s+/g, '').substring(0,6);
    }

    function guessingPaymentMethod(event) {
        var bin = getBin();
        if (bin.length >= 6) {
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, setPaymentMethodInfo);
        }
    };

    function useCard(card){
      if(!card) return $scope.clientCards = [];
      ons.notification.prompt({message: 'Ingrese el código de seguridad', inputType: 'number', title: '', cancelable: true}).then(function(sc) {
        if(!sc) return;
        $timeout(function(){
          $scope.$root._loadingVeil = true;
        })
        cardNumber.value = card.cardNumber;
        securityCode.value = sc;
        cardExpirationMonth.value = card.cardExpirationMonth;
        cardExpirationYear.value = card.cardExpirationYear;
        docNumber.value = card.docNumber;
        cardholderName.value = card.cardholderName;
        if ("createEvent" in document) {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            cardNumber.dispatchEvent(evt);
        }
        else
          cardNumber.fireEvent("onchange");

        $timeout(function(){
          $scope.$root._loadingVeil = false;
          send();
        }, 3000)

      });
    }

    function setPaymentMethodInfo(status, response) {
        if (status == 200) {
            var paymentMethodElement = document.querySelector('input[name=paymentMethodId]');

            if (paymentMethodElement) {
                paymentMethodElement.value = response[0].id;
            } else {
                var input = document.createElement('input');
                input.setAttribute('name', 'paymentMethodId');
                input.setAttribute('type', 'hidden');
                input.setAttribute('value', response[0].id);

                form.appendChild(input);
            }
        } else {
          document.getElementById('cardNumber').blur()
          return ons.notification.alert({title: 'Error', message: i18next.t('Está ingresando un número de tarjeta no válido para pagar')})
        }
    };

    //// FIN CONTROLLER
  }
})();
