(function () {
  angular.module('pentaApp').factory("resourceHelper.factory", factory);
  factory.$inject = ['$resource', 'Upload', 'httpInterceptor', '$q', '$rootScope', 'resourceHelperUtils.factory'];
  function factory($resource, Upload, httpInterceptor, $q, $rootScope, resourceHelperUtils) {
    /// RETURN
    var uploadQueue = $q.resolve();

    return {
      defaultResource: defaultResource
    }

    ////////////////////////////////////////
    function defaultResource(url, extraMethods, useSpaces) {
      if (!url) throw new PentaError('ERR NO URL');
      var result = $resource(url, {}, extraMethods);
      var _save = result.save
      result.save = save;
      result.getFileUrl = getFileUrl;
      result.downloadAs = downloadAs;
      return result;

      function save(data, done) {
        var key, file;
        let files = {};
        let fields = {};
        Object.getOwnPropertyNames(data).forEach(function (f) {
          if (data[f] && (data[f] instanceof File || data[f].constructor.name === 'File' || data[f] instanceof Blob)) {
            key = f;
            file = data[f]
            files[f] = data[f]
          } else {
            fields[f] = data[f]
          }
        })
        if (file) {
          if (!useSpaces) {
            var request = { _controller: data._controller };
            request[key] = file;
            request.body = JSON.stringify(data);
            Object.defineProperty(request, '_progress', { value: 0, enumerable: false, writable: true });
            Upload
              .upload({
                url: url,
                data: request
              })
              .success(function (saved) {
                angular.copy(saved, request)
                if (done) done(saved);
              })
              .progress(function (evt) { request._progress = parseInt(100.0 * evt.loaded / evt.total); });
            return request;
          } else {
            var defer = $q.defer()
            var newObj = { $promise: defer.promise }
            uploadQueue = uploadQueue
              .then(function () {
                let spacesApiUrl = '/progressiveApp/api/spaces'
                httpInterceptor.showLoadingVeil();
                return resourceHelperUtils.uploadTask(newObj, fields, files, result, $rootScope, useSpaces, url, spacesApiUrl)
              })
              .then(function (result) {
                defer.resolve(result)
                if (done) done(result)
              })
              .catch(function (err) {
                uploadQueue = $q.resolve();
                var filenames = []
                Object.getOwnPropertyNames(files).forEach(function (f) { filenames.push(files[f].name) })
                defer.reject(new PentaError(i18next.t('Ocurrió un error al subir ' + filenames.join(','))).extraInfo('err', err));
              })
              .finally(function () { httpInterceptor.hideLoadingVeil() })
            return newObj;
          }
        } else {
          return done ? _save(data, done) : _save(data)
        }
      }

      function downloadAs(url, name) {
        return resourceHelperUtils.downloadAs(url, name, $rootScope, httpInterceptor);
      }

      function getFileUrl(object, filename, crc, apiUrl, updatedFiles, sharedCfg) {
        if (!filename) return alert('getFileUrl needs filename argument');
        if (!object || typeof object === 'object' && !object._id) return '//:0';
        var queryString = url + '?_file=' + filename;
        queryString += '&_id=' + object._id;
        queryString += '&enterprise=' + enterprise;
        return queryString;
      }
    }
  }


})();
