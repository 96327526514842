'use strict';
(function () {
  angular.module('pentaApp').factory('clubCard.resource', service);

  service.$inject = ['$resource'];
  function service($resource) {
    return $resource('/progressiveApp/api/clubCard');
  }

})();
