(function () {
  var _controller = 'ntnMosaicViewVertical.component.controller' + Date.now();
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('ntnMosaicViewVertical', {
      templateUrl: 'components/ntnMosaicViewVertical/ntnMosaicViewVertical.component.jade',
      controller: _controller,
      bindings: {
        mosaic: '<', //- objeto de categoría ( grupo en db )
        onClick: '=', //- Function
        config: '<', //- Object. { hideShadow: Boolean, hideName: Boolean, imagesPerRow: Number ( default undefine ) }
      }
    })

  controller.$inject = ['$scope', '$element', 'image.resource'];
  function controller($scope, $element, imageResource) {
    var that = this;
    //// METHODS
    that.openMosaic = openMosaic;

    //// VARS
    that.imageResource = imageResource;

    //// WATCHERS
    $scope.$on('$destroy', onDestroy);

    //// INIT
    this.$onInit = function () {
      if (!this.mosaic) return
      if (!this.config) this.config = {};
      init()
    };

    ////////////
    function init() {
      if (!that.mosaic) return
      calcElementSize();
      setImageBackground();
    }

    function calcElementSize() {
      var parentNodeOffsetWidth = $element[0].parentNode.parentNode.offsetWidth;
      var parentNodePaddingLeft = window.getComputedStyle($element[0].parentNode.parentNode, null).getPropertyValue('padding-left');
      var parentNodePaddingRigth = window.getComputedStyle($element[0].parentNode.parentNode, null).getPropertyValue('padding-right');
      var ntnMallMosaicViewComponentElement = $element[0].querySelector('.ntn-mosaic-view-vertical-component');
      var ntnMallMosaicViewComponentElementMarginLeft = window.getComputedStyle(ntnMallMosaicViewComponentElement, null).getPropertyValue('margin-left');
      var ntnMallMosaicViewComponentElementMarginRigth = window.getComputedStyle(ntnMallMosaicViewComponentElement, null).getPropertyValue('margin-right');
      var pxToRestParent = (parentNodePaddingLeft && Number(parentNodePaddingLeft.match(/\d+/g)[0]) || 0) +
        (parentNodePaddingRigth && Number(parentNodePaddingRigth.match(/\d+/g)[0]) || 0);
      var pxToRestComponent = (ntnMallMosaicViewComponentElementMarginLeft && Number(ntnMallMosaicViewComponentElementMarginLeft.match(/\d+/g)[0]) || 0) +
        (ntnMallMosaicViewComponentElementMarginRigth && Number(ntnMallMosaicViewComponentElementMarginRigth.match(/\d+/g)[0]) || 0);
      var divider = (that.config && that.config.imagesPerRow) || ((parentNodeOffsetWidth > 450) ? 3 : 2)
      var px = ((parentNodeOffsetWidth - pxToRestParent) / divider) - pxToRestComponent;
      ntnMallMosaicViewComponentElement.style.maxHeight = px + 'px';
      ntnMallMosaicViewComponentElement.style.maxWidth = px + 'px';
      ntnMallMosaicViewComponentElement.style.minHeight = px + 'px';
      ntnMallMosaicViewComponentElement.style.minWidth = px + 'px';
    }

    function setImageBackground() {
      if (!that.mosaic || !that.mosaic.image) return
      var ntnMallMosaicViewComponentElement = $element[0].querySelector('.ntn-mosaic-view-vertical-component');
      if (!ntnMallMosaicViewComponentElement) return
      var imageUrl = imageResource.getFileUrl(that.mosaic.image, 'image');
      if (!imageUrl) return
      ntnMallMosaicViewComponentElement.style.backgroundImage = 'url(' + imageUrl + ')';
      ntnMallMosaicViewComponentElement.style.backgroundRepeat = 'no-repeat';
      ntnMallMosaicViewComponentElement.style.backgroundSize = '100% 100%';
    }

    function openMosaic(mosaic) {
      if (!mosaic || !mosaic._id) return
      if (that.onClick && typeof that.onClick === 'function') that.onClick(mosaic);
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    // FIN CONTROLLER
  }
})();
