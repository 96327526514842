(function () {
  angular.module('pentaApp')
    .filter("find", function () {
      return function (array, value, field) {
        if(!array) return;
        if(!array.find) return console.error('findFilter -> array.find undefined');
        if(!field) field = '_id';
        return array.find(function(f){ return f[field] === value; })
      }
    });
})();