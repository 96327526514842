(function () {
  angular.module('pentaApp').directive('autoScroll', autoScroll);

  function autoScroll() {
    return function (scope, element, attrs) {
      if (scope.$last) {
        var totalHeight = element[0].parentNode.scrollHeight;
        var currentScroll = element[0].parentNode.scrollTop + element[0].parentNode.clientHeight;
        if (attrs.autoScroll === "force" || currentScroll + 75 >= totalHeight) setTimeout(function () { element[0].scrollIntoView() }, 0);
      }
    };
  }
})();