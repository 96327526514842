'use strict';
(function () {
  angular.module('pentaApp').factory('pagouno.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/pagouno/pagouno.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data', '$http'];
  function controller($scope, modal, modalData, $http) {
    //// Methods
    $scope.close = function(){modal.close()}
    $scope.send = send;

    /// Vars
    $scope.data = {
      card_holder: {},
      identification: {
        document_type: 'DNI'
      },
    }

    $scope.docTypes = [
      {name: 'DNI', id: 'DNI'},
      {name: 'CUIL', id: 'CUIL'}
    ]

    $scope.expDate = {};

    if(!modalData.router) throw new PentaError('No se especifica sucursal para pagos con Pagouno')
    var router = $scope.$root.routers.find(function(f){return f._id === modalData.router})

    ///////////////////////

    function send(){
      if(!router || !router.pagounoCfg || !router.pagounoCfg.publicKey) throw new PentaError('LLave pública de Pagouno no configurada')
      $scope.data.email = profile.email;
      $scope.data.expiration_date = ($scope.expDate.expYear < 10 ? ('0' + $scope.expDate.expYear.toString()) : $scope.expDate.expYear.toString()) +
                                    ($scope.expDate.expMonth < 10 ? ('0' + $scope.expDate.expMonth.toString()) : $scope.expDate.expMonth.toString());
      $http({
        method: 'POST',
        url: 'https://api.pagouno.com/v1/Transaction/token',
        data: [$scope.data],
        headers: {
          'Content-Type': 'application/json',
          'Authorization': router.pagounoCfg.publicKey
        },
      }).then(function(response){
        if(response.status !== 200) return alert(i18next.t('Parece haber un problema con el gateway de pagos (pagouno), intente mas tarde'));
        if(!response.data) return alert(i18next.t('Respuesta inválida de pagouno'));
        if(response.data.status === 403) return alert(i18next.t('Clave pública de Pagouno mal configurada'));
        if(response.data.status !== 200) return alert(i18next.t('Error inesperado con PagoUno, contactarse con el vendedor'));
        /// Si llegó hasta acá es porque está todo ok
        var data = response.data.data[0];
        modal.close({
          cardNumber: $scope.data.primary_account_number,
          cardholderName: $scope.data.card_holder.front_name,
          paymentMethodId: data.id
        });
      }, function(err){
        console.log(err)
      })

    }

    function alert(text){
      ons.notification.alert(text, {title: i18next.t('Ups, algo pasó')});
    }

    /// fin ctrl
  }

})();
