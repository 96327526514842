(function () {
    angular.module('pentaApp').directive('imgAutoHide', directive);

    directive.$inject = ['$http'];

    function directive($http) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var isVideo = element[0].nodeName === "VIDEO"
                attrs.$observe('ngSrc', function (ngSrc) {
                    setTimeout(function () {
                        if (isVideo) return;
                        var image = new Image()
                        image.src = ngSrc
                        image.onload = function(){
                          //element.attr('src', image.src)
                          image.onerror = null
                          image.onload = null
                          image = null
                        }
                        image.onerror = function(){
                          element[0].style.display = 'none'
                          image.onerror = null
                          image.onload = null
                          image = null
                        }
                    }, 0);
                });
            }
        };
    }
})();
