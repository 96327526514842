(function () {
  var _controller = 'ntnMosaicViewHorizontally.component.controller' + Date.now();
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('ntnMosaicViewHorizontally', {
      templateUrl: 'components/ntnMosaicViewHorizontally/ntnMosaicViewHorizontally.component.jade',
      controller: _controller,
      bindings: {
        mosaics: '<', //- objeto de categoría ( grupo en db )
        selectedMosaics: '<', //- [_id] 
        onClick: '=',
        config: '<', //- objecto { hideScrollBar: Boolean }
      }
    })

  controller.$inject = ['$scope', '$element', 'image.resource'];
  function controller($scope, $element, imageResource) {
    var that = this;
    //// METHODS
    that.openMosaic = openMosaic;

    //// VARS
    that.imageResource = imageResource;

    //// WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('$ctrl.selectedMosaics.length', function (newVal, oldVal) {
      if (newVal === oldVal) return
      checkSelected()
    }, true)

    //// INIT
    this.$onInit = function () {
      if (!this.mosaics || !this.mosaics.length) return
      if (!this.config) this.config = {};
      init()
    };

    ////////////
    function init() {
      if (!that.mosaics || !that.mosaics.length) return
      setTimeout(function () {
        setComponentStyle();
        if (that.config && that.config.hideScrollBar) hideComponentScrollBar();
        that.componentReady = true;
      }, 0)
    }

    function setComponentStyle() {
      $element[0].parentElement.style.width = '100%'; //- Seteo el padre con width 100%
      //- Aquí se calcula el valor del ancho dependiendo del tamaño de la pantalla
      //- en relación de aspecto 16/9
      var parentNodeOffsetHeight = $element[0].offsetHeight;
      var width = Math.round((parentNodeOffsetHeight / 9) * 16); //- Calcular el ancho de 16/9;
      var mosaicViewCards = Array.from($element[0].querySelectorAll('.mosaic-view-card'));
      if (mosaicViewCards && mosaicViewCards.length) {
        mosaicViewCards.forEach(function (mosaicViewCard) {
          // mosaicViewCard.style.width = width + 'px';
          mosaicViewCard.style.width = '100%';
          mosaicViewCard.style.minWidth = width + 'px';
          // mosaicViewCard.style.maxWidth = width + 'px';
        })
        if (mosaicViewCards.length <= 3) $element[0].firstChild.style.justifyContent = 'space-around';
      }
    }

    function hideComponentScrollBar() {
      var mosaicViewHorizontallyComponent = $element[0].querySelector('.ntn-mosaic-view-horizontally-component');
      if (!mosaicViewHorizontallyComponent) return
      mosaicViewHorizontallyComponent.classList.add('hide-scroll-bar');
    }

    function checkSelected() {
      if (!that.selectedMosaics || !that.selectedMosaics.length) return unSelectedAll();
      that.mosaics.forEach(function (mosaic) {
        mosaic._selected = false;
        selectedMosaic(mosaic);
      })
    }

    function selectedMosaic(mosaic) {
      if (!mosaic || !mosaic._id) return
      if (that.selectedMosaics.find(function (f) { return mosaic._id === f })) mosaic._selected = true;
    }

    function unSelectedAll() {
      if (!that.mosaics || !that.mosaics.length) return
      that.mosaics.forEach(function (mosaic) { mosaic._selected = false })
    }

    function openMosaic(mosaic) {
      if (!mosaic || !mosaic._id) return
      if (that.onClick && typeof that.onClick === 'function') that.onClick(mosaic);
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    // FIN CONTROLLER
  }
})();
