(function () {
  angular.module('pentaApp').directive('isEmail', directive);
  directive.$inject = ['$compile', '$q'];
  function directive($compile, $q) {
    var EMAIL_PATTERN = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$';
    var EMAIL_REGEXP = new RegExp(EMAIL_PATTERN, 'i');
    return {
        require: 'ngModel',
        link: function(scope, elem, attrs, ngModel){
            function validate(value) {
                var valid = angular.isUndefined(value)
                    || value.length === 0
                    || EMAIL_REGEXP.test(value);
                ngModel.$setValidity('email', valid);
                return valid ? value : undefined;
            }
            ngModel.$formatters.unshift(validate);
            ngModel.$parsers.unshift(validate);
            elem.attr('pattern', EMAIL_PATTERN);
            elem.attr('title', 'Invalid email address');
        }
    };
  }

})();
