'use strict';
(function () {
  angular.module('pentaApp').factory('qrGenerate.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/qrGenerate/qrGenerate.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data'];
  function controller($scope, modal, data) {
    $scope.close = function () { modal.close() }
    $scope.data = data
    ///////////////////////
  }

})();
