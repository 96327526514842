
(function () {
  if (angular) angular.module('pentaApp').factory('resourceHelperUtils.factory', service);
  service.$inject = ['$http'];
  function service($http) {
    var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    var img = document.createElement('img');
    var video = document.createElement('video');
    var canvas = document.createElement('canvas');
    var canvasCtx = canvas.getContext('2d');
    var previewSize = 128;
    var previewFrames = 15;

    return {
      uploadTask,
      humanFileSize,
      getFileUrl,
      downloadAs
    }

    async function uploadTask(result, fields, files, resource, $rootScope, useSpaces, apiUrl, spacesApiUrl) {
      try {
        var fileKeys = Object.getOwnPropertyNames(files);
        if (fileKeys.length > 1) return $q.reject(new PentaError('No se soportan modelos con multiples archivos en SPACES'));

        var file = files[fileKeys[0]];
        var fieldName = fileKeys[0];

        Object.defineProperty(result, 'fileName', { value: file.name, enumerable: false, writable: true });
        Object.defineProperty(result, '_step', { value: file.name, enumerable: false, writable: true });
        Object.defineProperty(result, '_progress', { value: 0, enumerable: false, writable: true });
        Object.defineProperty(result, '_textProgress', { value: 0, enumerable: false, writable: true });

        $rootScope._fileUploadProgress = result;

        const metadata = await getMetadata(file, result, img, video).catch(err => { throw new PentaError('3333') })
        let isSvg = fields.mediaType === 'SVG' //parche: porque al subir formas svg se pisaban con "IMAGE" y no andaba
        if (metadata) Object.assign(fields, metadata);
        if (isSvg) fields.mediaType = 'SVG'
        fields.mimeType = file.type;
        fields.fileSize = file.size;
        fields.fileName = file.name;
        const crc = await calculateCRC($rootScope, blobSlice, result, file)
        fields.crc = crc;
        fields.pendingRecompression = true;
        setProgress($rootScope, result, 'Actualizando');
        const saveResult = resource._save ? await resource._save(fields).$promise : await resource.save(fields).$promise
        if (saveResult._id) fields._id = saveResult._id;
        if (!saveResult._id) throw new PentaError('No se pudo determinar el archivo a actualizar');
        const preview = await createPreview($rootScope, result, file, fields)
        if (preview) await uploadBlob($rootScope, result, fields, fieldName, preview, true, useSpaces, apiUrl, spacesApiUrl)
        await uploadBlob($rootScope, result, fields, fieldName, file, false, useSpaces, apiUrl, spacesApiUrl)
        setProgress($rootScope, result, 'Publicando')
        let body = { _id: fields._id, pendingRecompression: false }
        if (fields.enterprise && fields.xEnterprise) {
          body.enterprise = fields.enterprise;
          body.xEnterprise = fields.xEnterprise;
        }
        const dbResult = resource._save ? await resource._save(body).$promise : await resource.save(body).$promise
        angular.extend(result, dbResult);

        $rootScope._fileUploadProgress = null;
        if (img.src) {
          URL.revokeObjectURL(img.src)
          img.removeAttribute('src')
        }
        if (video.src) {
          URL.revokeObjectURL(video.src)
          video.removeAttribute('src')
          video.load();
        }
        return result;
      } catch (err) { throw new PentaError(err) }
    }

    function getMetadata(file, result, img, video) {
      return new Promise((resolve, reject) => {
        result._step = 'Leyendo metadata'
        switch (fileType(file)) {
          case 'image':
            img.onload = () => resolve({
              mediaType: 'IMAGE',
              width: img.naturalWidth,
              height: img.naturalHeight,
            })
            img.onerror = (err) => reject(new PentaError(err))
            img.src = URL.createObjectURL(file)
            break;
          case 'video':
            video.oncanplaythrough = () => resolve({
              mediaType: !video.videoWidth || !video.videoHeight ? 'AUDIO' : 'VIDEO',
              width: video.videoWidth,
              height: video.videoHeight,
              duration: video.duration * 1000
            })
            video.onerror = (err) => reject(new PentaError(err))
            video.src = URL.createObjectURL(file)
            break;
          default: resolve()
        }
      })
    }

    async function calculateCRC($rootScope, blobSlice, result, file) {
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      setProgress($rootScope, result, 'Calculando CRC', 0, 2)
      const firstChunk = await readChunk(fileReader, file, 0, Math.min(500, Math.ceil(file.size * 0.1)), blobSlice)
      setProgress($rootScope, result, null, 1, 2)
      spark.append(firstChunk);
      const secondChunk = await readChunk(fileReader, file, Math.max(file.size - 500, Math.floor(file.size * 0.9)), file.size, blobSlice)
      setProgress($rootScope, result, null, 2, 2)
      spark.append(secondChunk);
      const buffer = new ArrayBuffer(4);
      const view = new Int32Array(buffer);
      view.set([file.size], 0);
      spark.append(buffer);
      return spark.end();
    }

    function readChunk(fileReader, file, start, end, blobSlice) {
      return new Promise((resolve, reject) => {
        fileReader.onerror = () => reject(fileReader.error)
        fileReader.onload = (e) => resolve(e.target.result)
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      })
    }

    async function createPreview($rootScope, result, file, metadata) {
      switch (metadata.mediaType) {
        case 'IMAGE':
          if (/image\/gif|image\/svg\+xml/.test(file.type)) return file;
          setProgress($rootScope, result, 'Generando preview', 0, 1)
          resizePreviewCanvas(metadata, canvas, previewSize)
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
          canvasCtx.drawImage(img, 0, 0, canvas.width, canvas.height);
          setProgress($rootScope, result, null, 1, 1)
          return await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (!blob) reject(new PentaError('No se pudo generar el preview de la imagen'))
              resolve(blob)
            }, 'image/webp', 0.5)
          })
        case 'VIDEO':
          resizePreviewCanvas(metadata, canvas, previewSize);
          var gif = new GIF({
            quality: 30,
            dither: 'FloydSteinberg-serpentine',
            width: canvas.width,
            height: canvas.height,
            workerScript: GIF.workerScript || '/frontend/bower_components/gif.js/dist/gif.worker.js'
          })
          setProgress($rootScope, result, 'GenerandoPreview', 0, previewFrames)
          let frameStep = metadata.duration / (previewFrames - 1)
          for (let frame = 0; frame < previewFrames; frame++) {
            await addFrame((frameStep * frame), gif, video, canvas, canvasCtx)
            setProgress($rootScope, result, null, frame, previewFrames)
          }
          const finishedGif = await createGif(gif);
          return finishedGif
        default: return
      }
    }

    function resizePreviewCanvas(metadata, canvas, previewSize) {
      const as = metadata.width / metadata.height;
      let width, height;
      if (as > 1) {
        width = previewSize
        height = width / as
      } else {
        height = previewSize
        width = height * as
      }
      canvas.width = width;
      canvas.height = height;
    }

    function addFrame(time, gif, video, canvas, canvasCtx) {
      return new Promise((resolve, reject) => {
        const loadTimeout = setTimeout(() => reject(new PentaError('Tiempo superado para generar el preview')), 10000)
        video.onseeked = () => {
          try {
            clearTimeout(loadTimeout)
            canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height)
            gif.addFrame(canvasCtx, { copy: true, delay: 1000 })
            resolve()
          } catch (error) { reject(new PentaError(err)) }
        }
        video.currentTime = time / 1000
      })
    }

    function createGif(gif) {
      return new Promise(function (success, fail) {
        var loadTO = setTimeout(function () { fail(new PentaError('Ha ocurrido un error al generar el preview')) }, 30000);
        gif.on('finished', function (blob) {
          clearTimeout(loadTO);
          success(blob)
        });
        gif.render();
      })
    }

    async function uploadBlob($rootScope, result, fields, fieldName, blob, isPreview, useSpaces, apiUrl, spacesApiUrl) {
      if (typeof useSpaces.customMapper === 'function') fieldName = useSpaces.customMapper(fields, fieldName, blob, isPreview);
      else if (isPreview) fieldName = 'preview';
      setProgress($rootScope, result, 'Subiendo ' + fieldName, 0, 100);
      let model = useSpaces.model || apiUrl.split('/').pop();
      let params = { _id: fields._id, model: model, file: fieldName }
      if (fields.enterprise && fields.xEnterprise) {
        params.enterprise = fields.enterprise;
        params.xEnterprise = fields.xEnterprise;
      }
      const spacesURL = await $http.get(spacesApiUrl, { params: params })
      return await xhrUpload($rootScope, result, spacesURL.data.signedURL, blob.type, blob)
    }

    function xhrUpload($rootScope, result, url, mimeType, blob) {
      return new Promise((resolve, reject) => {
        let match
        const extension = (match = /(\.\w+)$/.exec(result.fileName)) ? match[1] : ''
        const name = (match = /^(\w+)\//.exec(mimeType)) ? match[1] : 'file'
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", mimeType)
        xhr.setRequestHeader("x-amz-acl", "public-read");
        xhr.setRequestHeader("Content-Disposition", `attachment; filename=${name}${extension}`)
        if (xhr.upload) xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const current = 100 * event.loaded / event.total
            setProgress($rootScope, result, null, current, 100, current.toFixed(2) + '% (' + humanFileSize(event.loaded) + '/' + humanFileSize(event.total) + ')')
          }
        }
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const status = xhr.status;
            if (status >= 200 && status < 400) resolve(xhr.responseText)
            else reject(new PentaError(`Error en XHR - statusText: ${xhr.statusText} - responseText: ${xhr.responseText}`).extraInfo('status', xhr.status))
            xhr.upload.onprogress = null
          }
        }
        xhr.send(blob)
      })
    }



    function setProgress($rootScope, result, title, curr, max, text) {
      $rootScope.$applyAsync(function () {
        if (title) result._step = i18next.t(title)
        if (!curr && !max && !text) {
          result._textProgress = '';
          result._progress = 0;
        }
        else {
          if (max) {
            result._progress = curr / max * 100;
          }
          result._textProgress = text ? text : curr + ' / ' + max
        }
      })
    }

    function fileType(file) {
      if (file.type.startsWith('image')) return 'image';
      else if (file.type.startsWith('video')) return 'video';
      else 'other'
    }

    function humanFileSize(bytes) {
      var thresh = 1024;
      if (Math.abs(bytes) < thresh) return bytes + ' B';
      var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      var u = -1;
      var r = 10;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
    }

    function getFileUrl(object, filename, crc, apiUrl, updatedFiles, sharedCfg) {
      if (!filename) return alert('getFileUrl needs filename argument');
      if (!object || typeof object === 'object' && !object._id) return '//:0';
      let queryString = apiUrl + '?_file=' + filename;
      const _id = (object._id || object);
      queryString += '&_id=' + _id;
      if (crc) {
        queryString += '&crc=' + crc;
      }
      if (!sharedCfg?.useCookies) queryString += '&_apikey=' + localStorage.apikey;
      if (updatedFiles && updatedFiles[_id + '.' + filename])
        queryString += '&_refresh=' + updatedFiles[_id + '.' + filename];
      if (object.xEnterprises && object.xEnterprises.length)
        queryString += '&_xEnterprise=true'
      return queryString;
    };

    function downloadAs(url, name, $rootScope, httpInterceptor) {
      let fetchController = new AbortController();
      fetch(url, { signal: fetchController.signal })
        .then(response => {
          const contentLength = response.headers.get('content-length')
          let loaded = 0;
          $rootScope._fileUploadProgress = { fileName: name, _progress: 0, _textProgress: '0%', showAbort: true, abort: false }
          httpInterceptor.showLoadingVeil();

          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = response.body.getReader();
                read();

                function read() {
                  reader.read()
                    .then((progressEvent) => {
                      if ($rootScope._fileUploadProgress.abort) fetchController.abort()
                      if (progressEvent.done || $rootScope._fileUploadProgress.abort) {
                        controller.close();
                        return;
                      }
                      loaded += progressEvent.value.byteLength;
                      $rootScope._fileUploadProgress._progress = 100 * loaded / contentLength
                      $rootScope._fileUploadProgress._textProgress = $rootScope._fileUploadProgress._progress.toFixed(2) + '% (' + humanFileSize(loaded) + '/' + humanFileSize(contentLength) + ')'
                      controller.enqueue(progressEvent.value);
                      read();
                    })
                }
              }
            })
          )
        })
        .then(response => response.blob())
        .then(myBlob => {
          if (myBlob && $rootScope._fileUploadProgress && !$rootScope._fileUploadProgress.abort) {
            const objUrl = URL.createObjectURL(myBlob);
            const a = document.createElement("a");
            a.href = objUrl;
            a.download = name;
            a.click();
          }
        })
        .catch(err => { throw new PentaError(err) })
        .finally(function () {
          $rootScope._fileUploadProgress = null
          httpInterceptor.hideLoadingVeil()
        })
    }
  }
})();
