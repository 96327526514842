'use strict';
(function () {
  angular.module('pentaApp').factory('card.resource', service);

  service.$inject = ['$resource'];
  function service($resource) {
    return $resource('/progressiveApp/api/card', {}, {
      asociateCard: {
        method: 'POST',
        isArray: false
      }
    });
  }

})();