'use strict';
(function () {
  var _controller = 'turnTracking.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope'];
  function controller($scope) {
    /// METHODS

    /// VARS
    $scope.querystring = '';
    var hasQueryString = appNavigator.topPage.data.params;

    var externalURL = hasQueryString.production === true ? 'https://woxiturno.web.app/' : 'https://woxiturno-development.web.app/';
    // var idEmpresa = idSucursal = description = letter = place = "";


    /// INIT
    if (hasQueryString) {
      trackingTurnQueryString();
    }


    /////////////////////

    function trackingTurnQueryString() {
      var qs = hasQueryString;

      // replace empty value with a whitespace
      Object.keys(qs).forEach(function (key) {
        if (!qs[key]) {
          qs[key] = '%20';
        }
      });

      $scope.querystring = ''.concat(externalURL, '?idEmpresa=', qs.idEmpresa, '&idSucursal=', qs.idSucursal, '&description=', qs.description, '&letter=', qs.letter, '&place=', qs.place, '&fixSize=1');
    }

    //// FIN CONTROLLER
  }
})();
