'use strict';
(function () {
  var _controller = 'login.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$http', 'hq.socket'];
  function controller($scope, $http, hqSocket) {
    /// METHODS
    $scope.gotoRegister = gotoRegister;
    $scope.fbLogin = fbLogin;

    /// VARS
    var _onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    $scope.onLine = navigator.onLine;
    $scope.overwriteText = appNavigator.topPage.data.overwriteText;
    var onClose = function (data) {
      hqSocket.connect();
      if (_onClose) _onClose(data);
    }
    var allowAnonymous = appNavigator.topPage.data && appNavigator.topPage.data.allowAnonymous;
    var times = appNavigator.topPage.data && appNavigator.topPage.data.times || 1;

    $scope.credentials = {
      email: null,
      password: null
    };

    var ua = navigator.userAgent || navigator.vendor || window.opera;
    $scope.isInstagram = (ua && ua.indexOf('Instagram') > -1) ? true : false;

    init();

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    /////////////////////

    function init() {
      if ($scope.onLine) {
        activateFacebookLogin();
        activateGoogleLogin();
      }
    }

    ////// FACEBOOK-LOGIN
    function activateFacebookLogin(callback) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '357211158034257',
          cookie: true,  // enable cookies to allow the server to access 
          version: 'v3.2' // use graph api version 2.8
        });
      };
      if (!window.FB) {
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }
    }
    function fbLogin() {
      if (!window.FB) return console.log('FB not initialized');
      FB.login(function (response) {
        if (response.status === 'connected') {
          /// SI HACE LOGIN BIEN
          insightLogin({ facebook_id_token: response.authResponse.accessToken })
        }
        else {
          /// SI DA ERROR
          console.log('ERR', response);
        }
      }, { scope: 'email,user_birthday,user_gender,user_hometown' });
    }
    ////// FIN FACEBOOK-LOGIN 

    ///// GOOGLE LOGIN
    function activateGoogleLogin(callback) {
      window.gapiBootstrap = function () {
        if (typeof gapi === 'undefined') {
          return console.log("--- NO GAPI");
        }
        gapi.load('auth2', function () {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          var auth2 = gapi.auth2.init({
            client_id: '115178218059-9ab19ofjfht9o9lletfj9p1fuin6nvtt.apps.googleusercontent.com',
            cookie_policy: 'single_host_origin',
            scope: 'email profile openid'
          })
          auth2.then(function () {
            auth2.attachClickHandler(document.getElementById('google-login'), {},
              function (googleUser) {
                /// SI HACE LOGIN BIEN
                insightLogin({ google_id_token: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token })
              }, function (error) {
                /// SI DA ERROR
                return console.log(err);
              });
          });
        });
      }

      if (!window.gapi) {
        var script = document.createElement('script')
        script.src = "https://apis.google.com/js/platform.js?onload=gapiBootstrap"
        document.head.appendChild(script);
      }
      else window.gapiBootstrap();
    }
    ///// FIN GOOGLE



    function insightLogin(data) {
      data.enterprise = enterprise;
      data.lastDevice = {};
      data.frontendUtcOffset = formattedUtcOffset();
      populateLastDevice(data.lastDevice);
      $http.post('/clientApi/client', data, { params: { enterprise: enterprise, _controller: _controller } })
        .then(function (response) {
          if (!response || !response.data) return console.log('NO INSIGHT DATA LOGIN');
          loadProfile(function () { appNavigator.popPage({ callback: onClose }); })
        }, function (response) {
          handleError('ERR_LOGIN_INSIGHT', response);
        })
    }

    function gotoRegister() {
      appNavigator.pushPage('emailSignup/emailSignup.jade', {
        data: {
          times: times + 1,
          allowAnonymous: allowAnonymous,
          onClose: onClose
        }
      })
    }

    function populateLastDevice(lastDevice) {
      if (navigator && navigator.userAgent) lastDevice.userAgent = navigator.userAgent;
      if (!window.jscd) return;
      if (window.jscd && window.jscd.os && window.jscd.os !== '-') lastDevice.OS = window.jscd.os;
      if (window.jscd && window.jscd.osVersion && window.jscd.osVersion !== '-') lastDevice.OSVersion = window.jscd.osVersion;
      if (window.jscd && window.jscd.browser && window.jscd.browser !== '-') lastDevice.browser = window.jscd.browser;
      if (window.jscd && window.jscd.browserMajorVersion && window.jscd.browserMajorVersion !== '-') lastDevice.browserVersion = window.jscd.browserMajorVersion;
      if (window.jscd && window.jscd.screen && window.jscd.screen !== '-') lastDevice.screenSize = window.jscd.screen;
    }

    function formattedUtcOffset() {
      var off = -(new Date()).getTimezoneOffset() / 60;
      var resp = '';
      if (off < 0) resp += '-';
      else if (off >= 0) resp += '+';
      if (Math.abs(off) < 10) resp += '0'
      resp += Math.floor(Math.abs(off));
      if ((Math.abs(off) - Math.floor(Math.abs(off))) > 0) resp += (Math.abs(off) - Math.floor(Math.abs(off))) * 60
      else resp += '00'
      return resp
    }

    function onDestroy() {
      window.fbAsyncInit = null;
      $scope.$root.abortRequests(_controller);
    }
  }

})();
