'use strict';
(function () {
  angular.module('pentaApp').factory('event.resource', service);
  service.$inject = ['$resource'];
  var url = '/progressiveApp/api/event';
  function service($resource) {
    var result = $resource(url, null, { detail: { cache: true } })
    result.getFileUrl = function (object, filename) {
      if (!filename) return alert('getFileUrl needs filename argument');
      if (!object || typeof object === 'object' && !object._id) return '//:0';
      var queryString = url + '?_file=' + filename + '&enterprise=' + enterprise;
      var _id = (object._id || object);
      queryString += '&_id=' + _id;
      return queryString;
    };
    return result;
  }
})();
