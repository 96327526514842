(function () {
  angular.module('pentaApp').directive('dragToScroll', function () {
    return function (scope, element, attrs) {
      var pos = { top: 0, left: 0, x: 0, y: 0 }
      var ele = element[0]
      var currentPosition;

      $(ele).attr('title', 'Click para aumentar')
      
      element.bind('mousedown', function (event) {

        pos = { // Registro posición inicial del mouse
          top: ele.scrollTop,
          left: ele.scrollLeft,
          
          x: event.clientX,
          y: event.clientY
        }

        
        element.bind('mousemove', function (event) {
          var dx = event.clientX - pos.x; // Registro el movimiento del mouse
          var dy = event.clientY - pos.y;
          
          ele.scrollTop = pos.top - (dy - 15)
          ele.scrollLeft = pos.left - (dx - 15)
          currentPosition = {x: event.clientX, y: event.clientY} // Aplico movimiento a posición del elemento.
        });

        element.bind('mouseleave', function () {
          element.unbind('mousemove') // Si me paso de los márgenes del elemento interrumpo el drag.
        })
        
      });
      
      element.bind('mouseup', function (event) {
        $(ele).removeClass('grabbing')
        element.unbind('mousemove mouseleave')
      })

      scope.$on('$destroy', function () {
        element.unbind('mousedown mouseup mousemove mouseleave')
      })
    };
  })
})()
