(function (exports) {
  if (exports) {//esto permite importar este factory en backend
    exports.factory = factory();
    return
  }
  angular.module('pentaApp').factory("identification.factory", factory);
  factory.$inject = [];
  function factory() {
    return {
      validate: validate,
      validateDNI: validateDNI,
      validateCUIT: validateCUIT,
      validateCUIL: validateCUIL,
      validateRUT: validateRUT
    }

    function validate(identificationType, identificationNumber) {
      if (!identificationType || !identificationNumber) return console.error('No se otorgó el tipo o el número de la identificación que se intenta validar')
      let message, passed
      switch (identificationType) {
        case 'DNI':
          if (!validateDNI(identificationNumber)) {
            message = i18next.t('DNI invalido, Ej. 99999999 o 99.999.999')
            passed = false
            break;
          }
          passed = true
          break;
        case 'CUIT':
          if (!validateCUIT(identificationNumber)) {
            message = i18next.t('CUIT invalido, Ej. 99-99999999-9')
            passed = false
            break;
          }
          passed = true
          break;
        case 'CUIL':
          if (!validateCUIL(identificationNumber)) {
            message = i18next.t('CUIL invalido, Ej. 99-99999999-9')
            passed = false
            break;
          }
          passed = true
          break;
        case 'RUT':
          if (!validateRUT(identificationNumber)) {
            message = i18next.t('El RUT ingresado no es válido')
            passed = false
            break;
          }
          passed = true
          break;
      }
      return {
        message: message,
        passed: passed
      }
    }

    function validateDNI(identificationNumber) {
      if (!identificationNumber) return
      if (!/^(\d{8})$|^(\d{2}\.{1}\d{3}\.\d{3})$/.exec(identificationNumber)) return
      return true
    }

    function validateCUIT(identificationNumber) {
      if (!identificationNumber) return
      if (!/^(\b(30|33|34)\-{1}[0-9]{8}\-{1}[0-9]{1})$/.exec(identificationNumber)) return
      return true
    }

    function validateCUIL(identificationNumber) {
      if (!identificationNumber) return
      if (!/^(\b(20|23|24|27)\-{1}[0-9]{8}\-{1}[0-9]{1})$/.exec(identificationNumber)) return
      return true
    }

    function validateRUT(identificationNumber) {
      if (!identificationNumber) return
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.exec(identificationNumber)) return
      const rutSplit = identificationNumber.split('-');
      let number = rutSplit[0];
      let checkDigit = rutSplit[1];
      if (checkDigit === 'K') checkDigit = 'k';
      let M = 0, S = 1;
      for (; number; number = Math.floor(number / 10)) {
        S = (S + number % 10 * (9 - M++ % 6)) % 11;
      }
      var result = S ? (S - 1) : 'k';
      if (result.toString() !== checkDigit) return
      return true
    }
  }
})(typeof exports !== 'undefined' && exports)
