(function () {
  var _controller = 'login.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('login', {
      templateUrl: 'components/login/login.component.jade',
      controller: _controller,
      bindings: {
        // '=': Two Way Binding, lo que cambia acá, cambia en el controlador padre automáticamente
        // '<': One Way Binding (RECOMENDADO), para recibir variables, funciones, etc
        // '@': String, para recibir strings, puede ser una bandera por ejemplo
        // '&': Dinámico, se ejecuta lo que está adentro, hay que pasarle un objeto con variables 
        //      ej: componente(call-back='algo(var)')  y en el controller callBack({var: 'esto'})
        onLogin: '&'
      }
    })

  controller.$inject = ['$scope', '$element', '$timeout', '$http', 'hq.socket', 'clientUtils.factory'];
  function controller($scope, $element, $timeout, $http, hqSocket, clientUtilsFactory) {
    //// METHODS
    $scope.insightLogin = insightLogin;
    $scope.fbLogin = fbLogin;
    $scope.testEmail = testEmail;
    $scope.profile = profile;
    $scope.getCaptcha = getCaptcha;
    var onLogin = this.onLogin;
    var captchaId;

    //// VARS
    $scope.noPass = profile && profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.noPass;
    $scope.allowNoEmail = profile && profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.allowNoEmail;
    $scope.user = {};
    $scope.mode = 'TEST';
    $scope.acceptEmail = true;
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    $scope.isInstagram = (ua && ua.indexOf('Instagram') > -1) ? true : false;
    let $ctrl = this;
    let timeout;

    //// WATCHERS

    /// One Way Binding Update (cuando se actualiza tu binding con '<')
    this.$onChanges = function () { }
    $scope.$watch('user.identificationNumber', (newVal, oldVal) => {
      if (!newVal || newVal === oldVal) return
      if (timeout) $timeout.cancel(timeout)
      timeout = $timeout(function () {
        checkIdDupe(newVal)
      }, 1000);
    })

    //// INIT
    this.$onInit = function () {
      activateFacebookLogin();
      activateGoogleLogin();
    };

    /// DESTROY
    this.$onDestroy = function () {
      window.fbAsyncInit = null;
      $timeout.cancel(timeout);
    };

    ///////////////////////////////////
    function activateFacebookLogin(callback) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '357211158034257',
          cookie: true, // enable cookies to allow the server to access
          version: 'v12.0' // use graph api version 2.8
        });
        window.FB.AppEvents.logPageView();
      };
      if (!window.FB) {
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }
    }
    function fbLogin() {
      if (!window.FB) return console.log('FB not initialized');
      FB.login(function (response) {
        if (response.status === 'connected') {
          /// SI HACE LOGIN BIEN
          insightLogin({ facebook_id_token: response.authResponse.accessToken })
        }
        else {
          /// SI DA ERROR
          console.log('ERR', response);
        }
      }, { scope: 'email,user_birthday,user_gender,user_hometown', auth_type: 'rerequest' });
    }
    ////// FIN FACEBOOK-LOGIN 

    ///// GOOGLE LOGIN
    function activateGoogleLogin(callback) {
      window.gapiBootstrap = function () {
        if (typeof gapi === 'undefined') {
          return console.log("--- NO GAPI");
        }
        gapi.load('auth2', function () {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          var auth2 = gapi.auth2.init({
            client_id: '115178218059-9ab19ofjfht9o9lletfj9p1fuin6nvtt.apps.googleusercontent.com',
            cookie_policy: 'single_host_origin',
            scope: 'email profile openid'
          })
          auth2.then(function () {
            auth2.attachClickHandler(document.getElementById('google-login'), {},
              function (googleUser) {
                /// SI HACE LOGIN BIEN
                insightLogin({ google_id_token: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token })
              }, function (error) {
                /// SI DA ERROR
                return console.log(err);
              });
          });
        });
      }

      if (!window.gapi) {
        var script = document.createElement('script')
        script.src = "https://apis.google.com/js/platform.js?onload=gapiBootstrap"
        document.head.appendChild(script);
      }
      else window.gapiBootstrap();
    }
    ///// FIN GOOGLE


    function insightLogin(data) {
      if (!data) data = { _controller: _controller };
      else data._controller = _controller;
      $scope.wrongPassword = false;
      $scope.invalidCaptcha = false;
      data.enterprise = enterprise;
      data.lastDevice = {};
      populateLastDevice(data.lastDevice);
      data.frontendUtcOffset = formattedUtcOffset();
      if ($scope.allowNoEmail && $scope.user.email) {
        $scope.user.email = $scope.user.email.replace(/ /g, ''); // elimino todos los espacios
        if ($scope.user.email.indexOf('@') < 0)
          $scope.user.email = $scope.user.email + '@noemail.com'; // convierto en un email
      }
      data.email = $scope.user.email;
      if ($scope.mode === 'REGISTER') {
        if ($scope.user.bYear && $scope.user.bMonth && $scope.user.bDay) data.birthday = $scope.user.bYear + '-' + $scope.user.bMonth + '-' + $scope.user.bDay;
        data.name = $scope.user.name;
        data.identificationType = $scope.user.identificationType || null;
        data.identificationNumber = $scope.user.identificationNumber || null;
        data.telephoneNumber = $scope.user.telephoneNumber || null;
        data.captchaId = captchaId;
        data.captchaText = $scope.captchaText;
      }
      if ($scope.mode === 'REGISTER' || $scope.mode === 'ACTIVATION') {
        data.changePassword = true;
      }
      data.password = $scope.user.password || '';
      if ($scope.mode === 'REGISTER' && !$scope.invalidCaptcha) {
        $scope.loading = true;
        grecaptcha.ready(function () {
          grecaptcha.execute('6Le-lOQZAAAAAElKJMtT8Zn08hUtPEsP0N6B0cXM', { action: 'signup' }).then(function (token) {
            $scope.loading = false;
            data.reCaptchaToken = token;
            post(data)
          });
        });
      }
      else post(data)

      function post(data) {
        $scope.loading = true;
        if (!$scope.$$phase && !$scope.$root.$$phase) $scope.$apply();
        $http.post('/clientApi/client', data, { params: { enterprise: window.enterprise } })
          .then(function (response) {
            $scope.loading = false;
            if (!response || !response.data) return console.log('NO INSIGHT DATA LOGIN');
            if (response.data.wrongPassword) $scope.wrongPassword = true;
            else if (response.data.invalidCaptcha) {
              $scope.invalidCaptcha = true;
              getCaptcha();
            }
            else if (!response.data.aat) {
              //if ($scope.allowAnonymous) appNavigator.popPage({ callback: onClose.bind(null, { email: $scope.user.email }), times: times })
              try { hqSocket.connect(); } catch (ex) { }
              $scope.mode = 'LOGIN';
              $scope.emailSent = true;
            }
            else {
              loadProfile(function () {
                try { hqSocket.connect(); } catch (ex) { }
                if ($scope.$root.areMissingFields())
                  appNavigator.pushPage('userConf/userConf.jade', { data: { onClose: function () { appNavigator.popPage() } } });
                $ctrl.onLogin()
                ons.notification.toast(i18next.t('Sesión iniciada'), { timeout: 2000 });
                if (!$scope.$root.$$phase) $scope.$root.$apply()
              })
            }
          }, function (error) {
            $scope.loading = false;
            throw error
          })
      }
    }

    function testEmail() {
      $http.get('/clientApi/client', { params: { email: $scope.user.email, enterprise: enterprise, _controller: _controller } })
        .then(function (response) {
          if (!response || !response.data) throw new PentaError('NO INSIGHT DATA LOGIN');
          /// Chequeo registro deshabilitado
          if ((response.data.requireLogin || response.data.newForThisEnterprise) && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.disableRegister)
            return disableRegisterAlert()
          // Si tiene que hacer login directo, registro o activación
          // Es usuario nuevo en el sistema
          if (response.data.requireLogin) {
            $scope.mode = 'REGISTER'
          }
          //else if ($scope.allowAnonymous) appNavigator.popPage({ callback: onClose.bind(null, { email: $scope.user.email }), times: times })
          // Ya tiene usuario y tiene password vacío, hace login directo
          else if (response.data.emptyPassword) insightLogin();
          // Ya tiene usuario y ya tiene password
          else if (response.data.hasPassword) {
            $scope.mode = 'LOGIN';
            $scope.noPass = false;
          }
          // Ya tiene usuario pero no en esta empresa
          else $scope.mode = 'ACTIVATION'
        }, function (response) {
          console.log('ERRRRRRRRR: ', response)
        })
    }

    function disableRegisterAlert() {
      ons.notification.alert({ message: i18next.t('Este correo no se encuentra registrado, deberá solicitar que se lo registren para poder ingresar'), title: i18next.t('Aviso') })
    }

    function getCaptcha() {
      $timeout(function () {
        $scope.captchaImg = false;
        document.getElementById('captchaImg').src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        $http.post('/api/captcha', { _controller: _controller }).then(function (result) {
          captchaId = result.data.captcha;
          document.getElementById('captchaImg').src = '/api/captcha?_id=' + captchaId + '&ts=' + Date.now();
          $scope.captchaImg = true;
        }).catch(function (err) {
          $scope.captchaImg = false;
          handleError('CAPTCHA-ERROR', err)
        })
      }, 100)
    }

    function populateLastDevice(lastDevice) {
      if (navigator && navigator.userAgent) lastDevice.userAgent = navigator.userAgent;
      if (!window.jscd) return;
      if (window.jscd && window.jscd.os && window.jscd.os !== '-') lastDevice.OS = window.jscd.os;
      if (window.jscd && window.jscd.osVersion && window.jscd.osVersion !== '-') lastDevice.OSVersion = window.jscd.osVersion;
      if (window.jscd && window.jscd.browser && window.jscd.browser !== '-') lastDevice.browser = window.jscd.browser;
      if (window.jscd && window.jscd.browserMajorVersion && window.jscd.browserMajorVersion !== '-') lastDevice.browserVersion = window.jscd.browserMajorVersion;
      if (window.jscd && window.jscd.screen && window.jscd.screen !== '-') lastDevice.screenSize = window.jscd.screen;
    }


    function formattedUtcOffset() {
      var off = -(new Date()).getTimezoneOffset() / 60;
      var resp = '';
      if (off < 0) resp += '-';
      else if (off >= 0) resp += '+';
      if (Math.abs(off) < 10) resp += '0'
      resp += Math.floor(Math.abs(off));
      if ((Math.abs(off) - Math.floor(Math.abs(off))) > 0) resp += (Math.abs(off) - Math.floor(Math.abs(off))) * 60
      else resp += '00'
      return resp
    }

    async function checkIdDupe(identificationNumber) {
      try {
        $scope.duplicateMessage = undefined;
        if (!profile.enterprise.pwaConfig.idDupeAlert) return
        if (!identificationNumber) return
        let idDuped = await clientUtilsFactory.checkIdDupe(identificationNumber)
        if (!idDuped || !idDuped.length) return
        $scope.duplicateMessage = clientUtilsFactory.replaceIdDupeMessage(window.profile.enterprise.pwaConfig.idDupeAlertMessage, idDuped[0].email)
        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    // FIN CONTROLLER
  }
})();
