'use strict';

(function () {
  angular.module('pentaApp').factory('hq.socket', factory);

  factory.$inject = ['socketFactory']
  function factory(socketFactory) {
    if (typeof io === 'undefined') return;
    window.ioSocket = io("/pwa?enterprise=" + enterprise, { autoConnect: true })
    var factory = socketFactory({ ioSocket: ioSocket })
    factory.connect = connect;
    factory.disconnect = disconnect;
    return factory;
  }

  function disconnect() {
    window.ioSocket.close()
  }

  function connect() {
    disconnect();
    window.ioSocket.connect();
  }
})();