'use strict';
(function () {
  var _controller = 'loginRequired.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$rootScope'];
  function controller($scope, $rootScope) {
    /// METHODS
    $scope._login = _login;

    /// VARS

    // WATCHERS

    /// INIT


    /////////////////////

    function _login(){
      $rootScope.login(appNavigator.topPage.data);
    }

    //// FIN CONTROLLER
  }
})();
