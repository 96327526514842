'use strict';
(function () {
  var _controller = 'home.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$q', '$filter', 'flyer.resource', 'hq.socket', 'image.resource', 'flyer.factory'];
  function controller($scope, $q, $filter, flyerResource, hqSocket, imageResource, flyerFactory) {
    // METHODS


    // VARS
    $scope.allFlyers = [];
    $scope.categories = ['General'];
    $scope.flyerResource = flyerResource;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$on('defaultRouter', initCarousel);
    $scope.$on('login', initCarousel)

    // INIT
    initCarousel();

    /////////////////////
    function initCarousel() {
      flyerFactory.getFlyers('APP-HOME').then(function (flyers) {
        $scope.allFlyers = flyers;
      }).catch(function (err) { if (err) throw new PentaError(err) })
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
  }

})();
