'use strict';
(function () {
  var _controller = 'ntnClubCardView.component.controller';
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('ntnClubCardView', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        clubCard: '=', //- Objeto de clubCard.
        card: '=', //- Objeto de clubCard viejo
        arrProducts: '<', //- Array de productos, se lo envia el padre para evitar multiples peticiones al servidor.
      },
    });

  controller.$inject = ['$scope', '$element', '$injector'];
  function controller($scope, $element, $injector) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/ntnClubCardView/ntnClubCardView.component.html' : 'components/club/ntnClubCardView/ntnClubCardView.component.jade';
    let giveCardResource = $scope.$root.employeeApp ? undefined : $injector.get('giveCard.resource')
    let clubCardTemplateForClientResource = $scope.$root.employeeApp ? undefined : $injector.get('clubCardTemplateForClient.resource')
    let qrGenerateModal = $scope.$root.employeeApp ? $injector.get('qrGenerate.modal') : $injector.get('qrGenerate.modal')
    let ntnShoppingViewModal = $scope.$root.employeeApp ? undefined : $injector.get('ntnShoppingView.modal')

    let that = this;
    // METHODS
    that.expandCard = expandCard;
    that.isExpanded = isExpanded;
    that.redeem = redeem;
    that.gift = gift;
    that.generateCardAndRedeem = generateCardAndRedeem;
    that.goToStore = goToStore;

    // VARS
    let currentRouter;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$on('defaultRouter', refresh);

    // INIT

    /// On component init
    this.$onInit = function () {
      if (!this.card && !this.clubCard) return
      if (this.clubCard && (!this.clubCard.benefits || !this.clubCard.benefits.length)) return;
      if (!this.arrProducts) this.arrProducts = [];
      refresh();
    };

    /////////////////////
    function refresh() {
      currentRouter = $scope.$root.routers.find(f => { return f._id === $scope.$root.defaultRouter });
      if (that.clubCard && that.clubCard.benefits && that.clubCard.benefits.length)
        for (let benefit of that.clubCard.benefits) {
          benefit._conditions = clubFunctions.getTermsAndConditions(benefit.match, $scope.$root.routers);
          benefit._remainingUses = getRemainingUses(benefit.remainingUses);
          if (benefit.content && benefit.content.products && benefit.content.products.length) benefit._products = getProductsName(benefit.content.products, that.arrProducts);
        }
      if (that.card) {
        if (that.card.cardTemplate && that.card.cardTemplate.content && that.card.cardTemplate.content.lenght) {
          let content = that.card.cardTemplate.content[0];
          that.card._conditions = clubFunctions.getTermsAndConditions(content.validity, $scope.$root.routers);
          that.card._remainingUses = getRemainingUses(content.useLimit);
        }
      }
    }

    function getRemainingUses(remainingUses) {
      if (!remainingUses && remainingUses !== 0) return;
      var uses = '';
      if (remainingUses === 1) uses += remainingUses + ' ' + i18next.t('disponible');
      else uses += remainingUses + ' ' + i18next.t('disponibles');
      return uses;
    }

    function getProductsName(products, allProducts) {
      if (!products || !products.length || !allProducts || !allProducts.length) return undefined;
      var text = '';
      products.forEach(function (product, index) {
        var masterProduct = allProducts.find(function (f) {
          return f.masterProduct === product;
        });
        if (!masterProduct || !masterProduct.properties || !masterProduct.properties.name) return;
        if (index === 0) text += ' ' + masterProduct.properties.name;
        else if (index + 1 === products.length) text += '  ' + i18next.t('y') + ' ' + masterProduct.properties.name;
        else text += ', ' + masterProduct.properties.name;
      });
      return text;
    }

    function isExpanded(item) {
      if (!item || !item._id) return false;
      if (item._showSeeMoreButton) return true;
      var clubCardElement = $element[0].querySelector("[data-benefit='" + item._id + "']");
      if (!clubCardElement) return;
      item._showSeeMoreButton = showExpandButton(clubCardElement);
      if (item._showSeeMoreButton) setExpandSettings(item);
      return item._showSeeMoreButton;
    }

    function showExpandButton(clubCardElement) {
      var collapseElements = Array.from(clubCardElement.querySelectorAll('.collapse-text'));
      var show = false;
      for (var i = 0; i < collapseElements.length; i++) {
        var scrollWidth = Math.round($(collapseElements[i])[0].scrollWidth);
        var innerWidth = Math.round($(collapseElements[i]).innerWidth());
        if (scrollWidth > innerWidth) {
          show = true;
          break;
        }
      }
      return show;
    }

    function setExpandSettings(benefit) {
      if (!benefit || benefit._expandSettings) return;
      benefit._expandSettings = {
        text: i18next.t('VER MÁS'),
        expanded: false,
      };
    }

    function expandCard(benefit, $event) {
      if (!benefit || !benefit._showSeeMoreButton || !benefit._expandSettings || !$event || !$event.currentTarget) return;
      var elements = Array.from($event.currentTarget.querySelectorAll('.collapse-text'));
      elements.forEach(function (element) {
        if ($(element).hasClass('expand-text')) $(element).removeClass('expand-text');
        else $(element).addClass('expand-text');
      });
      elements.every(function (element) {
        if ($(element).hasClass('expand-text')) benefit._expandSettings = { text: i18next.t('VER MENOS'), expanded: true };
        else benefit._expandSettings = { text: i18next.t('VER MÁS'), expanded: false };
      });
    }

    //- ESTA FUNCTION GENERA Y CANJEA LA CLUB CARD.
    function generateCardAndRedeem(benefit, clubCard) {
      if (!benefit || !clubCard || !clubCard._id) return;
      clubCardTemplateForClientResource
        .createCards({ _id: clubCard._id })
        .$promise.then(function (clubCards) {
          if (!clubCards || !clubCards.length || !clubCards[0].benefits || !clubCards[0].benefits.length) return;
          //TODO POR EL MOMENTO SOLO SE TENDRA EN CUENTA LA PRIMERA POSICION DEL ARRAY DE CLUBCARDS
          redeem(benefit, clubCards[0]);
        })
        .catch(function (err) {
          if (err) throw new PentaError(err);
        });
    }

    //- ESTA FUNCTION CANJEA LA CLUB CARD.
    function redeem(benefit, guid, card) {
      if (!benefit && !card) return;
      let name = benefit ? benefit.name : card ? card.cardTemplate ? card.cardTemplate.name : undefined : undefined;
      let match;
      if (benefit) match = benefit ? benefit.match : undefined;
      else match = card.cardTemplate && card.cardTemplate.content && card.cardTemplate.content[0] && card.cardTemplate.content[0].validity || undefined;
      let conditions = benefit ? benefit._conditions : card ? card._conditions : undefined;
      var values = {
        date: moment(),
        utcOffset: $scope.$root.routers.find((f) => {
          return f._id === $scope.$root.defaultRouter;
        }).utcOffset,
        defaultRouter: $scope.$root.defaultRouter,
        products: that.arrProducts.map((f) => f.masterProduct || f._id),
      };
      if (!window.clubFunctions.doesMatch(match, values))
        return ons.notification.alert({
          title: i18next.t('Beneficio no vigente'),
          message: conditions && conditions.length > 1 ? conditions : '',
        });
      let message = $scope.$root.employeeApp ? i18next.t('Muestra el código para canjear tu voucher') : i18next.t('Muestra este código al vendedor para canjear tu voucher')
      qrGenerateModal.open({
        title: name || i18next.t('Voucher'),
        message: message + '.',
        guid: guid,
      });
    }

    function gift(card) {
      ons.notification.prompt({
        title: i18next.t('Regalar cupón'),
        message: i18next.t('Ingresa el correo electrónico a quien quieres regalarle tu cupón'),
        inputType: 'text',
        placeholder: i18next.t('Email destino'),
        buttonLabels: ['Cancelar', 'Aceptar'],
        cancelable: true
      }).then(function (email) {
        if (!email) return;
        ons.notification.prompt({
          title: i18next.t('Repetir email'),
          message: i18next.t('Te pedimos que vuelvas a escribir el email, ya que si regalas a un correo equivocado no podras revertir la operación'),
          inputType: 'text',
          cancelable: true,
          placeholder: i18next.t('Repetir email'),
          buttonLabels: ['Cancelar', 'Estoy seguro']
        }).then(function (repeatEmail) {
          if (!repeatEmail) return;
          if (repeatEmail !== email) return ons.notification.alert({
            title: i18next.t('Los emails no coinciden'),
            message: i18next.t('Por favor intente nuevamente. Preste especial atención ya que no podrá revertir el proceso si introduce un correo equivocado.'),
          }).then(function () { })
          var subject;
          if (window.profile.name && window.profile.enterprise.name) subject = '🎁 ' + window.profile.name + ' ' + i18next.t('te envió un regalo de') + ' ' + window.profile.enterprise.name + '!!';
          else subject = i18next.t('Te han enviado un regalo')
          if (!giveCardResource) return console.error('Falta recurso gift');
          giveCardResource.save({
            dstEmail: email,
            card: card._id,
            subject: subject,
            _controller: _controller,
            origin: window.location.origin
          }, function () {
            ons.notification.alert({
              title: i18next.t('¡Listo!'),
              message: i18next.t('El regalo fue enviado a') + ' ' + email + '\n' + i18next.t('Podrá accederlo desde el email o desde la app registrandose con el mismo correo'),
            }).then(function () {
              refresh();
            })
          })
        })
      })
    }

    //- ESTA FUNCTION ABRE LA TIENDA PARA CAJEAR LA CLUB CARD.
    function goToStore(benefit, clubCard) {
      if (!benefit || !clubCard) return;
      if (clubCard.shareWithAllies === 'NONE') return openStore();
      else if (clubCard.shareWithAllies === 'ALL' && clubCard.allies && clubCard.allies.length) return openShopping(clubCard.allies);
      else if (clubCard.shareWithAllies === 'LIST' && clubCard.allies && clubCard.allies.length) {
        if (clubCard.allies.length === 1) return openStore(clubCard.allies[0]._id);
        else return openShopping(clubCard.allies);
      } else return;
    }

    function openStore(enterprise) {
      if (enterprise) {
        var url = window.location.origin + '/progressiveApp/' + enterprise + '/index.jade' + '?store=true';
        return (window.location.href = url);
      }
      $scope.$root.openSection('store/storeProductSelection/storeProductSelection.jade');
    }

    function openShopping(enterprises) {
      if (!enterprises || !enterprises.length) return;
      ntnShoppingViewModal.open({
        allianceEnterprises: enterprises,
      });
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();
