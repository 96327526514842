'use strict';
(function () {
  var _controller = 'ntnListVertical.component.controller';
  angular
    .module('pentaApp')
    .controller(_controller, controller)
    .component('ntnListVertical', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        title: '<?', //- Titulo
        list: '=', //- Array de objetos en lista [{ label: STRING, value: STRING, ts: Date, valueStyle: STRING }]
      },
    });

  controller.$inject = ['$scope', '$element'];
  function controller($scope, $element) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/ntnListVertical/ntnListVertical.component.html' : 'components/ntnListVertical/ntnListVertical.component.jade';
    var that = this;
    //// METHODS
    that.execFunction = execFunction;

    //// VARS

    //// WATCHERS
    $scope.$on('$destroy', onDestroy);

    //// INIT
    this.$onInit = function () {
      if (!this.list) return;
      init();
    };

    ////////////
    function init() {
      if (!that.list) return;
    }

    function execFunction(item) {
      if (!item || !item.button || !item.button.function || typeof item.button.function !== 'function') return;
      item.button.function(item);
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    // FIN CONTROLLER
  }
})();
