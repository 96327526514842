'use strict';
(function () {
  angular.module('pentaApp').factory('image.resource', service);

  function service() {
    var result = {};
    var apiUrl = '/api/image'
    result.getFileUrl = function (object, filename) {
      if (!filename) return alert('getFileUrl needs filename argument');
      if (!object || typeof object === 'object' && !object._id) return '//:0';
      var queryString = apiUrl + '?_file=' + filename;
      var _id = (object._id || object);
      queryString += '&_id=' + _id;
      return queryString;
    };
    return result
  }

})();
