'use strict';
(function () {
  var _controller = 'chatCallButtons.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('chatCallButtons', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        inCall: '=?',
        callStatus: '=?',
        connecting: '=?',
        callType: '=?',
        microphone: '=?',
        videocall: '=?',
        callFunctions: '<'
      }
    })

  controller.$inject = ['$scope', '$element'];
  function controller($scope, $element) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/chat/messages/chatMessage.component.html' : 'components/chat/callButtons/chatCallButtons.component.jade';
    // METHODS
    $scope.microphoneStatus = microphoneStatus;
    $scope.videoStatus = videoStatus;

    // VARS
    var that = this;
    var localVideo = document.getElementById('localVideo'); //- Camara Propia
    var localAudio = document.getElementById('localAudio'); //- Audio Propio

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT

    /// On component init
    this.$onInit = function () {

    };

    /////////////////////

    function microphoneStatus() {
      if (that.callFunctions && that.callFunctions.microphoneStatus && (typeof that.callFunctions.microphoneStatus === 'function'))
        that.callFunctions.microphoneStatus();
    }

    function videoStatus() {
      if (that.callFunctions && that.callFunctions.videoStatus && (typeof that.callFunctions.videoStatus === 'function'))
        that.callFunctions.videoStatus();
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }

})();
