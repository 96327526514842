'use strict';
(function () {
  var _controller = 'emailSignup.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$http', '$timeout'];
  function controller($scope, $http, $timeout) {
    /// METHODS
    $scope.testEmail = testEmail;
    $scope.insightLogin = insightLogin;
    $scope.getCaptcha = getCaptcha;

    /// VARS
    $scope.credentials = {
      email: null,
      password: null
    };
    var onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    var times = appNavigator.topPage.data && appNavigator.topPage.data.times || 1;
    $scope.allowAnonymous = appNavigator.topPage.data && appNavigator.topPage.data.allowAnonymous;
    $scope.noPass = profile && profile.enterprise && profile.enterprise.pwaConfig && profile.enterprise.pwaConfig.noPass;

    $scope.mode = 'TEST';
    var captchaId;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    /// INIT
    init();


    /////////////////////

    function init() {
    }


    function insightLogin() {
      $scope.wrongPassword = false;
      $scope.invalidCaptcha = false;
      var data = { _controller: _controller };
      data.enterprise = enterprise;
      data.lastDevice = {};
      populateLastDevice(data.lastDevice);
      data.frontendUtcOffset = formattedUtcOffset();
      data.email = $scope.user.email;
      if ($scope.mode === 'REGISTER') {
        if ($scope.user.bYear && $scope.user.bMonth && $scope.user.bDay) data.birthday = $scope.user.bYear + '-' + $scope.user.bMonth + '-' + $scope.user.bDay;
        data.name = $scope.user.name;
        data.identificationType = $scope.user.identificationType || null;
        data.identificationNumber = $scope.user.identificationNumber || null;
        data.telephoneNumber = $scope.user.telephoneNumber || null;
        data.captchaId = captchaId;
        data.captchaText = $scope.captchaText;
      }
      if ($scope.mode === 'REGISTER' || $scope.mode === 'ACTIVATION') {
        data.changePassword = true;
      }
      data.password = $scope.user.password || '';
      if($scope.mode === 'REGISTER' && !$scope.invalidCaptcha){
        grecaptcha.ready(function() {
          grecaptcha.execute('6Le-lOQZAAAAAElKJMtT8Zn08hUtPEsP0N6B0cXM', {action: 'signup'}).then(function(token) {
            data.reCaptchaToken = token;
            post(data)
          });
        });

      }
      else post(data)


      function post(data){
        $http.post('/clientApi/client', data, { params: { enterprise: window.enterprise } })
          .then(function (response) {
            if (!response || !response.data) return console.log('NO INSIGHT DATA LOGIN');
            if (response.data.wrongPassword) $scope.wrongPassword = true;
            else if (response.data.invalidCaptcha) {
              $scope.invalidCaptcha = true;
              getCaptcha();
            }
            else if (!response.data.aat) {
              if ($scope.allowAnonymous) appNavigator.popPage({ callback: onClose.bind(null, { email: $scope.user.email }), times: times })
              $scope.mode = 'LOGIN';
              $scope.emailSent = true;
            }
            else {
              loadProfile(function () {
                if (onClose) appNavigator.popPage({ callback: onClose.bind(null, data), times: times });
                else appNavigator.popPage({ times: times });
              })
            }
          }, function (response) {
            console.log('ERR 2: ', response);
          })
      }
    }

    function testEmail() {
      $http.get('/clientApi/client', { params: { email: $scope.user.email, enterprise: enterprise, _controller: _controller } })
        .then(function (response) {
          if (!response || !response.data) throw new PentaError('NO INSIGHT DATA LOGIN');
          else if (response.data.requireLogin) { $scope.mode = 'REGISTER';}
          else if ($scope.allowAnonymous) appNavigator.popPage({ callback: onClose.bind(null, { email: $scope.user.email }), times: times })
          else if (response.data.emptyPassword) insightLogin();
          else if (response.data.hasPassword) {
            $scope.mode = 'LOGIN';
            $scope.noPass = false;
          }
          else $scope.mode = 'ACTIVATION'
        }, function (response) {
          console.log('ERRRRRRRRR: ', response)
        })
    }

    function populateLastDevice(lastDevice) {
      if (navigator && navigator.userAgent) lastDevice.userAgent = navigator.userAgent;
      if (!window.jscd) return;
      if (window.jscd && window.jscd.os && window.jscd.os !== '-') lastDevice.OS = window.jscd.os;
      if (window.jscd && window.jscd.osVersion && window.jscd.osVersion !== '-') lastDevice.OSVersion = window.jscd.osVersion;
      if (window.jscd && window.jscd.browser && window.jscd.browser !== '-') lastDevice.browser = window.jscd.browser;
      if (window.jscd && window.jscd.browserMajorVersion && window.jscd.browserMajorVersion !== '-') lastDevice.browserVersion = window.jscd.browserMajorVersion;
      if (window.jscd && window.jscd.screen && window.jscd.screen !== '-') lastDevice.screenSize = window.jscd.screen;
    }

    function getCaptcha() {
      $timeout(function () {
        $scope.captchaImg = false;
        document.getElementById('captchaImg').src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        $http.post('/api/captcha', { _controller: _controller }).then(function (result) {
          captchaId = result.data.captcha;
          document.getElementById('captchaImg').src = '/api/captcha?_id=' + captchaId + '&ts=' + Date.now();
          $scope.captchaImg = true;
        }).catch(function (err) {
          $scope.captchaImg = false;
          handleError('CAPTCHA-ERROR', err)
        })
      }, 100)
    }
    function formattedUtcOffset() {
      var off = -(new Date()).getTimezoneOffset() / 60;
      var resp = '';
      if (off < 0) resp += '-';
      else if (off >= 0) resp += '+';
      if (Math.abs(off) < 10) resp += '0'
      resp += Math.floor(Math.abs(off));
      if ((Math.abs(off) - Math.floor(Math.abs(off))) > 0) resp += (Math.abs(off) - Math.floor(Math.abs(off))) * 60
      else resp += '00'
      return resp
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    //// FIN CONTROLLER
  }
})();
