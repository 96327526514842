'use strict';
(function () {
  var _controller = 'qrCode.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'qrCode.resource', '$timeout'];
  function controller($scope, qrCode, $timeout) {
    /// METHODS
    $scope.ok = ok;

    /// VARS
    $scope.data = appNavigator.topPage.data;
    var times = appNavigator.topPage.data.times;

    // WATCHERS
    $scope.$on('$destroy', onDestroy)

    /// INIT
    init();


    /////////////////////

    var inEffectToTimeout;
    function init() {
      if ($scope.data.inEffectTo) inEffectToTimeout = $timeout(ok, $scope.data.inEffectTo - new Date());
      $scope.data.qrContent.client = $scope.$root.profile._id;
      $scope.data.qrContent._controller = _controller;
      qrCode.create($scope.data.qrContent, function (data) { $scope.qrCode = data.qrCode; })
    }

    function ok() {
      appNavigator.popPage({ times: times });
    }


    function onDestroy() {
      $timeout.cancel(inEffectToTimeout);
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CONTROLLER
  }
})();
