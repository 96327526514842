'use strict';
(function (serviceWorker) {
  if (!serviceWorker) return;

  window.showRefreshUI = showRefreshUI;
  function showRefreshUI(registration) {
    // TODO: Display a toast or refresh UI.

    // This demo creates and injects a button.
    document.body.style.cssText = 'display:block!important';
    document.getElementById('app-update').style.display = "block";

    var button = document.getElementById('update-button')
    button.addEventListener('click', function () {
      if (!registration.waiting) {
        // Just to ensure registration.waiting is available before
        // calling postMessage()
        return;
      }

      button.disabled = true;

      registration.waiting.postMessage('skipWaiting');
    });

  };

  function onNewServiceWorker(registration, callback) {
    if (registration.waiting) {
      // SW is waiting to activate. Can occur if multiple clients open and
      // one of the clients is refreshed.
      return callback();
    }

    function listenInstalledStateChange() {
      registration.installing.addEventListener('statechange', function (event) {
        if (event.target.state === 'installed') {
          // A new service worker is available, inform the user
          callback();
        }
      });
    };

    if (registration.installing) {
      return listenInstalledStateChange();
    }

    // We are currently controlled so a new SW may be found...
    // Add a listener in case a new SW is found,
    registration.addEventListener('updatefound', listenInstalledStateChange);
  }

  window.addEventListener('load', function () {
    serviceWorker.register('./sw.js')
      .then(function (registration) {
        // Track updates to the Service Worker.
        //
        // Si no hay controller, quiere decir que la página aún no está controlada por el Service Worker
        if (!navigator.serviceWorker.controller) {
          // Escucha el evento de controllerchange para recargar la página solo una vez
          return;
        }

        // When the user asks to refresh the UI, we'll need to reload the window
        var preventDevToolsReloadLoop;
        serviceWorker.addEventListener('controllerchange', function (event) {
          // Ensure refresh is only called once.
          // This works around a bug in "force update on reload".
          if (preventDevToolsReloadLoop) return;
          preventDevToolsReloadLoop = true;
          window.location.reload();
        });
        onNewServiceWorker(registration, function () {
          showRefreshUI(registration);
        });
      })
      .catch(function (error) {
        console.error('Error during service worker registration:', error);
      });
  });
})(navigator.serviceWorker);
