/**
 * Created by FERNANDO on 19/04/2016.
 */
'use strict';

(function () {
  angular.module('pentaApp').filter('absoluteTime', myFilter);
  myFilter.$inject = ['$filter'];
  function myFilter($filter) {
    return function (value, format) {
      return $filter('date')(moment({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).set({ milliseconds: value }).toDate(), format);
    }
  }
})();
