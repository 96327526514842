'use strict';
(function () {
  var _controller = 'additionalInformationValue.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('additionalInformationValue', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        currentAdditionalInformation: '<', // Objeto de información adicional.
        mainObject: '<', // objeto de Reserva / ticket / order . Verificar que tenga el array de "informacionAdicional" para validar datos.
        resourceName: '@',// String, nombre del recurso utilizado. 'reservation' / 'ticket' / 'order'.
        truncate: '<', // Boolean, limita el width del valor devuelto a 200px
        addInfoKey: '@', // String, campo de informacion adicional adentro de mainObject
        languageCode: '<'
      }
    })

  var privateKeys = []
  var privateStorageChecked = false;

  controller.$inject = ['$scope', '$element', '$filter', '$injector', 'privateFiles.resource'];
  function controller($scope, $element, $filter, $injector, privateFilesResource) {
    // METHODS
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/additionalInformationValue/additionalInformationValue.component.html' : 'components/additionalInformationValue/additionalInformationValue.component.jade';
    var genericModal = $scope.$root.employeeApp ? $injector.get('generic.modal') : null;
    var galleryPreview = $scope.$root.employeeApp ? $injector.get('galleryPreview.modal') : null;
    var pdfViewer = $scope.$root.employeeApp ? $injector.get('pdfViewer.modal') : null;

    $scope.getValue = getValue;
    $scope.getFiles = getFiles;
    $scope.canDownload = canDownload;
    $scope.getPrivateKey = getPrivateKey;
    $scope.rawValue = rawValue;
    $scope.viewFile = viewFile;

    // VARS
    var that = this;
    var download = document.createElement('a');
    $scope.mainObject = null;
    this.aiValue = null;
    var addInfoKey = 'additionalInformation'
    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // Este watcher permite reaccionar a los cambios de valor en el objeto de información adicional
    $scope.$watch('rawValue($ctrl.currentAdditionalInformation, $ctrl.mainObject)', function (newVal, oldVal) {
      if (!newVal === undefined || newVal === oldVal) return
      that.aiValue = getValue(that.currentAdditionalInformation, $scope.mainObject);
    })

    // INIT

    /// On component init
    this.$onInit = function () {
      getPersistedPrivateKeys();
      if (this.mainObject) $scope.mainObject = that.mainObject;
      if (this.addInfoKey) addInfoKey = this.addInfoKey;
      if (this.currentAdditionalInformation)
        this.aiValue = getValue(this.currentAdditionalInformation, $scope.mainObject);
    };

    /////////////////////
    function getPersistedPrivateKeys() {
      if (privateStorageChecked) return;
      privateStorageChecked = true;
      try {
        var persistedPrivateKeys = appStorage.get('privateKeys');
        if (persistedPrivateKeys) privateKeys = JSON.parse(persistedPrivateKeys);
      }
      catch (err) { console.log(err) }
    }

    // rawValue se usa simplemente para obtener el valor en crudo y poder monitorear su cambio con un $watch
    function rawValue(info, mainObject) {
      if (!info || !mainObject || !mainObject[addInfoKey]) return;
      var currentInfo = mainObject[addInfoKey].find(function (f) { return ((f.type === info.type) && (f._id === info._id)) });
      if (!currentInfo) return;
      return currentInfo.value;
    }

    function getValue(info, mainObject) {
      if (!info || !info.type || !info._id) return i18next.t('-');
      var value, currentInfo;
      if (info.value !== undefined) {
        value = $filter('language')(info, 'value', that.languageCode);
      } else {
        if (!mainObject || !mainObject[addInfoKey]) return i18next.t('-')
        currentInfo = mainObject[addInfoKey].find(function (f) { return ((f.type === info.type) && (f._id === info._id)) });
        if (currentInfo && currentInfo.value !== undefined) {
          value = $filter('language')(currentInfo, 'value', that.languageCode);
        }
      }
      if (info.publicKey) {
        var privateKey = privateKeys.find(function (f) { return f.publicKey === info.publicKey });
        if (privateKey) {
          var decrypt = new JSEncrypt();
          decrypt.setPrivateKey(privateKey.privateKey);
          value = decrypt.decrypt(value) || '';
          that.desencrypted = true;
        }
      }
      switch (info.type) {
        case 'BOOLEAN': return setBooleanFinalValue(value);
        case 'OPTIONS-MULTIPLE': return setOptionMultipleFinalValue(value);
        case 'DATE': return setDateFinalValue(value);
        default: return value || '-'
      }
    }

    function getPrivateKey(publicKey) {
      if (!publicKey) return;
      // Si ya está guardada, la reemplazo, esto no debería darse nunca
      var privateIndex = privateKeys.findIndex(function (f) { return f.publicKey === publicKey })
      if (privateIndex >= 0) privateKeys.splice(privateIndex, 1)
      genericModal.open({
        title: i18next.t('Ingrese la llave privada'),
        groups: [
          {
            label: '', fields: [
              { label: i18next.t('Una vez ingresada la llave, la misma quedará almacenada en el dispositivo'), type: 'WARNING', class: "col-xs-12" },
              { label: i18next.t('Llave privada'), type: 'TEXTAREA', class: "col-xs-12", field: 'privateKey', required: true },
            ]
          },
        ]
      })
        .then(function (data) {
          privateKeys.push({
            publicKey: publicKey,
            privateKey: data.privateKey
          })
          try { appStorage.set('privateKeys', JSON.stringify(privateKeys)) }
          catch (err) { console.log(err); }
          that.$onInit();
        }).catch(function () { })
    }

    function setBooleanFinalValue(value) {
      if (!value && value !== false) return '-';
      return value ? i18next.t('Verdadero') : i18next.t('Falso');
    }

    function setOptionMultipleFinalValue(values) {
      if (!values || !values.length) return '-';
      var res = '';
      values.forEach(function (f, i) { res += (values.length === (i + 1)) ? f : (values.length === (i + 2)) ? f + ' y ' : f + ', ' });
      return res;
    }

    function setDateFinalValue(value) {
      if (!value) return '-';
      return $filter('date')(value, 'dd/MM/yyyy');
    }

    function canDownload() {
      if (!that.currentAdditionalInformation || !that.currentAdditionalInformation._id || that.currentAdditionalInformation.type !== 'FILE') return false;
      if (!$scope.mainObject || !$scope.mainObject._id || !$scope.mainObject[addInfoKey] || !$scope.mainObject[addInfoKey].length) return false;
      if (!that.resourceName) return false;
      var files = $scope.mainObject[addInfoKey].filter(function (f) { return ((f.type === that.currentAdditionalInformation.type) && (f._id === that.currentAdditionalInformation._id)) });
      var currentFiles = files.filter(function (f) { return f.mimeType && f.value });
      if (!currentFiles || !currentFiles.length) return false;
      return true;
    }

    async function getFiles(file, mainObject) {
      try {
        if (!file || !file.type || !file._id) return ons.notification.alert({ message: i18next.t('Faltan datos para descargar'), title: i18next.t('Error descarga') });
        if (!that.resourceName) return ons.notification.alert({ message: i18next.t('No se especificó el origen de la descargar'), title: i18next.t('Error descarga') });
        if (!mainObject || !mainObject._id) return ons.notification.alert({ message: i18next.t('Falta objeto principal'), title: i18next.t('Error descarga') });
        var files = mainObject[addInfoKey].filter(function (f) { return ((f.type === file.type) && (f._id === file._id)) });
        var currentFiles = files.filter(function (f) { return f.mimeType && f.value });
        if (!currentFiles || !currentFiles.length) return ons.notification.alert({ message: i18next.t('No se pudo recuperar los archivos a descargar'), title: i18next.t('Error descarga') });
        var data = { resource: that.resourceName, mainObject: mainObject, infoName: file.name }
        if (file.privateFile) downloadPrivateFile(file, data)
        else downloadFile(data, file.value, 1)
        ////
        $scope.$applyAsync();
      }
      catch (err) {
        $scope.$applyAsync(() => { throw err });
      }
    }

    function viewFile(file) {
      if (!file || !file.mimeType) return;
      if (!$scope.mainObject || !$scope.mainObject._id) return;
      let url = '';
      if (file.privateFile) { //si usa Spaces
        url = privateFilesResource.getFileUrl(file.privateFile, 'file')
      } else url = `/api/reservation?_id=${$scope.mainObject._id}&fileName=${file.value}&_file=true`

      if (pdfViewer && file.mimeType === 'application/pdf') {
        pdfViewer.open({ file: { url: url, mimeType: 'application/pdf' } }).then().catch(function (err) { if (err) throw new PentaError(err) })
      } else if (galleryPreview && file.mimeType === 'image/png' || file.mimeType === 'image/jpeg') {
        galleryPreview.open({ file: { url: url, mediaType: 'IMAGE' } }).then().catch(function (err) { if (err) throw new PentaError(err) })
      }
    }

    function downloadFile(data, fileName, index) {
      download.href = '/api/' + data.resource;
      download.href += '?_id=' + data.mainObject._id;
      download.href += '&fileName=' + fileName;
      download.href += '&_file=' + true;
      download.download = '';
      download.download += data.mainObject.serial && data.mainObject.serial + '-';
      download.download += data.mainObject.name && data.mainObject.name + '-';
      download.download += data.infoName && data.infoName + '-';
      download.download += index;
      download.click();
    }

    function downloadPrivateFile(file) {
      if (!file || !file.privateFile) return
      let url = privateFilesResource.getFileUrl(file.privateFile, 'file')
      privateFilesResource.downloadAs(url, file.value)
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }

})();