'use strict';
(function () {
  var _controller = 'fileViewer.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('fileViewer', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        // '=': Two Way Binding, lo que cambia acá, cambia en el controlador padre automáticamente
        // '<': One Way Binding (RECOMENDADO), para recibir variables, funciones, etc
        // '@': String, para recibir strings, puede ser una bandera por ejemplo
        // '&': Dinámico, se ejecuta lo que está adentro, hay que pasarle un objeto con variables 
        //      ej: componente(call-back='algo(var)')  y en el controller callBack({var: 'esto'})
        fileData: '<', // !!OBLIGATORIO, item de archivo 
        fileType: '<', // !!OBLIGATORIO, Tipo de archivo: IMG, IMAGE, FILE-IMG, VIDEO, FILE-VIDEO, AUDIO, FILE-AUDIO
        resource: '<', // !!OBLIGATORIO, RECURSO DE DONDE SACAR LAS IMAGENES 
        videoSize: '<?', // Objeto {width: 160, height:220} PARA CONFIGURAR TAMAÑO DE VIDEO
      }
    })

  controller.$inject = ['$scope', '$injector'];
  function controller($scope, $injector) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/fileViewer/fileViewer.component.html' : 'components/fileViewer/fileViewer.component.jade';
    // VARS
    var galleryPreviewModal = $scope.$root.employeeApp ? $injector.get('galleryPreview.modal') : null;
    var $ctrl = this;

    // METHODS
    $ctrl.previewFile = previewFile;
    $ctrl.downloadAs = chatFunctions.downloadAs;
    $ctrl.employeeApp = $scope.$root.employeeApp;


    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    /// On component init
    this.$onInit = function () {
      if (!$ctrl.resource) return console.error('Falta especificar el recurso!');
      if (!$ctrl.fileData) return console.error('No hay item que visualizar!');
      if (!$ctrl.videoSize) $ctrl.videoSize = { width: 160, height: 220 };
      $scope.fileName = chatFunctions.getFileName($ctrl.fileData, $ctrl.fileType) //el campo "fileName" es cómo se guardó en db, si usa spaces se guarda todo como 'file', si usa el sistema viejo se guarda con 'image', 'video', 'audio', etc dependiendo del tipo de archivo
      // $scope.fileName = $ctrl.fileName || $ctrl.fileData.ts || ('archivo' + $ctrl.fileData._id);
      $scope.fileType = $ctrl.fileType || $ctrl.fileData.type;
    };

    function previewFile(item) {
      if (!item) item = $ctrl.fileData
      if (item.crc) item.url = $ctrl.resource.getFileUrl(item, 'file') //si el archivo es de spaces
      galleryPreviewModal.open({
        file: item,
        resource: $ctrl.resource
      }).then(function () { }).catch(function () { })
    }

    /////////////////////

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    //// FIN CTRL
  }

})();
