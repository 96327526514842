'use strict';

(function (exports) {
  exports.set = set;
  exports.get = get;

  function set(key, value) {
    if (value) localStorage.setItem(storagePath(key), value);
    else localStorage.removeItem(storagePath(key));
  }

  function get(key) { return localStorage.getItem(storagePath(key)); }

  function storagePath(key) { return enterprise + '_' + key; }
})(window.appStorage = {});