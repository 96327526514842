'use strict';
(function () {
  var _controller = 'attachedFiles.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('pmxAttachedFiles', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        title: '<', // STRING, titulo.
        files: '=', // ARRAY.
        required: '<', // BOOLEAN, campo requerido.
        config: '<', // OBJECT, configuración.
        //config: {
        //  multiple: true, //- permitir seleccionar varios archivos.
        //  accept: "'image/*'", //- tipo de archivo. Ej 'audio/*', 'video/*','.pdf','.doc'
        //  maxSize: '10MB', //- tamaño maximo del archivo.
        //  capture: "'camera'" //- permite que los dispositivos móviles capturen con la cámara.
        // }
      }
    })

  controller.$inject = ['$scope', '$element'];
  function controller($scope, $element) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/attachedFiles/attachedFiles.component.html' : 'components/attachedFiles/attachedFiles.component.jade';
    // METHODS
    $scope.addFile = addFile;
    $scope.addFiles = addFiles;
    $scope.changeFile = changeFile;
    $scope.removeFile = removeFile;

    // VARS
    var that = this;
    var defaultConfig = { multiple: false, maxSize: '5MB' };
    $scope.files = [];

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT

    /// On component init
    this.$onInit = function () {
      $scope.config = this.config || defaultConfig;
      if (!this.files || !this.files.length) this.files = [];
      $scope.files = this.files
    };

    /////////////////////
    function addFile(file) {
      if (!file) return
      $scope.files.push(file);
      validate()
    }

    function addFiles(files) {
      if (!files || !files.length) return
      files.forEach(function (f) { $scope.files.push(f) });
      validate()
    }

    function removeFile(file) {
      if (!file) return
      var index = $scope.files.findIndex(function (f) { return (f.lastModified === file.lastModified) && (f.name === file.name) });
      if (index !== -1) $scope.files.splice(index, 1);

    }

    function changeFile(oldFile, newFile) {
      if (!oldFile || !newFile) return
      var index = $scope.files.findIndex(function (f) { return (f.lastModified === oldFile.lastModified) && (f.name === oldFile.name) });
      if (index !== -1) {
        $scope.files.splice(index, 1);
        $scope.files.splice(index, 0, newFile);
      }
    }

    function validate() {
      if (!that.required) return
      $scope.reqValidate = $scope.files && $scope.files.length ? true : undefined;
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();
