'use strict';
(function () {
  var _controller = 'singleton.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'hq.socket'];
  function controller($scope, hqSocket) {
    // METHODS
    $scope.singleton = singleton;

    // VARS

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT
    init()

    /////////////////////
    function init() {
      hqSocket.disconnect();
    }

    function singleton() {
      hqSocket.connect();
      $scope.$root.ensureSingleton();
      $scope.$root.resetToLandingPage();
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
  }

})();
