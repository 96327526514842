(function () {
  angular.module('pentaApp').directive('ngEnter', directive);
  function directive() {
    return function ($scope, element, attrs) {

      element.bind("keydown keypress", onKeys);
      $scope.$on('$destroy', onDestroy);

      function onKeys(event) {
        if (event.which === 13) {
          $scope.$apply(function () { $scope.$eval(attrs.ngEnter); });
          event.preventDefault();
        }
      }
      function onDestroy() {
        element.unbind("keydown keypress", onKeys);
      }
    }
  }
})();