/**
 * Created by FERNANDO on 19/04/2016.
 */
'use strict';

(function () {
  angular.module('pentaApp').filter('utcOffset', myFilter);
  myFilter.$inject = ['$filter'];
  function myFilter($filter) {
    return function (value, utcOffset, format) {
      var offset = 0;
      if (utcOffset) {
        var parts = /(-?\d\d)(\d\d)/.exec(utcOffset);
        if(parts && parts[1]) offset = new Date().getTimezoneOffset() + parts[1] * 60 + parseInt(parts[2]);
      }
      if (typeof value === 'string') value = moment(value).add('minutes', offset).toDate(); else value = new Date(new Date(value).getTime() + offset * 60000);
      return $filter('date')(value, format);
    }
  }
})();
