'use strict';
(function () {
  angular.module('pentaApp').factory('ntnShoppingView.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/ntnShoppingView/ntnShoppingView.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data'];
  function controller($scope, modal, modalData) {
    // METHODS
    $scope.close = close;
    $scope.openStore = openStore;

    // VARS

    // INIT
    init()

    /////////////////////
    function init() {
      if (!modalData) close();
      $scope.allianceEnterprises = modalData && modalData.allianceEnterprises;
    }

    function openStore(enterprise) {
      if (!enterprise) return
      var url = window.location.origin + '/progressiveApp/' + enterprise + '/index.jade' + '?default=true';
      window.location.href = url;
    }

    function close() {
      modal.close()
    }
    ///////////////////////
  }

})();
