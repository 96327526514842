(function () {
  angular.module('pentaApp').factory("flyer.factory", factory);
  factory.$inject = ['$q', '$filter', '$rootScope', 'flyer.resource', 'image.resource'];
  function factory($q, $filter, $rootScope, flyerResource, imageResource) {
    var _controller = 'flyer.factory';

    return {
      getFlyers: getFlyers
    }
    ////////////

    function getFlyers(destination) {
      return $q(function (resolve, reject) {
        if (!destination) return reject(new PentaError(i18next.t('Se necesita un destino para obtener flyers')))
        flyerResource.query({ _controller: _controller, destination: destination }).$promise
          .then(function (flyers) {
            if (!flyers.length) pushDefaultFlyers(flyers); //- Flyers por default cuando no hay ninguno configurado.
            //- Flyers
            flyers = window.flyerFilter(flyers, $rootScope.defaultRouter); //- filtra condiciones requeridas
            if (!profile._id) flyers = flyers.filter(function (f) { return !f.inquery })
            flyers.forEach(function (f) {
              f.categories = ['General'] //- asigna categoria general a todos los flyers.
              var mediaType = f.mediaType && f.mediaType.toLowerCase();
              var url;
              //- SI TIENE TRADUCCION DEVUELVO LA MULTIMEDIA DE TRADUCCION
              if (f.translation && f.translation.length && mediaType === 'image') {
                var imageID = $filter('language')(f, 'image');
                if (imageID) url = imageResource.getFileUrl(imageID, 'image');
              }
              //- SI NO TIENE TRADUCCION DEVUELVO LA MULTEMEDIA DE GALERIA
              if (!url && f.media && mediaType) url = imageResource.getFileUrl(f.media, mediaType);
              f._imageUrl = url;
            })
            resolve(flyers);
          })
          .catch(function (err) { if (err) reject(new PentaError(err)) })
      })
    }

    function pushDefaultFlyers(flyers) {//- Flyers por default cuando no hay ninguno configurado.
      flyers.push({ mediaType: 'IMAGE', directUrl: '/frontend/static/img/defaultPwaHome.png', match: {} })
    }
  }
})();