'use strict';
(function () {
  angular.module('pentaApp').factory('additionalInformation.resource', service);

  service.$inject = ['$resource'];
  function service($resource) {
    return $resource('/progressiveApp/api/additionalInformation', null, { query: { isArray: true, cache: true } });
  }

})();
