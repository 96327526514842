'use strict';
(function () {
  var _controller = 'flyers.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('pmxFlyers', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        // '=': Two Way Binding, lo que cambia acá, cambia en el controlador padre automáticamente
        // '<': One Way Binding (RECOMENDADO), para recibir variables, funciones, etc
        // '@': String, para recibir strings, puede ser una bandera por ejemplo
        // '&': Dinámico, se ejecuta lo que está adentro, hay que pasarle un objeto con variables 
        //      ej: componente(call-back='algo(var)')  y en el controller callBack({var: 'esto'})
        data: '<', //- Array, flayers a mostrar
        defaultRouter: '<', //- String, Requerido para obtener encuestas
        categorie: '<', //- String, Categoria filtrada previamente
        flyerResource: '<', //- Recurso de Flyers
      }
    })

  controller.$inject = ['$scope', '$element', '$q', 'customInquery.resource', 'customInqueryStatistics.resource', 'clientCustomInquery.resource', 'flyerViewLog.resource'];
  function controller($scope, $element, $q, customInqueryResource, customInqueryStatisticsResource, clientCustomInqueryResource, flyerViewLogResource) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/flyers/flyers.component.html' : 'components/flyers/flyers.component.jade';

    // METHODS
    $scope.activeItem = activeItem;
    $scope.saveSurveyResponse = saveSurveyResponse;
    $scope.mutedVideo = mutedVideo;
    $scope.pauseCarousel = pauseCarousel;
    $scope.resumeCarousel = resumeCarousel;
    $scope.openUrl = openUrl;
    $scope.templateInit = templateInit;

    // VARS
    var defaultRouter = null;
    var currentTimer = null;
    var activeFlyer = null;
    var lastFlyer = null;
    var lastChangeDate = null;
    var carousel = null;
    var that = null;
    $scope.flyerResource = null; //- recurso de flyers
    $scope.activeBadge = null; //- index del elemento activo del carousel
    $scope.currentFlyers = []; //- array de flyers
    $scope.categorie = null;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    this.$onChanges = function (bind) {
      if (!bind.data) return;
      if (_.isEmpty(bind.data.previousValue)) return
      if (bind.data.previousValue !== bind.data.currentValue) init();
    }

    // INIT
    function templateInit() {
      carousel = $element.find('ons-carousel')[0];
      init();
    }
    /// On component init
    this.$onInit = function () { that = this; }

    function init() {
      if (that.defaultRouter) defaultRouter = that.defaultRouter; //- verificar si exite default Router
      else return console.error('SE_NECESITA_DEFAULT_ROUTER')
      if (that.flyerResource) $scope.flyerResource = that.flyerResource; //- verificar si exite flyer Resource
      else return console.error('SE_NECESITA_RECURSO_DE_FLYERS')
      if (that.categorie) $scope.categorie = that.categorie; //- verificar si exite categoria
      if (that.data && that.data.length) { //- verificar si exiten flyers
        $scope.currentFlyers = [];
        $scope.currentFlyers = that.data;
        $scope.currentFlyers.forEach(flyersConfig)
        var index = $scope.currentFlyers.findIndex(function (f) { return f._selected })
        if (index !== -1) setTimeout(function () { carousel.setActiveIndex(index) }, 100)
      }
    };

    /////////////////////
    function flyersConfig(flyer) {//- Obtener datos secundarios de cada Flyer
      if (flyer.inquery) getInquiry(flyer, function (data) {//- obtener encuesta
        if (data) {
          flyer._inquery = data;
          //- obtener respuesta del cliente a dicha encuesta si esta "NO SE PUEDE REPETIR"
          if (!flyer._inquery.canRepeat) {
            getClientAnswer(flyer.inquery, flyer.question, function (data) {
              if (data) {
                flyer._inquery._answer = data;
                if (flyer._inquery.answerType === 'TEXT' && data.text) flyer._inquery.textAnswer = data.text;
                getSurveyStatistics(flyer.inquery, flyer._inquery, function (data) { if (data) flyer._inquery._percent = data })
              }
            })
          }
        }
      }) //- obtener encuesta
    }

    function getInquiry(flyer, done) {//- Obtiene la encuesta seleccionada
      if (!flyer) return
      customInqueryResource.detail({ _id: flyer.inquery, _controller: _controller }, function (data) {
        var question = null;
        if (flyer.question) question = data.questions.find(function (f) { return f._id === flyer.question });
        else question = data.questions[0];
        //- Filtro encuesta valida
        if (!question) return done() //- KO - la encuesta no es con Opciones
        if (data.canRepeat) question.canRepeat = data.canRepeat;
        if (data.askForRouter) question.askForRouter = data.askForRouter;
        if (question) return done(question)
      })
    }

    function getClientAnswer(inquery, question, done) {//- Obtiene la respuesta del CLIENTE a una encuesta
      clientCustomInqueryResource.queryNoPaginate({
        _controller: _controller,
        "customInquery._id": inquery,
        _sortBy: '-_id',
        _select: ['_id', 'question', 'option', 'text', 'askForRouter']
      }, function (data) {
        if (!data || !data.length) return done()
        else return done(data.find(function (f) { return f.question === question }))
      })
    }

    function saveSurveyResponse(customInquery, question, answer) {//- Guarda la respuesta del CLIENTE a una encuesta
      if (question._answer) return //- si ya tiene una respuesta
      clientCustomInqueryResource.save({
        _controller: _controller,
        usedTime: Date.now() - lastChangeDate,
        router: question.askForRouter ? defaultRouter : undefined,
        customInquery: customInquery,
        question: question._id,
        option: (question.answerType === 'OPTIONS') ? answer._id : undefined,
        text: (question.answerType === 'OPTIONS') ? answer.text : answer
      }, function () {
        question._answer = {
          option: (question.answerType === 'OPTIONS') ? answer._id : undefined,
          text: (question.answerType === 'OPTIONS') ? answer.text : answer,
          question: question._id,
        }
        if (question.answerType === 'OPTIONS') getSurveyStatistics(customInquery, question, function (data) { if (data) question._percent = data; })
      })
    }

    function getSurveyStatistics(customInquery, question, done) {
      if (!customInquery) return
      if (!question || !question.options.length) return
      customInqueryStatisticsResource.queryNoPaginate({
        _controller: _controller,
        type: 'QUESTION-SUMMARY',
        customInquery: customInquery,
        router: question.askForRouter ? defaultRouter : undefined
      }, function (data) {
        if (!data || !data.length) return done()
        var total, optionsStatics, statics = {};
        var questionStatistics = data.find(function (q) { return q._id.question === question._id })
        if (!questionStatistics || !questionStatistics.options || !questionStatistics.options.length) return done();
        total = questionStatistics.total;
        optionsStatics = questionStatistics.options;
        question.options.forEach(function (f) {
          var option = optionsStatics.find(function (q) { return q.option === f._id })
          if (option) statics[option.option] = option.count / total * 100;
          else statics[f._id] = 0;
        })
        return done(statics)
      })
    }

    function activeItem(event) {//- cfg del elemento activo del 'carousel'
      activeFlyer = null;
      lastChangeDate = Date.now();
      if (currentTimer) currentTimer.clear(); //- si existe un timer activo lo borra
      var activeIndex = event.activeIndex || 0; //- adquiere el index del elemento activo del carousel
      var lastIndex = event.lastActiveIndex || 0; //- adquiere el index del elemento activo anterior del carousel
      $scope.activeBadge = activeIndex;

      //- flyer anterior
      if (lastIndex >= 0) lastFlyer = $scope.currentFlyers[lastIndex]
      if (lastFlyer && lastFlyer.mediaType === 'VIDEO') {//- verifica si el elemento activo anterior fue un video
        document.getElementById(lastFlyer._id + $scope.categorie).pause(); //- Etiqueta de video - PAUSE()
        document.getElementById(lastFlyer._id + $scope.categorie).currentTime = 0; //- Etiqueta de video - vuelve al inicio
        document.getElementById(lastFlyer._id + $scope.categorie).muted = false; //- Etiqueta de video - quitar posible muteo
        $scope.muteded = false; //- fab icon
      }

      //- flyer actual
      if (activeIndex >= 0) activeFlyer = $scope.currentFlyers[activeIndex]
      if (!activeFlyer) return console.error('ERR_AL_OBTENER_FLYER_DEL_CAROUSEL');
      if (activeFlyer.mediaType === 'VIDEO') {
        document.getElementById(activeFlyer._id + $scope.categorie).currentTime = 0; //- Etiqueta de video - vuelve al inicio
        document.getElementById(activeFlyer._id + $scope.categorie).muted = false; //- Etiqueta de video - quitar posible muteo
        document.getElementById(activeFlyer._id + $scope.categorie).play(); //- Etiqueta de video - PLAY()
        $scope.muteded = false; //- fab icon
      }

      //- set Timer's flyer actual
      currentTimer = new carouselDelay(activeFlyer.delay, activeFlyer.logViewAfter)
    }

    function mutedVideo(muted) {//- sonido ON/OFF de un video
      $scope.muteded = muted;
      if (activeFlyer.mediaType === 'VIDEO') document.getElementById(activeFlyer._id + $scope.categorie).muted = muted;
    }

    function openUrl(link) {
      if (!link) return
      var url = link;
      var str = new RegExp("^(http|https)://", "i");
      var match = str.test(url)
      if (!match) url = "http://" + url;
      window.open(url, '_blank')
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
      if (currentTimer) currentTimer.clear();
    }

    ///////////////////////////////////////////
    //- set Delay 
    var carouselDelay = function (delayToNext, delayToSaveLog) {
      var timerCarousel = null, timerLog = null, start;
      if (!delayToNext) delayToNext = 4000;

      this.resume = function () {
        start = Date.now();
        if (timerCarousel) window.clearTimeout(timerCarousel); //- clear timerCarousel
        if (timerLog) window.clearTimeout(timerLog); //- clear timerLog
        //- Timer Carousel
        if (delayToNext && delayToNext >= 0) {
          timerCarousel = window.setTimeout(function () {//- set timeout
            //- si no es el ultimo elemento del carousel pasa al siguiente, si es el ultimo pasa al primero.
            if (carousel.getActiveIndex() !== ($scope.currentFlyers.length - 1)) carousel.next();
            else carousel.first();
          }, delayToNext);
        }
        //- Timer guardar LOG
        if (delayToSaveLog && delayToSaveLog >= 0) {
          timerLog = window.setTimeout(function () {//- set timeout
            if (profile._id)
              flyerViewLogResource.save({
                flyerId: activeFlyer._id,
                category: $scope.categorie || activeFlyer.categories[0],
                router: defaultRouter,
                _ignoreVeil: true,
                _ignoreError: true,
                _controller: _controller
              })
          }, delayToSaveLog)
        }
      };

      this.pause = function () {
        if (delayToNext && timerCarousel) {
          window.clearTimeout(timerCarousel);
          delayToNext -= Date.now() - start; //- set tiempo restante.
        }
        if (delayToSaveLog && timerLog) {
          window.clearTimeout(timerLog);
          delayToSaveLog -= Date.now() - start; //- set tiempo restante.
        }
      };

      this.clear = function () {
        window.clearTimeout(timerCarousel); //- clear timerCarousel
        window.clearTimeout(timerLog); //- clear timerLog
      }

      this.resume();
    };

    //- Touch pause/resume Carousel
    function pauseCarousel() {
      if (currentTimer) currentTimer.pause();
    }

    function resumeCarousel() {
      if (currentTimer) currentTimer.resume();
    }

    $element[0].addEventListener('pointerdown', function () {
      if (activeFlyer._inquery && activeFlyer._inquery.answerType === 'TEXT' && !activeFlyer._inquery._answer) return
      if (currentTimer) currentTimer.pause();
      if (activeFlyer.mediaType === 'VIDEO') document.getElementById(activeFlyer._id + $scope.categorie).pause();
    })

    $element[0].addEventListener('pointerup', function () {
      if (activeFlyer._inquery && activeFlyer._inquery.answerType === 'TEXT' && !activeFlyer._inquery._answer) return
      if (currentTimer) currentTimer.resume();
      if (activeFlyer.mediaType === 'VIDEO') document.getElementById(activeFlyer._id + $scope.categorie).play();
    })
    //// FIN CTRL
  }

})();
