(function () {
    angular.module('pentaApp').directive('fallbackSrc', directive);

    directive.$inject = ['$http'];

    function directive($http) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var isVideo = element[0].nodeName === "VIDEO"
                attrs.$observe('ngSrc', function (ngSrc) {
                    if (ngSrc === '//:0' || !ngSrc) {
                        setTimeout(function () {
                            if (isVideo) element.attr('poster', attrs['fallbackSrc']);
                            else element.attr('src', attrs['fallbackSrc'])
                        }, 0);
                    } else {
                        setTimeout(function () {
                            if (isVideo) element.attr('poster', attrs['fallbackSrc']);
                            else element.attr('src', attrs['fallbackSrc'])
                            var image = new Image()
                            image.src = ngSrc
                            image.onload = function(){
                                element.attr('src', image.src)
                                image.onerror = null
                                image.onload = null
                                image = null
                            }
                            image.onerror = function(){
                                image.onerror = null
                                image.onload = null
                                image = null
                            }
                        }, 0);
                        
                        // $http.get(ngSrc, {
                        //     _ignoreVeil: true,
                        //     errorHandled: true
                        // }).error(function () {  
                        //     if (isVideo) element.attr('poster', attrs['fallbackSrc']);
                        //     else element.attr('src', attrs['fallbackSrc'])
                        // });
                    }
                });
            }
        };
    }
})();