'use strict';
(function () {
  var _controller = 'nps.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'inqueryLog.resource'];
  function controller($scope, inqueryLogResource) {
    /// METHODS
    $scope.save = save;

    /// VARS
    $scope.profile = profile;
    $scope.ratingConfig = {
      rating: {
        max: 5,
        multiplier: 2
      }
    }
    $scope.cantResponse = false;
    var fromLink = false;

    /// Se lo llama con {router: id, origin: 'STORE', order: id_order_o_turn_etc, employee: id}
    $scope.inqueryLog = appNavigator.topPage.data;
    if (!$scope.inqueryLog || !$scope.inqueryLog.origin) {
      $scope.inqueryLog = {
        router: $scope.$root.defaultRouter,
        origin: window.queryStrings.origin,
        order: window.queryStrings.order,
        reservation: window.queryStrings.reservation,
        response: window.queryStrings.response
      };
      fromLink = true;
    }
    var onClose = appNavigator.topPage.data.onClose;
    var onResponse = appNavigator.topPage.data.onResponse;
    delete appNavigator.topPage.data.onClose;
    delete appNavigator.topPage.data.onResponse;
    $scope.inqueryLog.onClose = undefined;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$on('login', function () { window.location.reload() });


    // INIT
    init();
    /////////////////////

    function init() {
      if ($scope.inqueryLog.origin === 'LINK') {
        var lastResponse = window.appStorage.get('npsLinkLastResponse');
        if (!lastResponse) return
        var days = moment().startOf('day').diff(moment(lastResponse).startOf('day'), "days");
        if (days < 1) {
          $scope.responded = true;
          ons.notification
            .alert({ message: i18next.t('Usted ya envió una opinión en el dia de hoy. Solo puede puntuar una vez por día.'), title: i18next.t('Aviso'), buttonLabels: 'Entendido' })
            .then(function () {
              window.location.reload();
            });
          return
        }
      }
      else
        inqueryLogResource.get({
          order: $scope.inqueryLog.order,
          reservation: $scope.inqueryLog.reservation
        }, function (data) {
          if (data && data._id) { $scope.inqueryLog = data; $scope.responded = true; }
        })
    }

    function save() {
      inqueryLogResource.save($scope.inqueryLog, function () {
        window.appStorage.set('npsLinkLastResponse', new Date());
        if ($scope.$root.profile && $scope.$root.profile._id)
          ons.notification
            .alert({ message: i18next.t('Muchas gracias por ayudarnos'), title: i18next.t('Listo') })
            .then(function () {
              if (fromLink) return window.location.reload();
              appNavigator.popPage({
                callback: function () {
                  if (onClose) onClose();
                  if (onResponse) onResponse();
                }
              });
            })
        else
          ons.notification
            .confirm({ message: i18next.t('Muchas gracias por ayudarnos, registrate para recibir promociones y beneficios'), title: i18next.t('Listo'), buttonLabels: [i18next.t('Registrate'), i18next.t('Salir')] })
            .then(function (response) {
              if (!response) { return $scope.$root.login().then(function () { window.location.reload() }); }
              window.location.reload();
            })
      })
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
  }

})();
