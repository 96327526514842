(function () {
  var _controller = 'ntnMallBackBar.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('ntnMallBackBar', {
      templateUrl: 'components/ntnMallBackBar/ntnMallBackBar.component.jade',
      controller: _controller,
      bindings: {
        category: '<', //- objeto de categoría ( grupo en db )
      }
    })

  controller.$inject = ['$scope', '$element'];
  function controller($scope, $element) {
    var that = this;
    //// METHODS
    that.popPage = popPage;

    //// VARS

    //// WATCHERS
    $scope.$on('$destroy', onDestroy);

    //// INIT
    this.$onInit = function () {
      if (!this.category) return
      init()
    };

    ////////////
    function init() {
      if (!that.category) return
    }

    function popPage() {
      if (!appNavigator) return
      appNavigator.popPage();
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    // FIN CONTROLLER
  }
})();
