'use strict';
(function () {
  var _controller = 'additionalInformation.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('additionalInformation', {
      template: '<ng-include src="templateUrl" onload="templateInit()"></ng-include>',
      controller: _controller,
      bindings: {
        data: '=', /// Array del modelo de Información Adicional.
        persistInProfile: '=?',
        preSaveCallback: '=?', // Función que debe ejecutarse justo antes de guardar para: Encriptar información, (a futuro otras funciones)
        config: '<', /// Configuración opcional
        noDataLabel: '=',
        editable: '='
        ///{
        ///  groups: [] // Opcional
        ///  accordion: Boolean // Muestra/oculta acordion
        ///  initialAccordion: String, // OPENED, CLOSED, OPEN-ONLY-FIRST
        ///}
      }
    })

  controller.$inject = ['$scope', '$element', '$q', '$injector', 'group.resource', 'publicKey.resource'];
  function controller($scope, $element, $q, $injector, groupResource, publicKeyResource) {
    $scope.templateUrl = $scope.$root.employeeApp ? '/frontend/commons/components/additionalInformation/additionalInformation.component.html' : 'components/additionalInformation/additionalInformation.component.jade';
    var coworkersResource = $scope.$root.employeeApp ? $injector.get('coworkers.resource') : null;
    var profileResource = $scope.$root.employeeApp ? null : $injector.get('profile.resource');

    // METHODS
    $scope.setCheckboxMultipleOptionsValue = setCheckboxMultipleOptionsValue;
    $scope.validateCheckboxMultipleOptions = validateCheckboxMultipleOptions;
    $scope.showIf = showIf;
    $scope.selectedGroup = selectedGroup;
    $scope.createNewGroup = createNewGroup;
    var processLog = debounce(_processLog, 200);

    // VARS
    var that = this;
    var groups, coworkers;
    var watchers = [];
    $scope.result = [];
    $scope.group = [];
    $scope.coworkers = [];
    $scope.AttachedImgFilesConfig = { multiple: true, accept: "'image/*'", maxSize: '10MB', capture: "'camera'" };
    $scope.AttachedAllFilesConfig = { multiple: true, maxSize: '10MB', capture: "'camera'" };
    var aiProfile = $scope.$root.profile && $scope.$root.profile.enterprise && $scope.$root.profile.enterprise.additionalInformation || [];
    var aiProfileStorage = $scope.$root.profile && $scope.$root.profile.enterprise && $scope.$root.profile.enterprise.additionalInformationStorage || [];
    var publicKeys;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('$ctrl.data', function (newVal, oldVal) {
      if (newVal === oldVal) return
      if (newVal) preProcess(groups, coworkers);
    })

    // INIT

    /// On component init
    this.$onInit = function () {
      $scope.config = this.config || { accordion: false };
      this.persistInProfile = persistInProfile;
      this.preSaveCallback = preSaveCallback;
      init();
    };

    /////////////////////
    function init() {
      if (!that.data || !that.data.length) return
      var promises = [];

      // Si no se recibe groups se consultan a base de datos, sino se utiliza el array recibido.
      if (!$scope.config.groups || !$scope.config.groups.length) {
        groups = groupResource.query({ _select: ['name', 'description', 'type'], type: 'ADDITIONAL-INFORMATION' });
        promises.push(groups.$promise);
      }
      else groups = $scope.config.groups;

      // Busco las llaves públicas
      publicKeys = publicKeyResource.query({ _select: ['key'], _paginate: false })
      promises.push(publicKeys.$promise)

      // Verifico que exista un tipo COWORKER en data para realizar la consulta.
      var neededCoworker = that.data.find(function (f) { return f.type === 'COWORKER' });
      if (neededCoworker && coworkersResource) {
        coworkers = coworkersResource.queryNoPaginate({});
        promises.push(coworkers.$promise);
      }
      $q
        .all(promises)
        .then(function () { preProcess(groups, coworkers); })
        .catch(function (err) { if (err) throw new PentaError(err); })
    }

    function preProcess(groups, coworkers) {
      $scope.groups = groups || [];
      if (coworkers && coworkers.length) $scope.coworkers = coworkers;
      $scope.groupsAdditionalInformation = {};
      sortData();
      regExpData();
      setGroups();
      setLastDataFromGroups();
      setDate();
      setAccordionSettings();
      clearWatches();
      that.data.forEach(function (ai, index) {
        logSetup(ai, index);
        if (ai.ts) ai.ts = Date.now();
        if (ai.type === 'OPTIONS-MULTIPLE') {
          if (ai.value && ai.value.length) {
            ai.value.forEach(function (value) {
              var option = ai.options && ai.options.length && ai.options.find(function (option) { return option.name === value });
              if (option) option.checked = true;
            })
          }
        };
        if (ai.type === 'BOOLEAN' && ai.value === undefined) ai.value = false;
      })
    }

    //- Esta función recibe additional information y un indice. Genera lo necesario para aplicar el log sobre esta información adicional.
    function logSetup(ai, index) {
      if (!ai.logRequired || ai.value === undefined || !ai.ts) return //- Esto se necesita para que pueda tener log.
      ai._lastData = { value: JSON.parse(JSON.stringify(ai.value)), ts: JSON.parse(JSON.stringify(ai.ts)) }; //- Asigno dentro de cada info adicional una var temporal con el valor actual.
      if (!ai.logs) ai.logs = [];
      watchers.push(
        $scope.$watch('$ctrl.data[' + index + '].value', function (newVal, oldVal) {
          if (newVal === undefined || newVal === oldVal) return
          processLog(that.data[index]);
        })
      );
    }

    function _processLog(ai) {
      if (ai.value === undefined || !ai._lastData || !ai._lastData.value === undefined || !ai._lastData.ts) return console.error(i18next.t('faltan datos'));
      if (Array.isArray(ai.value)) {
        var index = ai.logs.findIndex(function (f) { return (_.isEqual(f.value.sort(), ai._lastData.value.sort())) && (f.ts === ai._lastData.ts) });
        if (_.isEqual(ai.value.sort(), ai._lastData.value.sort())) {
          if (index !== -1) ai.logs.splice(index, 1);
        } else {
          if (index === -1) ai.logs.push({ value: ai._lastData.value, ts: ai._lastData.ts });
        }
      } else {
        var index = ai.logs.findIndex(function (f) { return (f.value === ai._lastData.value) && (f.ts === ai._lastData.ts) });
        if (ai.value == ai._lastData.value) {
          if (index !== -1) ai.logs.splice(index, 1);
        } else {
          if (index === -1) ai.logs.push({ value: ai._lastData.value, ts: ai._lastData.ts });
        }
      }
    }

    function sortData() {
      if (!that.data || !that.data.length) return
      that.data.sort(function (a, b) { return a.sortIndex - b.sortIndex });
    }

    function regExpData() {
      if (!that.data || !that.data.length) return
      that.data.forEach(function (f) { if (f.validation && f.validation.regexp) f.validation._regexp = new RegExp(f.validation.regexp) });
    }

    function setGroups() {
      if (!that.data || !that.data.length) return
      that.data.forEach(function (f) {
        if (!f.groups || !f.groups.length) {
          if (!$scope.groupsAdditionalInformation['']) $scope.groupsAdditionalInformation[''] = {};
          if (!$scope.groupsAdditionalInformation[''].additionalInformation) $scope.groupsAdditionalInformation[''].additionalInformation = [];
          $scope.groupsAdditionalInformation[''].additionalInformation.push(f)
        } else {
          f.groups.forEach(function (group) {
            if (!$scope.groupsAdditionalInformation[group]) $scope.groupsAdditionalInformation[group] = {};
            if (!$scope.groupsAdditionalInformation[group].additionalInformation) $scope.groupsAdditionalInformation[group].additionalInformation = [];
            $scope.groupsAdditionalInformation[group].additionalInformation.push(f);
          })
        }

      })

      aiProfileStorage.forEach(function (ai) {
        if (!ai || !ai.group) return
        if (!$scope.groupsAdditionalInformation[ai.group]) return
        if (!$scope.groupsAdditionalInformation[ai.group].profileStorage) $scope.groupsAdditionalInformation[ai.group].profileStorage = [];
        $scope.groupsAdditionalInformation[ai.group].profileStorage.push(ai);
      })
    }

    //- Esta función obtiene cada ultimo grupo utilizado del perfil y asigna sus valores.
    function setLastDataFromGroups() {
      if (!$scope.groupsAdditionalInformation) return
      for (var key in $scope.groupsAdditionalInformation) {
        if (!key || key === "" || !$scope.groupsAdditionalInformation[key]) continue
        if (!$scope.groupsAdditionalInformation[key].profileStorage || !$scope.groupsAdditionalInformation[key].profileStorage.length) continue
        //- Obtengo el ultimo grupo utilizado.
        $scope.groupsAdditionalInformation[key]._lastGroupSelected = $scope.groupsAdditionalInformation[key].profileStorage.reduce(function (r, a) { return new Date(r.lastUsed) > new Date(a.lastUsed) ? r : a; });
        if ($scope.groupsAdditionalInformation[key]._lastGroupSelected) {
          //- Asigno los valores del ultimo grupo seleccionado.
          setValue($scope.groupsAdditionalInformation[key]._lastGroupSelected.additionalInformation, key)
        }
      }
    }

    function setDate() {
      if (!that.data || !that.data.length) return
      that.data.forEach(function (f) { if (f.type === 'DATE') f.value = new Date(f.value) });
    }

    function setAccordionSettings() {
      if (!$scope.groupsAdditionalInformation || !$scope.config) return
      var first = null;
      for (var key in $scope.groupsAdditionalInformation) {
        if (!$scope.config.accordion) {
          $scope.groupsAdditionalInformation[key]._show = true;
          continue
        }
        if (!$scope.config.initialAccordion) continue
        switch ($scope.config.initialAccordion) {
          case "OPENED":
            $scope.groupsAdditionalInformation[key]._show = true;
            break
          case "CLOSED":
            // Si el grupo no tiene nombre/no hay grupo, siempre se muestra al inicio
            if (key === '') { $scope.groupsAdditionalInformation[key]._show = true; continue; }
            $scope.groupsAdditionalInformation[key]._show = false;
            break
          case "OPEN-ONLY-FIRST":
            // Si el grupo no tiene nombre/no hay grupo, siempre se muestra al inicio
            if (key === '') { $scope.groupsAdditionalInformation[key]._show = true; continue; }
            if (!first) {
              first = true;
              $scope.groupsAdditionalInformation[key]._show = true;
            } else $scope.groupsAdditionalInformation[key]._show = false;
            break;
          default:
            $scope.groupsAdditionalInformation[key]._show = true;
            break
        }
      }
    }

    function setCheckboxMultipleOptionsValue(obj) {
      if (!obj || !obj.options || !obj.options.length) return
      var arr = [];
      obj.options.forEach(function (f) { if (f.checked) arr.push(f.name); })
      obj.value = arr;
    }

    function validateCheckboxMultipleOptions(options) {
      if (!options || !options.length) return false
      if (options.find(function (f) { return f.checked })) return false;
      return true
    }

    function showIf(additionalInfo) {
      if (!additionalInfo.interConditions || !additionalInfo.interConditions.length) return true;
      var show = additionalInfo.interConditions.find(function (interCondition) {
        if (!interCondition.and && !interCondition.and.length) return true;
        return interCondition.and.every(function (condition) { return checkCondition(condition, that.data) });
      })
      if (!show && additionalInfo.value) additionalInfo.value = null;
      return show
    }

    function checkCondition(condition, allAdditionalInformations) {
      var toCompare = allAdditionalInformations.find(function (f) { return f._id === condition.additionalInformation });
      if (!toCompare) return true;
      switch (condition.condition) {
        case 'EXISTS':
          if (toCompare.value) return true;
          break;
        case 'EQUAL':
          if (toCompare.type === 'BOOLEAN') {
            if (condition.value && toCompare.value) return true;
            if (!condition.value && !toCompare.value) return true;
          } else {
            if (condition.value === toCompare.value) return true;
          }
          break;
        case 'NOT-EQUAL':
          if (toCompare.type === 'BOOLEAN') {
            if (condition.value && !toCompare.value) return true;
            if (!condition.value && toCompare.value) return true;
          } else {
            if (condition.value !== toCompare.value) return true;
          }
          break;
        case 'GT':
          if (angular.isNumber(toCompare.value) && toCompare.value > condition.value) return true;
          break
        case 'LT':
          if (angular.isNumber(toCompare.value) && toCompare.value <= condition.value) return true;
          break
        case 'GTE':
          if (angular.isNumber(toCompare.value) && toCompare.value >= condition.value) return true;
          break
        case 'LTE':
          if (angular.isNumber(toCompare.value) && toCompare.value <= condition.value) return true;
          break
        default: return false;
      }
    }

    function clearWatches() {
      if (!watchers || !watchers.length) return
      watchers.forEach(function (watcher) { watcher(); })
    }

    function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }

    //- Esta función se debe ejecutar desde el controller cual utilice el componente y requiera persistir en el perfil
    //- la información adicional. Solo PWA.
    function persistInProfile() {
      if (!profileResource) return console.error(i18next.t('Err falta profile resource'));
      if (!$scope.$root.profile.enterprise) return console.error(i18next.t('Err profile enterprise'));
      //- Filtro aquellas info adicional que no tengan value o sean tipo FILE;
      // No guardo información que deba encriptarse en el perfil
      var data = that.data.filter(function (f) { return (f.value || (!f.value && f.type === 'BOOLEAN')) && f.type !== 'FILE' && !f.publicKey });
      if (!data || !data.length) return //- SI no hay información adicional no modifico lo que esta.
      else
        data.forEach(function (item) {
          var index = aiProfile.findIndex(function (f) { return f._id === item._id });
          if (index === -1) aiProfile.push(item);
          else aiProfile.splice(index, 1, item);
        })
      saveProfile(aiProfile, processGroups());
    }

    //- Esta función procesa la información del ultimo grupo seleccionado. Genera o actualiza los valores.
    function processGroups() {
      if (!that.data || !that.data.length) return //- Si no hay informacion adicional retorno.
      if (!$scope.groups || !$scope.groups.length) return aiProfileStorage //- Si no hay grupos no se puede procesar informacion.
      for (var key in $scope.groupsAdditionalInformation) {
        if (!key || key === "") continue;
        if (!$scope.groupsAdditionalInformation[key]._lastGroupSelected) {
          var name = $scope.groups.find(function (f) { return f._id === key }).name + ' Nº 1';
          aiProfileStorage.push({
            name: name,
            group: key,
            lastUsed: Date.now(),
            additionalInformation: $scope.groupsAdditionalInformation[key].additionalInformation
          });
        } else {
          var index = aiProfileStorage.findIndex(function (f) { return !f._new && f._id === $scope.groupsAdditionalInformation[key]._lastGroupSelected._id });
          if (index === -1) {
            aiProfileStorage.push({
              name: $scope.groupsAdditionalInformation[key]._lastGroupSelected.name,
              group: key,
              lastUsed: Date.now(),
              additionalInformation: $scope.groupsAdditionalInformation[key].additionalInformation,
              _new: true
            });
          } else {
            that.data
              .filter(function (a) { return a.groups && a.groups.length && a.groups.find(function (g) { return g === key }) })
              .forEach(function (f) {
                var data = aiProfileStorage[index].additionalInformation.find(function (g) { return g._id === f._id });
                if (data) data.value = f.value;
                else aiProfileStorage[index].additionalInformation.push(f);
              })
            aiProfileStorage[index].lastUsed = Date.now();
          }
        }
      }
      return aiProfileStorage
    }

    //- Esta función guarda en el profile del cliente la información adicional.
    function saveProfile(additionalInformation, additionalInformationStorage) {
      if (!$scope.$root.profile || !$scope.$root.profile._id) return console.error(i18next.t('Error profile'));
      if (!additionalInformation || !additionalInformationStorage) return console.error(i18next.t('Error al intentar guardar información adicional en el perfil'));
      profileResource.save({ _id: $scope.$root.profile._id, additionalInformation: additionalInformation, additionalInformationStorage: additionalInformationStorage }, function (data) {
        if (!data || !data.enterprise) return
        $scope.$root.profile.enterprise.additionalInformation = data.enterprise.additionalInformation
        $scope.$root.profile.enterprise.additionalInformationStorage = data.enterprise.additionalInformationStorage
      });
    }

    //- Esta función asigna el value del grupo de información seleccionado.
    function selectedGroup(storage) {
      if (!storage) return
      setValue(storage.additionalInformation, storage.group);
      setLastSelectedGroup(storage);
    }

    //- Esta función asigna el ultimo grupo de información utilizado para procesar al guardar.
    function setLastSelectedGroup(storage) {
      if (!storage || !storage.group) return
      $scope.groupsAdditionalInformation[storage.group]._lastGroupSelected = storage;
    }

    //- Esta función asigna el value de cada elemento del array del parametro 'additionalInformation' a that.data;
    function setValue(additionalInformations, group) {
      if (!additionalInformations || !additionalInformations.length) return
      if (!group) return
      if (!that.data || !that.data.length) return
      that.data
        .filter(function (a) { return a.groups && a.groups.length && a.groups.find(function (g) { return g === group }) })
        .forEach(function (f) {
          var data = additionalInformations.find(function (g) { return g._id === f._id });
          if (data) f.value = data.value;
          else f.value = undefined;
        })
    }

    //- Esta función crea un nuevo grupo de información.
    function createNewGroup(key) {
      if (!key || key === "") return console.error(i18next.t('Error al crear grupo'));
      if (!$scope.groups || !$scope.groups.length) return //- Si no hay grupos no se puede crear uno nuevo.
      var group = $scope.groupsAdditionalInformation[key];
      if (!group || !group.profileStorage || !group.profileStorage.length) return
      var additionalInformationGroup = JSON.parse(JSON.stringify(group.additionalInformation));
      if (!additionalInformationGroup || !additionalInformationGroup.length) return
      additionalInformationGroup.forEach(function (f) { f.value = undefined });
      var name = $scope.groups.find(function (f) { return f._id === key }).name + ' Nº ' + (group.profileStorage.length + 1) || 'Nº ' + (group.profileStorage.length + 1);
      var lastGroupSelected = {
        name: name,
        group: key,
        additionalInformation: additionalInformationGroup,
        lastUsed: Date.now()
      }
      group.profileStorage.push(lastGroupSelected);
      group._lastGroupSelected = lastGroupSelected;
      setValue(additionalInformationGroup, key);
    }

    // Esta función la ejecuta el controlador padre de este componente justo antes de enviar los datos al servidor
    function preSaveCallback() {
      encryptAdditionalInformation(that.data) // Encripto la información adicional
    }

    function encryptAdditionalInformation(additionalInformations) {
      if (!additionalInformations) throw new PentaError('Error al intentar encriptar información adicional')
      var aiToEncrypt = additionalInformations.filter(function (f) { return f.publicKey && !f.encrypted })
      if (!aiToEncrypt.length) return;
      aiToEncrypt.forEach(function (ai) {
        var publicKey = publicKeys.find(function (f) { return f._id === ai.publicKey })
        if (!publicKey || !publicKey.key) throw new PentaError('No se puede encriptar la información adicional ' + ai.name + ' porque ya no existe la llave pública configurada')
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey.key)
        ai.value = encrypt.encrypt(ai.value) || '';
        if (ai.value) ai.encrypted = true;
      })
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    //// FIN CTRL
  }

})();
