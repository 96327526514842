'use strict';
(function () {
  var _controller = 'rating.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('pmxRating', {
      templateUrl: window.location.href.includes('/frontend/mobile') ? 'commons/components/rating/rating.component.html' : 'components/rating/rating.component.jade',
      controller: _controller,
      bindings: {
        model: '=', // Number, resultado de rating.
        question: '<',// STRING
        config: '<' // Object, configuración.
        // {
        //  rating: {
        //    max: NUMBER, numero maximo de iconos
        //    multiplier: NUMBER, valor por cada icono seleccionado.
        //    readonly: BOOLEAN, true: solo lectura
        //    value: NUMBER, solo aplica cuando readonly es true.
        //  }
        //  icon: { // ESTE OBJETO ES OPCIONAL
        //    class: STRING, 'fas fa-star', icono estilo SOLIDO Font Awesome 5 Free
        //    size: NUMBER, '12', tamaño de los iconos en px.
        //    color: {
        //      after: STRING, color icono al seleccionar. 'yellow' '#00423'
        //      before: STRING, color icono antes de selecionar. 'gray' '#00423'
        //    }
        //  }
        // }
      }
    })

  controller.$inject = ['$scope', '$element'];
  function controller($scope, $element) {
    // METHODS
    $scope.setRating = setRating;

    // VARS
    var that = this;
    $scope.config = {};
    $scope.ratings = [];
    $scope.question = null;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('$ctrl.model', function(newVal, oldVal){
      if(newVal === oldVal) return;
      setModelToRating();
    });

    // INIT

    /// On component init
    this.$onInit = function () {
      if (!this.config) return console.error('ERR_OBJETO_CONFIGURACION');
      if (!this.config.icon) this.config.icon = {
        class: 'fas fa-star',
        size: '35',
        color: {
          after: '#ffee33',
          before: 'lightgray'
        }
      }
      if (this.question) $scope.question = this.question;
      $scope.config = this.config;
      setRatingArray();
      setModelToRating();
    };

    /////////////////////
    function setRatingArray() {
      if (!$scope.config.rating.max) return console.error('SE_NECESITA_ESTABLECER_UN_MAXIMO_DE_ICONS');
      for (var i = 0; i < $scope.config.rating.max; i++) {
        var _selected = false;
        if ($scope.config.rating.readonly && $scope.config.rating.value) _selected = ($scope.config.rating.value >= (i + 1)) ? true : false;
        $scope.ratings.push({
          style: {
            'font-size': ($scope.config.icon.size || 30) + 'px',
            'color': _selected ? $scope.config.icon.color.after : $scope.config.icon.color.before
          }
        })
      }
    }

    function setModelToRating(){
      var index = (that.model / ($scope.config.rating.multiplier || 1)) - 1;
      setRating(index)
    }

    function setRating(index) {
      for (var i = 0; i < $scope.ratings.length; i++) {
        if (i <= index) $scope.ratings[i].style.color = $scope.config.icon.color.after;
        if (i > index) $scope.ratings[i].style.color = $scope.config.icon.color.before
      }
      that.model = (index + 1) * ($scope.config.rating.multiplier || 1);
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();
