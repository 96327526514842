(function () {
  angular.module('pentaApp').factory('payment.factory', factory);
  factory.$inject = ['router.resource'];
  function factory(routerResource) {
    return {
      initPayment: initPayment
    }

    /////////////////

    //gateway: OPCIONAL, medio de pago ('TRANSBANK-WEBPAY-MALL', 'PAGOS360', 'STRIPE', 'PAGOUNO','MERCADOPAGO-AR')
    //routerId: OPCIONAL CONDICIONAL, obligatorio si no se envía gateway
    //amount: OBLIGATORIO, cantidad a pagar
    function initPayment(gateway, routerId, amount) {
      return new Promise(async function (resolve, reject) {
        if (!routerId && !gateway) reject('Se necesita _id de sucursal y/o gateway')
        if (!amount) reject('Se necesita monto a pagar')
        let router;
        if (!gateway && routerId) {
          router = await routerResource.query({ _select: 'paymentGateway' }).$promise
          router = router.find(f => f._id === routerId)
        }
        gateway = gateway || router.paymentGateway || 'MERCADOPAGO-AR';
        if (['TRANSBANK-WEBPAY-MALL', 'PAGOS360', 'STRIPE'].includes(gateway)) {
          return resolve({ data: {} })
        }
        if (gateway === 'MERCADOPAGO-AR')
          return appNavigator.pushPage('mercadoPagoByApi/mercadoPagoByApi.jade', {
            data: {
              amount: amount,
              comment: '',
              onlyPayData: true,
              onClose: resolve
            }
          });
        else if (gateway === 'PAGOUNO')
          pagounoModal.open({
            amount: amount,
            router: routerId
          }).then(resolve)
      })
    }

  }

})();