(function () {
  let _controller = "clientUtils.factory";
  angular.module('pentaApp').factory(_controller, factory);
  factory.$inject = ['clientUtils.resource'];
  function factory(clientUtilsResource) {
    return {
      checkIdDupe: checkIdDupe,
      replaceIdDupeMessage: replaceIdDupeMessage
    }

    async function checkIdDupe(identificationNumber) {
      if (!profile.enterprise.pwaConfig.idDupeAlert) return
      if (!identificationNumber || identificationNumber.length < 2) return
      return await clientUtilsResource.query({ action: 'FIND-ID-DUPLICATE', _controller: _controller, number: identificationNumber }).$promise
    }

    function replaceIdDupeMessage(message, email) {
      if (!message || typeof message !== 'string' || !email || typeof email !== 'string') return
      return message.replace('{{clientEmail}}', email)
    }

  }
})(typeof exports !== 'undefined' && exports)
