'use strict';
(function () {
  angular.module('pentaApp').factory('payWithPoints.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/payWithPoints/payWithPoints.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data', 'clubPayWithPoints.resource', 'posTable.resource', 'receipt.resource', 'product.resource', 'clubBenefit.resource'];
  function controller($scope, modal, modalData, clubPayWithPointsResource, posTableResource, receiptResource, productResource, clubBenefitResource) {
    /// METHODS
    $scope.close = close;
    $scope.redeem = redeem;
    $scope.getReceipt = getReceipt;
    $scope.getTables = getTables;
    $scope.modalData = modalData;

    //// VARS
    $scope.routers = $scope.$root.routers.filter(function(f){return f.hasPosIntegration});
    $scope.data = {
      router: $scope.routers.find(function(f){return f._id === $scope.$root.defaultRouter}) || $scope.routers[0],
      posTable: null,
      amount: 0
    }
    var routerProducts;

    /// WATCHERS

    /// INIT
    init()

    ///////////////////////
    function init(){
      getTables()
    }


    function getTables(){
      if(!$scope.data.router) return;
      $scope.receipt = null;
      posTableResource.query({router: $scope.data.router._id}, function(tables){
        $scope.tables = tables.filter(function(f){return f.externalId})
      });
      productResource.query({router: $scope.data.router._id, includeNoAppProducts: true, _select: ['externalId', 'masterProduct']}, function(data){
        routerProducts = data
      })
    }

    function getReceipt(){
      if(!$scope.data.posTable || !$scope.data.posTable.externalId) return;
      $scope.receipt = null;
      $scope.receiptReady = false;
      receiptResource.get({reference: $scope.data.posTable.externalId, router: $scope.data.router._id}, function(data){
        $scope.receiptReady = true;
        if(data && data.header) $scope.receipt = data || {};
        var masterProducts = receiptProductsToMasterProducts(data.products, routerProducts)
        clubBenefitResource.get({cashByPoints: true, router: $scope.data.router._id, products: masterProducts}, function(data){
          $scope.cashByPoints = data;
          if($scope.cashByPoints && $scope.cashByPoints.maxCashByProductMatch && $scope.receipt && $scope.receipt.header && $scope.receipt.header.totalPrice)
            $scope.data.amount = Math.min($scope.cashByPoints.maxCashByProductMatch, $scope.receipt.header.totalPrice, $scope.cashByPoints.totalCash)
        })
      })
    }

    function receiptProductsToMasterProducts(receiptProducts, routerProducts){
      if(!receiptProducts || !receiptProducts.length || !routerProducts || !routerProducts.length) return []
      var alertProducts = [];
      var toRet = receiptProducts.map(function(rp){
        var routerProduct = routerProducts.find(function(f){ return f.externalId === rp.externalId })
        if(!routerProduct || !routerProduct.masterProduct) { alertProducts.push(rp.properties && rp.properties.name); return; }
        return routerProduct.masterProduct;
      }).filter(function(f){return !!f}) // solo devuelvo los que tienen un producto asociado en nuestro sistema
      if(alertProducts.length) 
        ons.notification.alert({
          message: i18next.t('Los siguientes productos no están sincronizados con el sistema, esto puede traer problemas en el canje')
          + '<br/><br/>' + alertProducts.reduce(function(acc,p){return acc + '<br/>' + p}, '')
        })
      return toRet;
    }

    function redeem(){
      if(!$scope.data.router || !$scope.data.router._id || !$scope.data.posTable || !$scope.data.posTable._id) throw new PentaError('Datos insuficientes para hacer el canje');
      // Pagar con puntos no depende de la rule porque pueden aplicar muchas rules
      clubPayWithPointsResource.save({
        router: $scope.data.router._id,
        posTable: $scope.data.posTable._id,
        cashAmount: $scope.data.amount
      }, function(){
        ons.notification.alert({title: i18next.t('Éxito'), message: i18next.t('Canje realizado con éxito, ya puede emitir el ticket')})
        if(modalData && modalData.onFinish) modalData.onFinish();
        close()
      })
    }


    function close(){
      modal.close()
    }

  }

})();
