'use strict';
(function () {
  angular.module('pentaApp').factory('joinWaitlist.modal', factory);
  factory.$inject = ['bottomModal.factory'];
  function factory(bottomModal) {
    return bottomModal.create({
      controller: controller,
      templateUrl: 'modals/joinWaitlist/joinWaitlist.modal.jade'
    })
  }

  controller.$inject = ['$scope', 'modal', 'data'];
  function controller($scope, modal, data) {
    $scope.back = function back() { modal.close() }
    let contentHeight = $('.page__content').height()
    $scope.modalHeight = contentHeight + 'px';

    init()

    ///////////////////////
    function init() {
      let text = i18next.t("¡Listo! Ya estás agendado en la Lista de espera para el día")
      if (data && data.waitingList && data.waitingList.date) text += ` ${moment(data.waitingList.date).utc().format('DD/MM/YYYY')}.`
      $scope.text = text
    }
  }

})();
