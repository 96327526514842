'use strict';
(function () {
  angular.module('pentaApp').factory('gameLoader', factory);
  factory.$inject = ['$http', '$q', '$rootScope'];
  function factory($http, $q, $scope) {
    return function (domTarget, onLoad, onWin, onLose, onError) {
      var myEl = angular.element(domTarget);
      myEl.addClass('gameLoading');
      $http.get('/games/api/gameDependencies').then(function (response) {
        var promises = [];
        angular.forEach(response.data, function (script) {
          if (!script.endsWith('.js')) return
          var promise = $q.defer();
          promises.push(promise);
          loadJS(script, function () { promise.resolve() });
        })

        angular.forEach(response.data, function (css) {
          if (!css.endsWith('.css')) return
          var promise = $q.defer();
          promises.push(promise);
          onloadCSS(loadCSS(css), function () { promise.resolve() });
        })

        $q.all(promises).then(function () { window.addEventListener('gameReady', onGameReady) })
      })
      function onGameReady() {
        game.init(domTarget,
          function ready() { myEl.removeClass('gameLoading'); if (!$scope.$$phase) $scope.$apply(onLoad); else onLoad(); },
          function win(data) { if (!$scope.$$phase) $scope.$apply(onWin.bind(null, data)); else onWin(data); },
          function lose() { if (!$scope.$$phase) $scope.$apply(onLose); else onLose(); },
          function error(err) { if (!$scope.$$phase) $scope.$apply(onError.bind(null, err)); else onError(err); });
        window.removeEventListener('gameReady', onGameReady);
      }
    }
  }

})();
