/**
 * Created by FERNANDO on 19/04/2016.
 */
'use strict';

(function () {
  angular.module('pentaApp').filter('translate', myFilter);
  myFilter.$inject = [];
  function myFilter() {
    return function (string, ns, options) {
      if(typeof ns === 'object'){
        options = ns
        ns = null
      } 
      if (ns) string = ns + "." + string
      return i18next.t(string, options);
    }
  }
})();
