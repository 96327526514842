'use strict';
(function () {
  var _controller = 'chat.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', '$q', 'chat.resource', 'hq.socket', 'reservation.resource', 'getUserInformation.resource', 'image.resource', '$interval', '$timeout'];
  function controller($scope, $q, chatResource, hqSocket, turnResource, getUserInformationResource, imageResource, $interval, $timeout) {
    // METHODS
    $scope.sendMessage = sendMessage;
    $scope.startVideoCall = startVideoCall;
    $scope.startAudioCall = startAudioCall;
    $scope.callModalHide = callModalHide;
    $scope.joinCall = joinCall;
    $scope.endCall = endCall;
    $scope.setEmoji = setEmoji;
    $scope.microphoneStatus = microphoneStatus;
    $scope.videoStatus = videoStatus;
    $scope.getStyles = getStyles;
    $scope.removeChatEntry = removeChatEntry;
    $scope.closeChat = closeChat;
    $scope.backConfirm = backConfirm;
    $scope.uploadFunction = uploadFunction;
    $scope.startAudioRecording = startAudioRecording;
    $scope.stopAudioRecording = stopAudioRecording;
    $scope.swapCamera = swapCamera;

    $scope.showMessagePanel = showMessagePanel;
    $scope.switchCamera = switchCamera;

    // VARS
    var localVideo, localAudio, remoteVideo, remoteAudio; //- componentes de audio y video.
    var phoneTone, phoneRingtone; //- ringtones.
    var relatedObject, relatedObjectType; //- configuración necesaria para chatear.
    var selected, running = false;
    var reservationHasChange = debounce(_reservationHasChange, 1000);
    var audioRecInterval = null; //- rec audio interval
    $scope.imageResource = imageResource;
    $scope.chatModal;
    $scope.message;
    $scope.audioRecording = null; //- rec audio status Start/Stop
    $scope.initializingRecording = null; //- rec audio inicializando
    $scope.chatResource = chatResource;
    $scope.disableSwapCamera = true; // Para deshabilitar el cambio de camara mientras se realiza la negociación
    $scope.hideTurnOffCamera = profile.enterprise.pwaConfig.hideTurnOffCamera;

    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // Esto se hace para asegurarse de que onsen haya inicializado las 2 variables monitoreadas antes de iniciar la app
    var unbind = $scope.$watch('!!chatModal && !!callModal', function (newVal, oldVal) {
      if (newVal) {
        init();
        unbind();
      }
    })
    hqSocket.on('RESERVATION-UPSERT', reservationHasChange);
    hqSocket.on('CHAT-DELETED', deleteUserChat);

    //- INIT
    // ATENCION: El init de la app se hace en el watcher (ahí explicado)

    ///////////////
    function init() {
      $scope.$root._loadingVeil = true; //- muestra velo de carga
      if (profile.enterprise
        && profile.enterprise.pwaConfig
        && profile.enterprise.pwaConfig.menuItems
        && profile.enterprise.pwaConfig.menuItems.enableChat === 'CHAT-VIDEO') {
        chatUtils.availableMediaDevices('Necesitamos permisos para poder hacer uso de los dispositivos multimedia', function (err, data) {
          if (err) ons.notification.toast(i18next.t('Lo sentimos, pero su dispositivo no puede realizar video-llamadas. ' + err.message), { timeout: 5000, class: 'toastBeth' })
          $scope.availableMediaDevices = data; //- Dispositivos de medios disponibles (audio - video)
          initialSetup();
        })
      } else {
        initialSetup();
      }
    }

    function initialSetup(reload) {
      if (running) return
      running = true;
      if ((reload && (!reload.relatedObject || !reload.relatedObjectType)) || (appNavigator.topPage.data && (!appNavigator.topPage.data.relatedObject || !appNavigator.topPage.data.relatedObjectType))) return new PentaError('ERR_AL_OBTENER_RELATEOBJECT')
      relatedObject = reload ? reload.relatedObject : appNavigator.topPage.data && appNavigator.topPage.data.relatedObject;
      relatedObjectType = reload ? reload.relatedObjectType : appNavigator.topPage.data && appNavigator.topPage.data.relatedObjectType;
      configureAudioAndVideo(); //- asigna los componentes de audio y video.
      configureRingtones(); //- asigna y configura los ringtones.
      configurationNeededToChat(); //- configuración necesaria para chatear.
      configureChatWindow(reload); //- configurar la ventana de chat.
      initializeChat(); //- iniciar chat.
      checkCompatibility(); //- Verificar compatibilidad
      getVideoDevices();
      $scope.canSwapCamera = DetectRTC.videoInputDevices && DetectRTC.videoInputDevices.length > 0;
      $scope.$root._loadingVeil = false; //- oculta velo de carga
    }

    function configureAudioAndVideo() {
      localVideo = document.getElementById('localVideo');//- Camara Propia
      localAudio = document.getElementById('localAudio'); //- Audio Propio
      remoteVideo = document.getElementById('remoteVideo');//- Camara Remota
      remoteAudio = document.getElementById('remoteAudio'); //- Audio Remoto
    }

    function configureRingtones() {
      phoneTone = new Audio('/frontend/static/sounds/phone-tone.mp3')//- Llamando
      phoneTone.loop = true;//- activa loop de audio
      phoneRingtone = new Audio('/frontend/static/sounds/phone-ringtone.mp3')//- Ringtone llamada entrante
      phoneRingtone.loop = true;//- activa loop de audio
    }

    function configurationNeededToChat() {
      $scope.connecting = false; // Indica que el proceso de responder esta en proceso, levanta el velo
      $scope.inCall = false;//- En llamada (true)
      $scope.callStatus = null;//- Llamada entrante (false) / Llamada saliente (true)
      $scope.callType = null;//- Tipo de llamada ('AUDIO', 'VIDEO')
      $scope.microphone = true;//- Inicio de llamada con microfono (false)
      $scope.videocall = true;//- Inicio de llamada con video (false)
      $scope.reShowChat = false; //- inicio de llamada maximizado.
    }

    function configureChatWindow(reload) {
      $scope.title = reload ? reload.title : appNavigator.topPage.data && appNavigator.topPage.data.title || i18next.t('Chat'); //- Titulo de ventana de chat.
      $scope.userName = reload ? reload.name : appNavigator.topPage.data && appNavigator.topPage.data.name;
      $scope.readOnly = reload ? reload.readOnly : appNavigator.topPage.data && appNavigator.topPage.data.readOnly || false;
      $scope.onBackConfirm = reload ? reload.onBackConfirm : appNavigator.topPage.data && appNavigator.topPage.data.onBackConfirm || null;
      $scope.modalQueue = reload ? reload.modalQueue : appNavigator.topPage.data && appNavigator.topPage.data.modalQueue; //- Motivo, por defecto Operador.
      var user = reload ? reload.user : appNavigator.topPage.data && appNavigator.topPage.data.user;
      $scope.emojis = ['😃', '😅', '😂', '😉', '😋', '😒', '🙄', '😪', '😷', '🤒', '🤧', '😧', '😖', '😤', '😡', '💬', '👋', '🖐', '👌', '👍', '👎', '🙏', '👩', '🧑', '🧓', '👴', '🏃', '⏰', '📞', '📠', '📧', '📩', '📅', '📆', '🏁']
      $scope.sendingMessage = false; //- Si estoy mandando el mensaje y todavía no hay respuesta del server.
      if (user) getUserInformation(user).then(function (user) {
        $scope.userName = user ? user.name : null;
        $scope.userImage = user ? user.image : null;
      })
    }

    function initializeChat() {
      if (!relatedObject || !relatedObjectType) return
      if (chatUtils.binded) chatUtils.dispose();
      //-chatLogs
      $scope.chatLogs = chatResource.query({ relatedObject: relatedObject, _controller: _controller });//- Obtiene lista de chats
      chatResource.save({ relatedObject: relatedObject, relatedObjectType: relatedObjectType, markAsReaded: true, _ignoreVeil: true, _controller: _controller });//- Marca como leido
      //-chatUtils
      chatUtils.bind($scope, relatedObject, relatedObjectType, hqSocket, chatResource);
      chatUtils.onError = function (err) { throw new PentaError(err) };
      chatUtils.callEnded = debounce(_callEnded, 500);
      chatUtils.inCall = inCall;
      chatUtils.callStats = callStats;
      chatUtils.onChat = onChat;
      if ($scope.chatModal && !$scope.chatModal.visible) $scope.chatModal.show();
    }

    function checkCompatibility() {
      var result = $scope.$root.checkVideoconferenceCompatibility(); //- Verifica compatibilidad.
      if (!result.autoChat) return
      sendMessage('Mensaje Automatico de ' + $scope.$root.profile ? $scope.$root.profile.name : 'El cliente' + ': ' + result.autoChat, 'INFO');
    }

    function getVideoDevices() {
      var localDevices = DetectRTC.videoInputDevices;
      var frontCamera = localDevices.find(function (device) {
        return device.label && (device.label.length > 0) && (device.label.toLowerCase().indexOf('front') >= 0)
      })
      var backCamera = localDevices.find(function (device) {
        return device.label && (device.label.length > 0) && (device.label.toLowerCase().indexOf('back') >= 0)
      })
      $scope.videoDevices = [];
      if (frontCamera) {
        frontCamera._type = "FRONT";
        $scope.videoDevices.push(frontCamera);
      }
      if (backCamera) {
        backCamera._type = "BACK";
        $scope.videoDevices.push(backCamera);
      }
    }

    function inCall(callType) {
      $scope.inCall = false;
      if (callType) $scope.callType = callType;//- Configurar el tipo de llamada.
      setConstraints(function (constraints) {
        if (!constraints) return callError();
        if ($scope.callType) {
          if ($scope.callType === 'AUDIO' && !constraints.audio) return callError();
          if ($scope.callType === 'VIDEO' && (!constraints.audio || !constraints.video)) return callError();
        }
        if (!constraints.audio) return callError();
        $scope.call = "LLAMADA DE"; //- Variable de info llamada
        $scope.callStatus = false; //- Llamada entrante
        chatUtils.constraints = constraints; //- Asigna configuracion de dispositivos multimedia
        chatUtils.getLocalStreams();
        if (!$scope.callModal.visible) $scope.callModal.show();
        if (phoneRingtone.paused) phoneRingtone.play(); //- Ringtone llamada entrante
      })
    }

    function callError() {
      ons.notification.toast(i18next.t('No se pudo iniciar llamada, no tienes habilitado video y audio'), { timeout: 2000, class: 'toastBeth' })
      var from = $scope.$root.profile ? $scope.$root.profile.name : 'El usuario';
      endCall()
      sendMessage('Mensaje Automatico: ' + from + ' no otorgó permisos, o bien no tiene cámara o micrófono para iniciar la llamada', 'INFO');
    }

    function onChat(chatLogId, chatLog) {
      //- Verifica el typo de llamada entrate ( AUDIO - VIDEO )
      if (chatLog && chatLog.callType && !$scope.callType) $scope.callType = chatLog.callType; // Solo cambio el tipo si NO estoy ya en llamada.
      //- Guarda log - si ya está solo lo actualizo
      let chatlogIndex = $scope.chatLogs.findIndex(f => f._id === chatLog._id)
      if (chatlogIndex !== -1) $scope.chatLogs.splice(chatlogIndex, 1, chatLog);
      else $scope.chatLogs.push(chatLog)
      //- Solo marcar leidos del start-call
      if (chatLog.message !== 'START-CALL') chatResource.save({ relatedObject: relatedObject, relatedObjectType: relatedObjectType, markAsReaded: true, _controller: _controller, _ignoreVeil: true });
    }

    function sendMessage(msg, type) {
      if (!$scope.message && !msg) return
      if ($scope.sendingMessage) return
      $scope.sendingMessage = true;
      chatResource.save({
        title: $scope.title,
        message: msg || $scope.message,
        type: type || undefined,
        relatedObject: relatedObject,
        relatedObjectType: relatedObjectType,
        _ignoreVeil: true,
        _controller: _controller
      }, function (result) {
        $scope.chatLogs.push(result)
        $scope.message = "";
      }).$promise.finally(function () { $scope.sendingMessage = false })
    }

    function startAudioCall() {
      configurationNeededToChat();
      $scope.callType = 'AUDIO';
      setConstraints(function (constraints) {
        if (!constraints) return ons.notification.toast(i18next.t('No se pudo iniciar llamada, no tienes habilitado video y audio'), { timeout: 2000, class: 'toastBeth' })
        $scope.call = "LLAMANDO A"; //- Variable de info llamada
        $scope.callStatus = true; //- Llamada saliente
        chatUtils.constraints = constraints; //- Asigna configuracion de dispositivos multimedia
        chatUtils.getLocalStreams();
        if (!$scope.callModal.visible) $scope.callModal.show(); //- Abrir Modal de llamada
        var cfg = {};
        if (relatedObjectType === 'client') cfg.toClients = $scope.toClients; //- asigna el id del cliente al que se quiere comunicar
        cfg.callType = $scope.callType; //- asigna el tipo de llamada.
        chatUtils.startCall(cfg);
        if (phoneTone.paused) phoneTone.play(); //- Llamando
      })
    }

    function startVideoCall() {
      configurationNeededToChat();
      $scope.callType = 'VIDEO';
      setConstraints(function (constraints) {
        if (!constraints || !constraints.audio) return ons.notification.toast(i18next.t('No se pudo iniciar llamada, no tienes habilitado video y audio'), { timeout: 2000, class: 'toastBeth' })
        $scope.call = "LLAMANDO A"; //- Variable de info llamada
        $scope.callStatus = true; //- Llamada saliente
        chatUtils.constraints = constraints; //- Asigna configuracion de dispositivos multimedia
        chatUtils.getLocalStreams();
        if (!$scope.callModal.visible) $scope.callModal.show(); //- Abrir Modal de llamada
        var cfg = {};
        if (relatedObjectType === 'client') cfg.toClients = $scope.toClients; //- asigna el id del cliente al que se quiere comunicar
        cfg.callType = $scope.callType; //- asigna el tipo de llamada.
        chatUtils.startCall(cfg);
        if (phoneTone.paused) phoneTone.play(); //- Llamando
      })
    }

    function joinCall() {//- Unirse a una llamada entrante
      $scope.connecting = true;
      $scope.inCall = true;
      chatUtils.joinCall()
      if (!phoneRingtone.paused) {//- Frena Audio de ringtone al unirse.
        phoneRingtone.pause();
        phoneRingtone.currentTime = 0;
      }
    }

    function endCall() {//- Colgar una llamada
      if (!phoneTone.paused) {
        phoneTone.pause();
        phoneTone.currentTime = 0;
      }
      if (!phoneRingtone.paused) {
        phoneRingtone.pause();
        phoneRingtone.currentTime = 0;
      }
      chatUtils.endCall();
      releaseMedia();
      configurationNeededToChat();
      if ($scope.callModal.visible) $scope.callModal.hide();
    }

    function callStats(event) {
      switch (event.type) {
        case 'LOCAL-TRACKS':
          if (typeof $scope.disableSwapCamera === 'number') $timeout.cancel($scope.disableSwapCamera);
          $scope.disableSwapCamera = $timeout(function () { $scope.disableSwapCamera = null; }, 10000);
          chatResource.save({ relatedObject: relatedObject, relatedObjectType: relatedObjectType, markAsReaded: true, _controller: _controller, _ignoreVeil: true });
          if ($scope.callType === 'VIDEO') {
            if (localVideo.srcObject === event.tracks) return;
            if (localVideo.srcObject) localVideo.srcObject.getTracks().forEach(function (e) { e.stop() });
            localVideo.srcObject = event.tracks;
            localVideo.play();
            if (profile.enterprise && profile.enterprise.recordVideocallAutomatically && relatedObjectType == 'reservation') showCallSecurityToast();
          }
          if ($scope.callType === 'AUDIO') {
            if (localAudio.srcObject === event.tracks) return
            if (localAudio.srcObject) localAudio.srcObject.getTracks().forEach(function (e) { e.stop() });
            localAudio.srcObject = event.tracks;
            localAudio.play();
          }
          break;
        case 'SWAPPING-CAMERA':
          $scope.disableSwapCamera = true;
          if (typeof $scope.disableSwapCamera === 'number') $timeout.cancel($scope.disableSwapCamera);
          break;
        case 'REMOTE-TRACKS':
          if (typeof $scope.disableSwapCamera === 'number') $timeout.cancel($scope.disableSwapCamera);
          $scope.disableSwapCamera = $timeout(function () { $scope.disableSwapCamera = null; }, 10000);
          // Se marca como leído
          $scope.connecting = false;
          if ($scope.callType === 'VIDEO') {
            if (!event.tracks || !event.tracks.length || remoteVideo.srcObject === event.tracks[0]) return;
            remoteVideo.srcObject = event.tracks[0];
            remoteVideo.play();
          }
          if ($scope.callType === 'AUDIO') {
            if (!event.tracks || !event.tracks.length || remoteAudio.srcObject === event.tracks[0]) return;
            remoteAudio.srcObject = event.tracks[0];
            remoteAudio.play();
          }
          break;
        case 'PEER-CONNECTED':
          $scope.inCall = true;
          //- Frena Audio de tono al conectarse un PEER
          if (!phoneTone.paused) {
            phoneTone.pause();
            phoneTone.currentTime = 0;
          }
          break
        case 'PEERS':
          if (!event.data || (((event.data.clients && event.data.clients.length || 0) + (event.data.users && event.data.users.length || 0)) < 1)) {
            endCall(); // Estoy solo? mando un end call para que termine la llamada.
            return busyCall($scope.modalClientName);
          }
          break
      }
    }

    function busyCall() {
      ons.notification.alert({ title: i18next.t("Atención"), message: i18next.t('La persona con la que se desea comunicar se encuentra Ocupado/Desconectado, por favor intente luego') });
    }

    function _callEnded() {
      if ($scope.callModal && (typeof $scope.callModal.hide === 'function')) $scope.callModal.hide();
      if (!phoneTone.paused) {
        phoneTone.pause();
        phoneTone.currentTime = 0;
      }
      if (!phoneRingtone.paused) {
        phoneRingtone.pause();
        phoneRingtone.currentTime = 0;
      }
      releaseMedia();
      configurationNeededToChat();
    }

    function releaseMedia() {//- Limpia media.
      if (localVideo) localVideo.srcObject = null;
      if (localAudio) localAudio.srcObject = null;
      if (remoteVideo) remoteVideo.srcObject = null;
      if (remoteAudio) remoteAudio.srcObject = null;
      if ($scope.videoDevices && $scope.videoDevices.length)
        $scope.videoDevices.forEach(function (f) { f._active = null })
    }

    function callModalHide() {
      // $scope.callStatus = null // Sin estado de llamadas
      // $scope.inCall = false;
      if (!$scope.chatModal.visible) $scope.chatModal.show(); //- Abre modal de chat
      if ($scope.callModal.visible) $scope.callModal.hide(); //- cierra modal de llamada
    }

    function setEmoji(elemnt) {
      var input = $('#chatInput');
      if ($scope.message === undefined) $scope.message = elemnt;
      else $scope.message += elemnt;
      input.focus()
    }

    function microphoneStatus() {//- on/off microfono
      var audioStreams = null;
      if (localVideo && localVideo.srcObject) audioStreams = localVideo.srcObject.getAudioTracks();
      if (localAudio && localAudio.srcObject) audioStreams = localAudio.srcObject.getAudioTracks();
      if (!audioStreams) return;
      if ($scope.microphone) audioStreams.forEach(function (f) { f.enabled = true });
      else audioStreams.forEach(function (f) { f.enabled = false });
    }

    function videoStatus() {// on/off video
      var videoStreams = null;
      if (localVideo && localVideo.srcObject) videoStreams = localVideo.srcObject.getVideoTracks();
      if (!videoStreams) return;
      if ($scope.videocall) videoStreams.forEach(function (f) { f.enabled = true });
      else videoStreams.forEach(function (f) { f.enabled = false });
    }

    function setConstraints(done) {
      chatUtils.availableMediaDevices('Necesitamos permisos para poder hacer uso de los dispositivos multimedia', function (err, data) {
        if (err) ons.notification.toast(i18next.t('Lo sentimos, pero su dispositivo no puede realizar video-llamadas. ' + err.message), { timeout: 5000, class: 'toastBeth' })
        $scope.availableMediaDevices = data; //- Dispositivos de medios disponibles (audio - video)
        var constraints = {};
        if ($scope.availableMediaDevices.audio && $scope.callType) constraints.audio = true;
        if ($scope.availableMediaDevices.video && $scope.callType === 'VIDEO') {
          if ($scope.videoDevices && $scope.videoDevices.length && $scope.videoDevices.length === 2) {
            var videoDevice = $scope.videoDevices.find(function (f) { return f._type === 'FRONT' });
            videoDevice._active = true; //- Se asiga la camara como activa;
            constraints.video = { "width": 240, "height": 180, "frameRate": 17, "deviceId": { exact: videoDevice.deviceId } };
          } else constraints.video = { "width": 240, "height": 180, "frameRate": 17, "facingMode": "user" };
        }
        return done(constraints)
      }, $scope.callType)
    }

    function getStyles(chatEntry, type) {//- Estilos = en base a TYPE de mensaje
      if (!type || !chatEntry) return;
      return chatFunctions.getStyles(chatEntry, type, 'PWA');
    }

    function showMessagePanel(chatEntry, $event) {
      if (!chatEntry) return
      if (!$scope.messagePanel.visible) {
        selected = chatEntry;
        $scope.messagePanel.show($event);
      }
    }

    function removeChatEntry() {
      if (!selected || !selected._id) return
      chatResource.save({ _id: selected._id, disabled: true, _controller: _controller, _ignoreVeil: true })
        .$promise
        .then(function (result) {
          if (!result) return
          var chatEntry = $scope.chatLogs.find(function (f) { return f._id === result._id });
          chatEntry.disabled = true;
          selected = null;
          if ($scope.messagePanel.visible) $scope.messagePanel.hide()
        })
        .catch(function (err) { throw new PentaError(err) })
    }

    function deleteUserChat(chatEntry) {//- Coloca disabled en chat de usuario
      if (!chatEntry) return
      var chatEntry = $scope.chatLogs.find(function (f) { return f._id === chatEntry._id });
      chatEntry.disabled = true;
    }

    function _reservationHasChange() {
      turnResource.query({ myReservations: true, _controller: _controller, _ignoreVeil: true }, function (data) {
        if (!data || !data.length) return closeChat();//- cerrar chat si no hay reservas.
        var turn = data.find(function (f) { return (f._id === relatedObject) || (f.parent && f.parent === relatedObject) });
        if (!turn) return closeChat();//- cerrar chat si la reserva fue finalizada y el chat esta activo.
        if (turn.parent && turn._id !== relatedObject) return reconfigureChat(turn);//- recofigurar chat con el nuevo turno tranferido.
        if (turn.queueUser && (!$scope.userName || !$scope.userImage)) {//- obtiene el profile del usuario si no lo obtuvo aun
          getUserInformation(turn.queueUser).then(function (user) {
            $scope.userName = user ? user.name : null;
            $scope.userImage = user ? user.image : null;
          })
        }
      })
    }

    function closeChat() {
      releaseMedia();
      configurationNeededToChat();
      appNavigator.popPage()
    }

    function reconfigureChat(turn) {
      var chat = {
        relatedObject: turn._id,
        relatedObjectType: 'reservation',
        title: 'Turno ' + turn.serial,
        user: turn.queueUser || null
      }
      releaseMedia();
      running = false;
      initialSetup(chat);
    }

    function getUserInformation(user) {
      return getUserInformationResource.query({ userId: user }).$promise
        .then(function (user) { return user })
        .catch(function (err) { throw new PentaError(err) })
    }

    function showCallSecurityToast() { //- Mostrar toast de seguridad de llamada
      ons.notification.toast(i18next.t('Esta Videollamada podría estar siendo grabada por su seguridad.'), { timeout: 3000, class: 'securityToast', animation: 'fall' })
    }

    function backConfirm() {
      ons.notification.confirm({ message: $scope.onBackConfirm.message, title: $scope.onBackConfirm.title, buttonLabels: ['No', 'Si'] })
        .then(function (confirm) {
          if (confirm) turnResource.cancelReservation({ _id: relatedObject, _controller: _controller })
        })
    }

    function uploadFunction($file) {
      if (!$file) return
      if ($file.size > 10000000) return ons.notification.toast(i18next.t('No se permiten archivos mayores a 10mb'), { timeout: 3000 })
      var regex = /(.*)(\W)(.*)/.exec($file.type);
      if (!regex || !regex.length) return
      var item = {
        relatedObject: relatedObject,
        relatedObjectType: relatedObjectType,
        _controller: _controller,
      }
      switch (regex[1]) {
        case 'audio':
          item.key = 'audio';
          item.type = "FILE-AUDIO";
          item.message = $file.name
          break
        case 'image':
          item.key = "image";
          item.type = "FILE-IMG";
          item.message = $file.name
          break;
        case 'video':
          item.key = "video";
          item.type = "FILE-VIDEO";
          item.message = $file.name
          break;
        case 'application':
          if (regex[3] === 'pdf') {
            item.key = 'pdf';
            item.type = "FILE-PDF";
            item.message = $file.name
          } else {
            item.key = 'file';
            item.type = "FILE-BINARY";
            item.message = $file.name
          }
          break;
        default:
          item.key = 'file';
          item.type = "FILE-BINARY";
          item.message = $file.name
          break;
      }
      item.file = $file;
      chatResource.save(item, function (result) { $scope.chatLogs.push(result); })
    }

    //- MENSAJES DE AUDIO
    function startAudioRecording() {
      $scope.initializingRecording = true;
      chatUtils.startAudioRecording(function (err, data) {
        if (err) return ons.notification.toast(i18next.t('error.chat.' + err), { timeout: 5000, class: 'toastError' });
        $scope.initializingRecording = false;
      });
      chatUtils.audioRecordingStarted = function () {
        $scope.audioRecording = true;
        $scope.recTime = 0;
        var count = 0;
        audioRecInterval = $interval(function () {
          count++
          $scope.recTime = count * 1000;
          if (count === 180) stopAudioRecording(); //- tiempo maximo audio 3 minutos.
        }, 1000)
      }
      chatUtils.finishedAudioRecording = function (file, cancelled) {
        $scope.audioRecording = false;
        $scope.initializingRecording = false;
        if (cancelled) {
          if (!$scope.$$phase) $scope.$apply();
          return
        }
        if (!file) return console.error('ERR_GENERATING_FILE');
        uploadFunction(file);
      }
    }

    function stopAudioRecording(cancelled) {
      chatUtils.stopAudioRecording(cancelled);
      $interval.cancel(audioRecInterval);
    }

    function swapCamera() {
      $scope.microphone = true;
      $scope.videocall = true;
      microphoneStatus()
      videoStatus()
      chatUtils.swapCamera();
    }

    function switchCamera() {
      if (!localVideo || !localVideo.srcObject) return
      $scope.changing = true; //- true: colocar el boton de cambio de camara como deshabilitado.
      var switchToThis = $scope.videoDevices.find(function (device) { return !device._active });
      if (!localVideo.paused) localVideo.pause();
      chatUtils.switchCamera(switchToThis, localVideo.srcObject, $q, function (err, data) {
        if (err) {
          if (localVideo.paused && localVideo.srcObject) localVideo.play();
          $scope.changing = false; //- false: colocar el boton de cambio de camara como habilitado.
          ons.notification.toast(i18next.t('No se pudo realizar el cambio de camara'), { timeout: 2500 });
          return console.error(err);
        }
        if (localVideo.paused) localVideo.play(); //- si esta pausado se reanuda.
        $scope.videoDevices.forEach(function (device) { //- cambio el estado de los 2 elementos del array, 
          device._active = !device._active;
        });
        microphoneStatus(); //- asigno el estado actual al microfono ON/OFF
        videoStatus(); //- asigno el estado actual al video ON/OFF
        $scope.changing = false; //- false: colocar el boton de cambio de camara como habilitado.
      });
    }

    function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }

    function onDestroy() {
      if (typeof $scope.disableSwapCamera === 'number') $timeout.cancel($scope.disableSwapCamera);
      $scope.$root.abortRequests(_controller);
      hqSocket.removeListener('RESERVATION-UPSERT', reservationHasChange);
      hqSocket.removeListener('CHAT-DELETED', deleteUserChat);
      $interval.cancel(audioRecInterval);
    }
  }
})();
