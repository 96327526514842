(function () {
  angular.module('pentaApp')
    .factory("routerStatus.factory", factory);

  var script, loadTimeout;

  factory.$inject = ['$rootScope'];
  function factory($rootScope) {
    return {
      getStatus: function (done, timeout) {
        if (!done) throw new PentaError('done must be a function');
        window.setProfile = function (err, data) {
          window.setProfile = Function.prototype;
          //loading = false;
          script.remove();
          script = null;
          clearTimeout(loadTimeout);
          if (!$rootScope.$$phase) $rootScope.$apply(function () {
            if (err) return done(new Error(err));
            done(null, data);
          }); else {
            if (err) return done(new Error(err));
            done(null, data);
          };
        };
        script = document.createElement('script');
        script.type = 'text/javascript';
        //script.src = 'http://10.5.50.1/profile.html?ts=' + Date.now();
        //document.head.appendChild(script);
        loadTimeout = setTimeout(function () { window.setProfile(new Error('Connection time-out')); }, timeout ||  10000)
      }
    }


  }


})();