(function () {
  angular.module('pentaApp').factory("playlistPlayer.factory", factory);
  factory.$inject = ['$interval', '$timeout', 'image.resource', 'playlist.resource'];
  function factory($interval, $timeout, imageResource, playlistResource) {
    /// VARS
    var playlist;
    var playlistPromise;
    var playing = false;
    var everySecondInverval;
    var cacheTimeout;
    var currentTrack;
    var watchdogCounter = 0;

    /// INIT

    /// RETURN
    return {
      /// PUBLIC VARS

      /// PUBLIC METHODS
      play: play,
      stop: stop,
      toggle: toggle,
      destroy: destroy
    }

    ////////////////////////////////////////

    function play(){
      playing = true;
      getPlaylist().then(function(){
        if(!playlist.length) return;
        setEverySecondInverval();
        cacheNextTrack();
        destroyPlayer(currentTrack);
        currentTrack = playlist.pop();
        currentTrack.player.play();
      })
    }

    function stop(){
      playing = false;
      removeEverySecondInterval();
      removeCacheTimeout();
      destroyPlayer(currentTrack);
    }

    function toggle(){
      if(playing) stop();
      else play();
    }


    function onPlayEnd(){
      play();
    }

    function destroyPlayer(track){
      if(!track || !track.player) return;
      track.player.src = '';
      track.player = null;
    }

    function removeCacheTimeout(){
      if(cacheTimeout) $timeout.cancel(cacheTimeout);
    }

    function cacheNextTrack(){
      if(!playlist.length) return;
      var nextTrack = playlist[playlist.length - 1];
      nextTrack.player = createPlayer(nextTrack);
      nextTrack.player.load();
    }

    function createPlayer(track){
      var player = new Audio(imageResource.getFileUrl(track, 'audio'));
      player.onended = onPlayEnd;
      return player
    }

    function getPlaylist(){
      /// Si se queda sin canciones vuelve a pedir la playlist
      if(playlist && !playlist.length) playlistPromise = null;
      if(playlistPromise) return playlistPromise
      playlist = playlistResource.query({_controller: 'mnmsvmsm'})
      playlistPromise = playlist.$promise;
      return playlistPromise;
    }

    function setEverySecondInverval(){
      if(everySecondInverval) return;
      everySecondInverval = $interval(everySecond, 1000)
    }

    function everySecond(){
      watchdog();
      if(currentTrack && currentTrack.player && currentTrack.player.duration){
        var leftTime = currentTrack.player.duration - currentTrack.player.currentTime;
        if(leftTime < 5) cacheNextTrack();
      }
    }

    function removeEverySecondInterval(){
      if(!everySecondInverval) return;
      $interval.cancel(everySecondInverval);
      everySecondInverval = null;
    }

    function watchdog(){
      watchdogCounter++;
      if(watchdogCounter > 10){
        watchdogCounter = 0;
        if(playing && (!currentTrack || !currentTrack.player || currentTrack.player.paused)) play();
      }
    }

    function destroy(){
      stop();
    }
  }


})();
