'use strict';
(function () {
  angular.module('pentaApp').factory('missingFields.resource', service);

  service.$inject = ['$resource'];
  function service($resource) {
    return $resource('/clientApi/missingFields', {}, {});
  }

})();
