'use strict';
(function () {
  angular.module('pentaApp').factory('appRate.modal', factory);
  factory.$inject = ['bottomModal.factory', 'appRate.resource', '$timeout'];
  function factory(bottomModal, appRateResource, $timeout) {
    var modal = bottomModal.create({
      controller: controller,
      templateUrl: 'modals/appRate/appRate.modal.jade',
      class: 'adjust-size'
    })
    var open = modal.open;
    modal.open = function (moment) {
      if (!profile || !profile._id) return;
      if (!profile.enterprise || !profile.enterprise.pwaConfig || !profile.enterprise.pwaConfig.appRate) return;
      var appRateCfg = profile.enterprise.pwaConfig.appRate;
      if (!appRateCfg.enabled || !appRateCfg.moment) return;
      if (!appRateCfg.moment[moment]) return;
      var that = this
      var args = arguments
      appRateResource.get({ _controller: 'appratefactory' }, function (data) {
        if (data && data.ts) return;
        open.apply(that, args)
      })
    }
    return modal
    ///////////////
  }

  controller.$inject = ['$scope', 'modal', 'data', 'appRate.resource'];
  function controller($scope, modal, data, appRateResource) {
    $scope.close = close;
    $scope.save = save;
    $scope.toggle = toggle;
    $scope.ratingConfig = {
      rating: {
        max: 5,
        multiplier: 2
      }
    }
    $scope.appRate = {};


    ///////////////////////

    function toggle() {
      var element = document.getElementsByClassName('appRate-modal');
      if (!element || !element.length) return
      element[0].classList.add('expand');
    }

    function save() {
      appRateResource.save($scope.appRate, close)
    }

    function close() {
      modal.close()
    }

  }

})();
