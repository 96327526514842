(function () {
  angular.module('pentaApp').directive('confirm', directive);
  directive.$inject = [];
  function directive() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('click', function () {
          var message = attrs.confirmMessage;
          if (!message) throw new Error('message should be supplied for confirm directive');
          ons.notification.confirm({ message: message, title: 'Confirmación' }).then(function (val) { if (val) scope.$eval(attrs.confirm); })
        });
      }
    }
  }
})();