'use strict';
(function () {
  var _controller = 'routerSelect.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('routerSelect', {
      templateUrl: 'components/routerSelect/routerSelect.component.jade',
      controller: _controller,
      bindings: {
        onClick: '<', // FUNCTION, se ejecuta al seleccionar un router, devuelve el _id del router seleccionado.
        routers: '<', // ARRAY, array de routers
        selectedRouterId: '<', // id del router seleccionado. ejemplo: $root.defaultRouter
        config: '<', // OBJECT, configuración.
        userPosition: '<'
        //config: {
        //   showLine : Boolean // muestra una linea vertical en los elementos del grupo. (Utilizado en newTurn)
        // }
      }
    })

  controller.$inject = ['$scope', '$element', '$q', '$timeout', 'router.resource', 'group.resource', 'geolocation.factory'];
  function controller($scope, $element, $q, $timeout, routerResource, groupResource, geolocationFactory) {
    let $ctrl = this;
    // METHODS
    $ctrl.selectRouter = selectRouter;

    // VARS
    let ready = false;
    let allRouters = [];

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('routers', function (newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!newVal.find(function (f) { return f._distanceToUser })) return
      else if (ready) return
      else {
        ready = true;
        processRouters();
        sortRouterByDistanceToUser();
        setGroups();
        $scope.routersDistanceReady = true;
      }
    }, true)

    this.$onChanges = function (bind) {
      // if (bind.selectedRouterId && bind.selectedRouterId.currentValue
      //   && bind.selectedRouterId.previousValue !== bind.selectedRouterId.currentValue) {
      //   $scope.selectedRouterId = bind.selectedRouterId.currentValue;
      //   setActiveRouter(bind.selectedRouterId.currentValue);
      // }
      if (bind.routers && bind.routers.previousValue && bind.routers.currentValue
        && bind.routers.previousValue.length !== bind.routers.currentValue.length) {
        refresh(bind.routers.currentValue)
        //filterRouters(bind.routers.currentValue);
      }
    }
    // INIT

    /// On component init
    this.$onInit = function () {
      if (!this.onClick) return console.error('ERR falta declarar la función al seleccionar un router.')
      $scope.onClick = this.onClick || null;
      $scope.config = this.config || {};
      refresh();
    };

    /////////////////////
    function refresh(routers) {
      getGroups()
        .then((groups) => {
          $ctrl.groups = groups;
          allRouters = routers || $ctrl.routers || $scope.$root.routers;
          filterRouters(allRouters);
          getDistanceAndSetRoutersDistance();
        })
        .catch(function (err) { if (err) throw new PentaError(err); })
    }

    function getGroups() {
      return new Promise((resolve, reject) => {
        groupResource.query({ _select: ['name', 'description', 'type', 'parent'], type: 'ROUTER' }, function (data) { resolve(data) });
      })
    }

    function filterRouters(routers) {
      if (!routers || !routers.length || !$ctrl.routers || !$ctrl.routers.length) return
      $ctrl.routers = allRouters;
      $ctrl.routers = $ctrl.routers.filter(function (router) { return routers.find(function (f) { return f._id === router._id }) });
      setGroups();
    }

    function getDistanceAndSetRoutersDistance() {
      if ($ctrl.userPosition) {
        $scope.routersDistanceReady = true
        return setRoutersDistance($ctrl.userPosition)
      }
      $scope.routersDistanceReady = false;
      // Esto es una protección por si falla la obtención de la distancia.
      $timeout(function () { $scope.routersDistanceReady = true; }, 8000)
      geolocationFactory.getCurrentPosition(setRoutersDistance,
        function () {
          geolocationFactory.estimatePosition()
            .then(setRoutersDistance)
            .catch(function () {
              $scope.routersDistanceReady = true;
              if (!$scope.$$phase && !$scope.$root.$$phase) $scope.$apply();
            })
          $scope.badPrecision = true;
        });
    }

    function setRoutersDistance(position) {
      if (!position || !position.latitude || !position.longitude) return
      var routersFiltered = allRouters.filter(function (router) {
        if (!router.geolocalization || !router.geolocalization.coordinates) return;
        if (!router.geolocalization.coordinates[0] || !router.geolocalization.coordinates[1]) return;
        var lat = router.geolocalization.coordinates[0];
        var lng = router.geolocalization.coordinates[1];
        if (!(lat > -90 && lat < 90) || !(lng > -180 && lng < 180)) return;
        return true;
      })
      routersFiltered.forEach(function (router) {
        var routerPosition = router.geolocalization.coordinates;
        router._distanceToUser = geolocationFactory.getDistance(routerPosition[0], routerPosition[1], position.latitude, position.longitude)
      });
      // if (!$scope.$$phase) $scope.$apply();
    }

    function processRouters() {
      if (!$ctrl.routers || !$ctrl.routers.length) return
      $ctrl.routers.forEach(function (router) { if (!router._distanceToUser) router._distanceToUser = 0 })
    }

    function sortRouterByDistanceToUser() {
      if (!$ctrl.routers || !$ctrl.routers.length) return
      $ctrl.routers.sort(function (a, b) { return a._distanceToUser - b._distanceToUser });
    }

    let cleaned = false
    function setGroups() {
      if (!$ctrl.routers.length) return
      $ctrl.routerWithoutGroup = $ctrl.routers.filter(router => !router.groups?.length);
      let routerWithGroup = $ctrl.routers.filter(router => router.groups?.length);
      for (let router of routerWithGroup) {
        for (let routerGroup of router.groups) {
          let group = $ctrl.groups.find(group => group._id === routerGroup)
          if (!group) continue
          if (!group._routers) group._routers = [];
          group._routers.push(router)
        }
      }
      $ctrl.groups.forEach(function (item) { buildChilds(item, $ctrl.groups) });
      let routerTree = $ctrl.groups.filter(f => !f.parent)
      for (let i = 0; i < 15 && filterGroups(routerTree); i++) { cleaned = false }
      $ctrl.routerTree = routerTree
    }

    function filterGroups(nodes) {
      if (!nodes?.length) return false
      for (let i = 0; i < nodes.length; i++) {
        if (clearNode(nodes[i])) {
          nodes.splice(i, 1)
          cleaned = true
          i--
        } else {
          if (nodes[i].nodes?.length) filterGroups(nodes[i].nodes)
        }
      }
      return cleaned
    }

    function clearNode(node) {
      if (!node?.nodes?.length && !node?._routers?.length) return true
      return false
    }

    function selectRouter(router) {
      if (!router) return
      if (typeof $ctrl.onClick === 'function') $ctrl.onClick(router);
    }

    function buildChilds(parent, nodes) {
      if (typeof parent.nodes === 'undefined') Object.defineProperty(parent, 'nodes', { writable: true });
      parent.nodes = nodes.filter(function (f) { return f.parent === parent._id });
      parent.nodes.forEach(function (node) { node._parentArray = parent.nodes; })
      parent.nodes.forEach(function (item) { buildChilds(item, nodes) });
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();
