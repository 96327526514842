'use strict';
(function () {
  var _controller = 'identification.component.controller'
  angular.module('pentaApp')
    .controller(_controller, controller)
    .component('identification', {
      templateUrl: window.location.href.includes('/frontend/mobile') ? 'commons/components/identification/identification.component.html' : 'components/identification/identification.component.jade',
      controller: _controller,
      bindings: {
        identificationType: '=',
        identificationNumber: '=',
        origin: '@?', //- Origen de donde se llama el componente. 'LOGIN', 'SIGNUP', 'USER', 'ADDITIONAL-INFORMATION'
        required: '<?'
      }
    })

  controller.$inject = ['$scope', '$element', '$injector', 'identification.factory'];
  function controller($scope, $element, $injector, identificationFactory) {
    // METHODS
    $scope.changeType = changeType;
    $scope.checkValidation = checkValidation;
    $scope.getStyles = getStyles;

    // VARS
    var that = this;
    let clientsIdentification = [];
    // WATCHERS
    $scope.$on('$destroy', onDestroy);

    // INIT

    /// On component init
    this.$onInit = async function () {
      try {
        $scope.identificationNumberTypes = (profile
          && profile.enterprise
          && profile.enterprise.identificationNumberTypes
          && profile.enterprise.identificationNumberTypes.length) ? profile.enterprise.identificationNumberTypes : (profile && profile.identificationNumberTypes && profile.identificationNumberTypes.length) ? profile.identificationNumberTypes : ['DNI', 'CUIL', 'RUT'];
        if ($scope.identificationNumberTypes.length === 1) this.identificationType = $scope.identificationNumberTypes[0];
        setTimeout(function () { checkValidation(); }, 0)
        $scope.$applyAsync()
      } catch (err) {
        $scope.$applyAsync(() => { throw err })
      }
    };

    /////////////////////
    // that.identificationType:
    // "CEDULA"
    // "PASSPORT"
    // "CARNET-RESIDENTE"
    // "CARNET-TEMPORAL"
    // "DNI"
    // "RUT"
    // "CUIT"


    function changeType() {
      if (!$scope.myComponentForm) return
      if (!that.identificationType) return setFormValid();
      checkValidation()
    }

    function checkValidation() {
      if (!$scope.myComponentForm) return
      if (!that.identificationNumber) return resetInputErrorMessage()
      switch (that.identificationType) {
        case "DNI":
          setFormInvalid()
          if (!identificationFactory.validateDNI(that.identificationNumber)) return setInputErrorMessage(i18next.t('DNI invalido, Ej. 99999999 o 99.999.999'));
          setFormValid()
          break;
        case "CUIT":
          setFormInvalid()
          if (!identificationFactory.validateCUIT(that.identificationNumber)) return setInputErrorMessage(i18next.t('CUIT invalido, Ej. 99-99999999-9'))
          setFormValid();
          break;
        case "CUIL":
          setFormInvalid()
          if (!identificationFactory.validateCUIL(that.identificationNumber)) return setInputErrorMessage(i18next.t('CUIL invalido, Ej. 99-99999999-9'))
          setFormValid();
          break;
        case "RUT":
          setFormInvalid()
          if (!identificationFactory.validateRUT(that.identificationNumber)) return setInputErrorMessage(i18next.t('El RUT ingresado no es válido'))
          setFormValid()
          break;
        default: return setFormValid();
      }
    }

    function setFormValid() {
      $scope.myComponentForm.identificationNumber.$setValidity("text", true);
    }

    function setFormInvalid() {
      $scope.myComponentForm.identificationNumber.$setValidity("text", false);
    }

    function setInputErrorMessage(err) {
      if (!err) err = i18next.t('Error');
      $scope.myComponentForm.identificationNumber.errorMessage = i18next.t(err);
      $scope.$applyAsync();
    }

    function resetInputErrorMessage() {
      $scope.myComponentForm.identificationNumber.errorMessage = null
    }

    function getStyles(type) {
      var styles = [];
      if ($scope.$root.employeeApp) {
        if (type === 'SELECT') {
          if (that.origin === 'USER') styles.push('identification-select select-input select-input--material max-height-37');
          else styles.push('ai-input max-height-37');
        }
        if (type === 'INPUT') {
          if (that.origin === 'USER') styles.push('identification-input text-input text-input--material text-input--underbar max-height-37');
          else styles.push('ai-input');
        }
      } else {
        if (type === 'SELECT') {
          if (that.origin === 'LOGIN' || that.origin === 'SIGNUP' || that.origin === 'USER') styles.push('identification-select select-input select-input--material max-height-32');
          if (that.origin === 'ADDITIONAL-INFORMATION') styles.push('ai-input max-height-31');
        }
        if (type === 'INPUT') {
          if (that.origin === 'LOGIN' || that.origin === 'SIGNUP' || that.origin === 'USER') styles.push('identification-input text-input text-input--material text-input--underbar');
          if (that.origin === 'ADDITIONAL-INFORMATION') styles.push('ai-input');
        }
      }
      return styles;
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }
    //// FIN CTRL
  }
})();