(function () {
  angular.module('pentaApp').factory("bottomModal.factory", factory);
  factory.$inject = ['$rootScope', '$controller', '$templateRequest', '$compile', '$q'];
  function factory($rootScope, $controller, $templateRequest, $compile, $q) {
    /// Clase modal
    function Modal(cfg) {
      this.cfg = cfg;
      this.id = Date.now().toString();
      this.scope = null;
    }
    Modal.prototype.open = open;
    Modal.prototype.close = close;

    return {
      create: create,
    }

    ////////////
    function create(cfg) {
      return new Modal(cfg)
    }

    function open(data) {
      var me = this;
      me.deferer = $q.defer();
      $templateRequest(this.cfg.templateUrl).then(function (template) {
        var scope = $rootScope.$new()
        me.scope = scope;
        template = '<ons-action-sheet class="bottom-modal ' + me.cfg.class + '" id="' + me.id + '" cancelable>' + template + '</ons-action-sheet>'
        $controller(me.cfg.controller, { $scope: scope, data: data, modal: me });
        me.element = $compile(template)(scope);
        $('body').append(me.element)
        me.onsenPopup = document.getElementById(me.id);
        me.hideOnsenPopup = me.onsenPopup.hide;
        me.onsenPopup.hide = function () { me.close(); return me.hideOnsenPopup.bind(this)(); }
        me.onsenPopup.show();
      })
      return me.deferer.promise;
    }

    function close(response) {
      var me = this;
      setTimeout(function () {
        me.scope.$destroy();
        $(me.element).remove()
        me.deferer.resolve(response)
      }, 400)
    }

    ////////////
  }
})();
