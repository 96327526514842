'use strict';
(function () {
  angular.module('pentaApp').factory('reservation.resource', service);

  service.$inject = ['$resource', 'privateFiles.resource'];
  function service($resource, privateFilesResource) {
    var url = '/progressiveApp/api/reservation'
    var result = $resource(url, {}, {
      get: { method: 'GET', cache: false },
      query: { method: 'GET', isArray: true, cache: false },
      doReservation: {
        method: 'POST',
        isArray: false
      },
      cancelReservation: {
        method: 'PUT',
        isArray: false
      }
    });

    var _doReservation = result.doReservation;
    result.doReservation = doReservation;
    return result

    async function doReservation(data, done) {
      if (data?.additionalInformation?.filter(function (f) { return f.file && !Array.isArray(f.file) }).length) {
        for (let ai of data.additionalInformation) {
          if (!ai.file || Array.isArray(ai.file)) continue
          let file = await uploadFile(ai.file, ai._id)
          ai.privateFile = file._id
          ai.mimeType = file.mimeType;
          ai.value = file.name;
        }
      }
      let saved = await _doReservation(data).$promise
      if (done) done(saved)
      return saved
    }

    async function uploadFile(data, aiId) {
      if (!data || !aiId) return {}
      let file = {
        name: data.name,
        file: data,
        additionalInformation: aiId
      }
      let savedFile = await privateFilesResource.save(file).$promise

      return savedFile
    }
  }

})();